<template>
  <div class="container-fluid content-area">
    <div class="row">
      <div class="col-md-9">
        <h3 class="judul-seksi-sga">Data Pengambilan Formulir PSB Tahun Ajaran {{this.$store.getters.getTahunAJaran}}</h3>
      </div>
    </div>
    <div class="" style="padding-top: 20px;">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <div class="card">
              <DataTable v-model:filters="filters" :value="dataPendaftaran" ref="dt" tableStyle="min-width: 50rem"
                         paginator :rows="25"
                         :rowsPerPageOptions="[5, 10, 20,25, 50,100]"
                         :first="0"
                         :loading="loading"
              >
                <template #empty> Data tidak ada. </template>
                <template #loading> Mengambil data. Mohon tunggu. </template>
                <Column field="no" header="No" sortable></Column>
                <Column field="tgl" header="Tanggal" filterMatchMode="startsWith" sortable>
                </Column>
                <Column field="noinv" header="No Formulir" sortable style="width: 25%"></Column>
                <Column field="jml" header="Jumlah" sortable></Column>
                <Column field="hargaformulir" header="Harga" sortable style="width: 25%"></Column>
                <Column field="biayaformulir" header="Pengambilan formulir" sortable style="width: 25%"></Column>
                <Column field="ortu_nama" header="Orang Tua" sortable></Column>
                <Column field="ortu_hp" header="No HP" sortable></Column>
                <Column field="anak_nama" header="Nama Anak" sortable></Column>
                <Column field="sibling_nama" header="Nama Sibling" sortable></Column>
                <Column field="sibling_kelas" header="Kelas Sibling" sortable></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from '@primevue/core/api';
export default {
  data:()=>({
    totalRecords: 4,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'tgl': {value: '', matchMode: FilterMatchMode.CONTAINS},
      'noinv': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'jml': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'hargaformulir': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'biayaformulir': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
    },
    lazyParams: {},
    response:'',
    dataPendaftaran: [],
    dataInformasi: [],
    loading: false,
    message: '',
    success: false
  }),
  name: "ViewDataPendaftaran",
  components:{
    DataTable,Column
  },
  methods:{
    onPage(event) {
      this.lazyParams = event;
      this.getDataPendaftaran();
    },
    onSort(event) {
      this.lazyParams = event;
      this.getDataPendaftaran();
    },
    onFilter(event){
      this.lazyParams = event;
      this.getDataPendaftaran();
    },
    getDataPendaftaran(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataFormulirPendaftaranAdmin';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getInformasi(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.accountData;
      let path = '/gemala/info/getinformasipsb';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataInformasi = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.lazyParams = {
      first: this.$refs.dt.first,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };
    this.getDataPendaftaran();
  }
}
</script>

<style scoped>

</style>