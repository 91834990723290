<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">
      <div class="logo-sga"><img :src="showLogo" class="img-fluid"></div>
    </a>
    <button @click="togglemenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div :class="menuclass" id="navbarNavDropdown">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link to="/admin" class="nav-link">Beranda <span class="sr-only">(current)</span></router-link>
        </li>
        <li :class="navclass">
          <router-link to="#" class="nav-link dropdown-toggle" @click="togglemenu">Pendaftaran</router-link>
          <ul :class="dropdownclass" aria-labelledby="navbarDropdownMenuLink">
            <DropDownMenu v-for="(menu,idx) in menus" :key="idx" @click="toggle(idx,menu.show)" :show="menuopened===idx" :title="menu.title" :menus="menu.data"></DropDownMenu>
          </ul>
        </li>
      </ul>
      <div class="my-2 my-lg-0">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item ">
            <router-link v-if="loggedIn" to="/gemala/account" style="color: red;"><i class="fa-solid fa-user-plus"></i> {{namapengguna}}</router-link>
            <router-link v-else to="/login" style="color: green;"><i class="fa-solid fa-right-to-bracket"></i> Login</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import DropDownMenu from "@/components/viewadmin/DropDownMenu";
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "HeaderAdmin",
  components: {
    DropDownMenu
  },
  computed:{
    menuclass(){
      if(this.menuopen){
        return 'collapse navbar-collapse show';
      }else {
        return 'collapse navbar-collapse';
      }
    },
    navclass(){
      if(this.menuopen){
        return 'nav-item dropdown show';
      }else {
        return 'nav-item dropdown show';
      }
    },
    dropdownclass(){
      if(this.menuopen){
        return 'dropdown-menu show';
      }else {
        return 'dropdown-menu';
      }
    },
    showLogo(){
        return this.logo;
    },
  },
  data:()=>({
    menuopen: false,
    logo: require('@/assets/images/logo-sga.png'),
    logoAnimated: require('@/assets/images/logo-sga-animated2.gif'),
    namapengguna:'',
    success:false,
    loggedIn: false,
    response:'',
    message:'',
    menuopened:'',
    menus: []
  }),
  methods:{
    toggle(idx,mn){
      this.menus[idx].show = !mn;
      this.menuopened = idx;
    },
    togglemenu(){
      this.menuopen = !this.menuopen
    },
    checkUserData(){
      const userData = localStorage.getItem('usergemala');
      if(userData){
        const objData = JSON.parse(userData);
        this.loggedIn = true;
        this.namapengguna = objData.data.fullname;
        return objData.data.email;
      }else{
        this.loggedIn = false;
        return '';
      }
    },
    getAdminMenu(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/getAdminMenu';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.menus = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  beforeCreate: function() {
    document.body.style = 'padding-top: 70px';
  },
  mounted() {
    //this.checkLogin();
    this.checkUserData();
    this.getAdminMenu();
  },
  created() {}
}
</script>

<style scoped>

</style>