<template>
  <LoadSpinner v-if="loading"></LoadSpinner>
  <div class="card flex justify-center" v-else>
    <table class="m-2" v-if="!isKonfirmasi">
      <tr>
        <td>Tanggal</td>
        <td>:</td>
        <td>{{formulir.tgl}}</td>
      </tr>
      <tr>
        <td>No Inv</td>
        <td>:</td>
        <td>{{formulir.noinv}}</td>
      </tr>
      <tr>
        <td>Jumlah</td>
        <td>:</td>
        <td>{{formulir.hargaformulir}}</td>
      </tr>
    </table>
    <table class="m-2" v-else>
      <tr class="bg-light p-2">
        <td>Total</td>
        <td>:</td>
        <td>{{formulir.hargaformulir}}</td>
      </tr >
      <tr class="p-2">
        <td colspan="3" class="text-center font-weight-bold">Rincian</td>
      </tr >
      <template v-for="(item,idx) in formulir.data" :key="idx">
        <tr>
          <td>Tanggal</td>
          <td>:</td>
          <td>{{item.tgl}}</td>
        </tr>
        <tr>
          <td>No Inv</td>
          <td>:</td>
          <td>{{item.noinv}}</td>
        </tr>
        <tr>
          <td>Jumlah</td>
          <td>:</td>
          <td>{{item.hargaformulir}}</td>
        </tr>
      </template>
    </table>
    <Select v-model="selectedPayment" :options="paymentList" optionLabel="name" placeholder="Pilih channel pembayaran" class="w-full md:w-56 m-2" />
    <button v-if="Object.keys(selectedPayment).length>0" @click="doPaymentFormulir(formulir)" class="mt-3 btn btn-sm btn-success"><i class="fa-solid fa-caret-right"></i> Proses</button>
    <Card v-if="messagetrx.length>0">
      <template #title>Informasi Pembayaran</template>
      <template #content>
        <p>{{messagetrx}}</p>
        <div v-html="infopembayaran" />
      </template>
    </Card>
  </div>
</template>

<script>
import Select from 'primevue/select';
import LoadSpinner from "@/components/LoadSpinner";
import DashboardServices from "@/services/dashboard.services";
import Card from 'primevue/card';
export default {
  name: "PaymentOption",
  props:{
    formulir :undefined,
    isKonfirmasi: Boolean,
    path:String,
  },
  components:{Select,LoadSpinner,Card},
  data:()=>({
    loading: false,
    response: "",
    success: false,
    selectedPayment: "",
    infopembayaran:"",
    paymentList:[],
    dataPembayaran:[],
    messagetrx:"",
  }),
  methods:{
    getPaymentOptions(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/listPaymentOptions';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.paymentList = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    doPaymentFormulir(formulir){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paymentchannel:this.selectedPayment,
        data:formulir
      };
      //let path = '/dashboard/doPaymentFormulir';
      let path = this.path;
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.messagetrx = response.data.message;
              this.dataPembayaran = this.response.data;
              if(this.dataPembayaran.Status==='SUCCESS'){
                this.infopembayaran = this.dataPembayaran.infopembayaran;
              }else{
                this.messagetrx = 'Mohon coba kembali';
              }
            }else {
              this.messagetrx = response.data.message;
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    this.getPaymentOptions();
  }
}
</script>

<style scoped>

</style>