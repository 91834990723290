<template>
  <div v-if="message" class="alert alert-danger text-center" role="alert">
    {{message}}
  </div>
</template>

<script>
export default {
  name: "AlertDanger",
  props:{
    message:String
  }
}
</script>

<style scoped>

</style>