<template>
  <Bread-crumb page="Beranda > Pendaftaran > Jadwal Tes Kognitif"></Bread-crumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Jadwal Tes Kognitif</h3>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Dibawah ini adalah jadwal tes kognitif untuk Ananda. Harap tunggu pembaharuan jadwal dan pemberitahuan melalui surel dari pihak sekolah bila Anda belum mendapatkan jadwal.</p>
        <div class="table-data-sga">
          <div class="table-responsive">
            <LoadSpinner v-if="loading"></LoadSpinner>
            <table v-else class="table table-striped ">
              <thead>
              <tr>
                <th scope="col">No formulir</th>
                <th scope="col">Nama Ananda</th>
                <th scope="col">Nama Orang Tua</th>
                <th scope="col">Jenis</th>
                <th scope="col">Status</th>
                <th scope="col"> Jadwal</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(anak,idx) in dataAnak" :key="idx">
                <td>{{anak.no_formulir}}</td>
                <td>{{anak.nama_anak}}</td>
                <td>{{anak.nama_ortu}}</td>
                <td>{{anak.jenis_daftar}}</td>
                <td>{{anak.jadwal_wawancara}}</td>
                <td>{{anak.tanggalid}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import LoadSpinner from "@/components/LoadSpinner";
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "PageJadwalTestKognitif",
  components:{LoadSpinner, BreadCrumb},
  data:()=>({
    loading: false,
    response: '',
    message: '',
    success: false,
    tanggalkelas:'',
    anggota:'',
    timname:'',
    showmodal: false,
    dataAnak:[],
    idanak:'',
  }),
  methods:{
    getDataAnak(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataWawancaraKognitif';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataAnak = this.response.data.kebutuhankhusus;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataAnak();
  }
}
</script>

<style scoped>

</style>