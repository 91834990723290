<template>
  <BreadCrumb page="Beranda > Pendaftaran > Penilaian > Pengaturan Jadwal Kelas Coba Belajar"></BreadCrumb>
  <div class="container content-area" v-if="showtable">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Data Jadwal Kelas Coba Belajar Tahun Ajaran {{this.$store.getters.getTahunAJaran}}</h3>
          <div class="form-general">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <Toolbar class="mb-4">
                    <template #end>
                      <Button label="Export" icon="pi pi-upload" severity="help" @click="exportCSV($event)"  />
                    </template>
                  </Toolbar>
                  <DataTable v-model:filters="filters" :value="dataKelasPercobaan" ref="dt" tableStyle="min-width: 50rem"
                             paginator :rows="10"
                             :rowsPerPageOptions="[5, 10, 20, 50]"
                             :first="0"
                             :loading="loading"
                  >
                    <template #header>
                      <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                      </div>
                    </template>
                    <template #empty> Data tidak ada. </template>
                    <template #loading> Mengambil data. Mohon tunggu. </template>
                    <Column field="no" header="No" sortable></Column>
                    <Column field="hari" header="Hari" sortable></Column>
                    <Column field="tanggalid" header="Tanggal" sortable></Column>
                    <Column field="kodeslot" header="Keterangan" filterMatchMode="startsWith" sortable></Column>
                    <Column field="anak_nama" header="Nama anak" sortable></Column>
                    <Column header="Slot" sortable style="width: 25%">
                      <template #body="slotProps">
                        {{slotProps.data.inv_child}}<br>{{slotProps.data.ortu_nama}}<br><strong>{{slotProps.data.anak_nama}}</strong>
                      </template>
                    </Column>
                    <Column field="namatimwawancara" header="Tim Pewawancara" sortable></Column>
                    <Column field="namatim" header="Tim Assessor" sortable style="width: 25%"></Column>
                    <Column header="Set Tim">
                      <template #body="slotProps">
                        <a href="#" @click="viewdata(slotProps.data)" class="fa-solid fa-eye" ></a>
                      </template>
                    </Column>
                    <Column header="Set Hasil">
                      <template #body="slotProps">
                        <a href="#" @click="updatedata(slotProps.data)" class="fa-solid fa-eye" ></a>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div></div>
          </div>
      </div>
    </div>
  </div>
  <PagePilihTimPenilaian :data="dataselected" v-if="showtim" @change-view="toogleviewshowtim"></PagePilihTimPenilaian>
  <PageUpdateHasilPenilaian :data="dataselected" v-if="showpenilaian" @change-view="toogleviewshowpenilaian"></PageUpdateHasilPenilaian>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import PagePilihTimPenilaian from "@/components/viewadmin/kelaspercobaan/PagePilihTimPenilaian";
import PageUpdateHasilPenilaian from "@/components/viewadmin/kelaspercobaan/PageUpdateHasilPenilaian";
import {FilterMatchMode} from "@primevue/core/api";
import Toolbar from 'primevue/toolbar';
import InputText from "primevue/inputtext";
import Button from 'primevue/button';
export default {
  name: "JadwalKelasPercobaan",
  components: {PageUpdateHasilPenilaian, PagePilihTimPenilaian, BreadCrumb, DataTable,Column,Toolbar,InputText,Button},
  data:()=>({
    loading: false,
    response: '',
    message: '',
    success: false,
    tanggalkelas:'',
    dataKelasPercobaan:[],
    showtim: false,
    showpenilaian: false,
    dataselected:[],
    showtable: true,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'tgl': {value: '', matchMode: FilterMatchMode.CONTAINS},
      'noinv': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'jml': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'hargaformulir': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'biayaformulir': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
    },
  }),
  methods:{
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    toogleviewshowtim(){
      this.showtim=false;
      this.showtable = true;
    },
    toogleviewshowpenilaian(){
      this.showpenilaian=false;
      this.showtable = true;
    },
    viewdata(kelas){
      this.dataselected = kelas;
      this.showtim = !this.showtim;
      this.showtable = !this.showtable;
    },
    updatedata(kelas){
      this.dataselected = kelas;
      this.showpenilaian = !this.showpenilaian;
      this.showtable = !this.showtable;
    },
    getDataKelasPercobaan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataJadwalKelasPercobaan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataKelasPercobaan = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    this.getDataKelasPercobaan();
  }
}
</script>

<style scoped>

</style>