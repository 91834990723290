<template>
  <FormTitle title="A. Data Pribadi"></FormTitle>
  <LoadSpinner v-if="loading"></LoadSpinner>
  <div v-else class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.namalengkap.$error?'text-danger':''">Nama lengkap</label>
          <input type="text" disabled="disabled" v-model="dataFormulir.namalengkap" class="w-100 form-control" required>
          <span class="keterangan-form">Tuliskan nama lengkap</span>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.namapanggilan.$error?'text-danger':''">Nama panggilan</label>
          <input type="text" v-model="dataFormulir.namapanggilan" class="w-100 form-control" required>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.tempatlahir.$error?'text-danger':''">Tempat lahir</label>
          <input type="text" v-model="dataFormulir.tempatlahir" class="w-100 form-control" required>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.tanggallahir.$error?'text-danger':''">Tanggal lahir</label>
          <div class="datepicker date input-group">
            <input type="date" disabled="disabled" v-model="dataFormulir.tanggallahir" placeholder="" class="date-picker-sga form-control" id="" required>
          </div>
          <span class="keterangan-form">Pilih tahun terlebih dahulu, kemudian bulan, terakhir tanggal</span>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.kebangsaan.$error?'text-danger':''">Kebangsaan</label>
          <input type="text" v-model="dataFormulir.kebangsaan" class="w-100 form-control" required>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.agama.$error?'text-danger':''">Agama</label>
          <select v-model="dataFormulir.agama" class="w-100 form-control" required>
            <option value="">-</option>
            <option value="islam">Islam</option>
            <option value="katolik">Katolik</option>
            <option value="kristen">Kristen</option>
            <option value="budha">Budha</option>
            <option value="hindu">Hindu</option>
            <option value="konghucu">Konghucu</option>
            <option value="kepercayaan">Kepercayaan Kepada Tuhan Yang Maha Esa</option>
          </select>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.nik.$error?'text-danger':''">NIK</label>
          <input type="text" disabled="disabled" v-model="dataFormulir.nik" class="w-100 form-control" required>
          <span class="keterangan-form">Angka. Sesuai dengan kartu keluarga</span>
        </div>
        <div class="col-12 spacer-field-sga">
          <normal-label :class="v$.dataFormulir.passport.$error?'text-danger':''">Passport</normal-label>
          <input type="text"  v-model="dataFormulir.passport" class="w-100 form-control">
          <span class="keterangan-form">No. Passport</span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.masukkekelas.$error?'text-danger':''">Mendaftar untuk masuk ke kelas</label>
          <LoadSpinner v-if="loading"></LoadSpinner>
          <select v-else name="Kelas" class="w-100 form-control" v-model="dataFormulir.masukkekelas"
                  @change="$emit('change-kelas',$event.target.value)" required>
            <option v-for="(kelas,idx) in jenisKelas" :key="idx" :value="kelas.code">{{kelas.value}}</option>
          </select>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.semester.$error?'text-danger':''">Semester</label>
          <input type="text" v-model="dataFormulir.semester" class="w-100 form-control" required>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.usiatahun.$error?'text-danger':''">Usia pada bulan Juli 2025 adalah ...... tahun</label>
          <input type="number" v-model="dataFormulir.usiatahun" class="w-100 form-control" required>
          <span class="keterangan-form">Masukkan tahun</span>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.usiabulan.$error?'text-danger':''">dan bulan</label>
          <input type="number" v-model="dataFormulir.usiabulan" class="w-100 form-control" required>
          <span class="keterangan-form">Masukkan bulan</span>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.berkebutuhankhusus.$error?'text-danger':''">Berkebutuhan khusus atau berkesulitan belajar</label>
          <div class="form-check">
            <input class="form-check-input" v-model="dataFormulir.berkebutuhankhusus" type="radio" name="exampleRadios" id="exampleRadios1"
                   value="ya" required>
            <span class="normal-label form-check-label" for="exampleRadios1">
              Ya
            </span>
          </div>
          <div class="form-check">
            <input class="form-check-input" v-model="dataFormulir.berkebutuhankhusus" type="radio" name="exampleRadios" id="exampleRadios2"
                   value="tidak" required>
            <span class="normal-label form-check-label" for="exampleRadios2">
              Tidak
            </span>
          </div>
        </div>
        <div class="col-12 spacer-field-sga">
          <label :class="v$.dataFormulir.photo.$error?'text-danger':''">Unggah foto Ananda dengan ukuran 3x4</label>
          <FileLister v-if="files.datapribadi.size!=0" :filedata="files.datapribadi.list" :num-visible="files.datapribadi.size"></FileLister>
          <ProgressBar v-if="isupload" class="mt-2 mb-2" :value="uploadprogress" style="height: 10px;font-size: 14px"></ProgressBar>
          <FileUpload v-if="showupload" :class="classupload" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel" @before-send="beforeUpload" :fileLimit="1" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,image/png" :maxFileSize="1200000" @progress="onProgress" @upload="onUpload" @select="onSelect" >
            <template #empty>
              <p>Drag and drop files to here to upload.</p>
            </template>
          </FileUpload>
          <span class="keterangan-form">Pilih berkas maksimum berukuran 1024kb. Hanya .jpg, .png</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/views/psb/FormTitle";
import DashboardServices from "@/services/dashboard.services";
import LoadSpinner from "@/components/LoadSpinner";
import FileUpload from 'primevue/fileupload';
import useValidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ProgressBar from 'primevue/progressbar';
import FileLister from "@/components/FileLister";

export default {
  name: "PageFormDataPribadi",
  props:{
    idanak:String,
    initdata:undefined,
    jenisKelas:undefined,
    dataFiles:undefined,
    showupload:Boolean
  },
  components: {FileLister, LoadSpinner,ProgressBar, FormTitle,FileUpload,},
  emits: ['change-data'],
  data: () => ({
    v$: useValidate(),
    loading: false,
    message:'',
    success:false,
    urlupload: '',
    uploadprogress:0,
    classupload:'',
    uploadLabel: 'Pilih',
    files:{
      datapribadi:{
        size:0,
        list:[]
      }
    },
    isupload: false,
    dataFormulir:{
      namalengkap:'',
      namapanggilan:'',
      tempatlahir:'',
      tanggallahir:'',
      kebangsaan:'',
      agama:'',
      nik:'',
      passport:'',
      masukkekelas:'',
      semester:'',
      usiatahun:'',
      usiabulan:'',
      berkebutuhankhusus:'',
      photo:''
    }
  }),
  mounted() {
    this.validate();
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.$emit('change-data',this.initdata);
    this.dataFormulir = this.initdata;
    this.getDataUploadedFile();
  },
  methods:{
    validate() {
      this.v$.dataFormulir.$validate();
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.idanak};
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              if (typeof this.response.data.datapribadi != "undefined") {
                this.files.datapribadi.list = this.response.data.datapribadi.list;
                this.files.datapribadi.size = this.response.data.datapribadi.size;
                if(this.files.datapribadi.size>0){
                  this.dataFormulir.photo = 'uploaded';
                }
              }
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    beforeUpload(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','datapribadi');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgress(event){
      this.isupload = true;
      this.uploadprogress = event.progress;
    },
    onUpload(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.photo = obj.data.fileid;
        this.classupload='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload = false;
    },
    onSelect(event){
      if(event.files.length >0){
        //this.uploadLabel = event.files[0].name;
      }
      this.photo = '';
    },
    doSubmitIsiFormulir(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paramname:'datapribadi',
        invid: this.idanak,
        formulir:this.dataFormulir
      };
      let path = '/dashboard/submitIsiFormulirPsb';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  validations() {
    return {
      dataFormulir:{
        namalengkap:{required },
        namapanggilan:{required },
        tempatlahir:{required },
        tanggallahir:{required },
        kebangsaan:{required },
        agama:{required },
        nik:{required },
        passport:{},
        masukkekelas:{required },
        semester:{required },
        usiatahun:{required },
        usiabulan:{required },
        berkebutuhankhusus:{required },
        photo:{required },
      }
    }
  },
}
</script>

<style scoped>

</style>