import { createWebHistory,createRouter} from "vue-router";
import routeOrangTua from "@/components/router/routeorangtua"
import NotFound404 from "@/components/NotFound404";
import RouteInformasiPendaftaran from "@/components/router/routeinformasipendaftaran";
import RoutePendaftaran from "@/components/router/routerpendaftaran";
import Routeraccount from "@/components/router/routeraccount";
import routerlogout from "@/components/router/routerlogout";
import routerformulir from "@/components/router/routerformulir";
import routerlogin from "@/components/router/routerlogin";
import routerregister from "@/components/router/routerregister";
import routerpsb from "@/components/router/routerpsb";
import routeBeranda from "@/components/router/routeberanda";
import routehome from "@/components/router/routehome";
import routerAdmin from "@/components/router/routerAdmin";
import routerViewAdmin from "@/components/router/routerViewAdmin";
import routerjadwalpsb from "@/components/router/routerjadwalpsb";
import routerhasilkeputusan from "@/components/router/routerhasilkeputusan";
import routerViewEditAdmin from "@/components/router/routerViewEditAdmin";
import routerresetpassword from "@/components/router/routerresetpassword";
import routeberasa from "@/components/router/routeberasa";
//import DashboardServices from "@/services/dashboard.services";
let routes = [
    {
        path: '/',
        name: 'Home',
        component: routeOrangTua,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/admin',
        name: 'Home Admin',
        component: routerAdmin,
        meta: {
            roleAdmin: true,
            authRequired: true,
        },
    },
    {
        path: '/berasa/:token',
        name: 'Formulir Berasa',
        component: routeberasa,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/admin/parameter/:page',
        name: 'Data Parameter',
        component: routerViewAdmin,
        meta: {
            roleAdmin: true,
            authRequired: true,
        },
    },
    {
        path: '/admin/edit-parameter/:page/:id',
        name: 'Edit Parameter',
        component: routerViewEditAdmin,
        meta: {
            roleAdmin: true,
            authRequired: true,
        },
    },
    {
        path: '/admin/data/:page',
        name: 'Data Pengambilan Formulir',
        component: routerViewAdmin,
        meta: {
            roleAdmin: true,
            authRequired: true,
        },
        children: [
            {
                path: ':id',
                name: 'View Formulir PSB',
                component: routerViewAdmin,
                meta: {
                    roleAdmin: true,
                    authRequired: true,
                },
            }
        ]
    },
    {
        path: '/beranda',
        name: 'Beranda',
        component: routeBeranda,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/home',
        name: 'Gemala Ananda',
        component: routehome,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/informasipendaftaran',
        name: 'Informasi Pendaftaran',
        component: RouteInformasiPendaftaran,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: routerlogin,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/pendaftaran-akun',
        name: 'Pendaftaran Akun',
        component: routerregister,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/reset-password',
        name: 'Setel ulang kata sandi',
        component: routerresetpassword,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: routerlogout,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
    },
    {
        path: '/gemala',
        name: 'Pendaftaran',
        component: RoutePendaftaran,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
        children: [
            {
                path: 'pendaftaran',
                name: 'Pendaftaran Sekolah',
                component: NotFound404,
                meta: {
                    roleAdmin: false,
                    authRequired: true,
                },
            }
        ]
    },
    {
        path: '/gemala/account',
        name: 'Account',
        component: Routeraccount,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
    },
    {
        path: '/gemala/formulir/:page',
        name: 'Page Formulir',
        component: routerformulir,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
    },
    {
        path: '/gemala/hasil/psb/keputusan',
        name: 'Page Hasil Keputusan',
        component: routerhasilkeputusan,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
    },
    {
        path: '/gemala/jadwal/psb/:page',
        name: 'Page Jadwal',
        component: routerjadwalpsb,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
    },
    {
        path: '/gemala/psb/:page',
        name: 'Page Pendaftaran Siswa Baru',
        component: routerpsb,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
        children: [
            {
                path: ':id',
                name: "Pengisian Formulir PSB",
                component: routerpsb,
                meta: {
                    roleAdmin: false,
                    authRequired: true,
                },
            },
        ]
    },
    {
        path:'/:pathMatch(.*)/*',
        name: "Page Not Found",
        component: NotFound404,
        meta: {
            roleAdmin: false,
            authRequired: 'false',
        },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
    linkActiveClass: 'active text-success font-weight-bold',
    linkExactActiveClass: 'active',
})

router.beforeEach(async (to, from, next) => {
    const authRequired = to.meta.authRequired;
    let userData = localStorage.getItem('usergemala');
    let admin = [3,4,5,6,7,8,9,10,11,12,13,14];
    let token = '';
    let loggedIn = false;
    let role = 0;
    if(userData != null){
        if(userData){
            const objData = JSON.parse(userData);
            if(objData.token !== undefined){
                token = objData.token;
                if (token.length>0){
                    loggedIn = true;
                    role = objData.role;
                }else{
                    loggedIn = false;
                }
            }else{
                loggedIn = false;
            }
        }else{
            loggedIn = false;
        }
    }
    if(authRequired){
        if(loggedIn){
            //await DashboardServices.checkMe(to.path);
            if(to.meta.roleAdmin){
                if(admin.includes(parseInt(role))){
                    next();
                }else{
                    next('/home');
                }
            }else{
                next();
            }
        }else{
            next('/login');
        }
    }else{
        next();
    }
});

export default router;