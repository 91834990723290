<template>
  <!--DATA PRIBADI-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>G. Referensi (bila ada)
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-12">
          <label>Mengetahui Gemala Ananda dari?</label>
        </div>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" v-model="dataFormulir.mengetahuidari" id="inlineCheckbox1" value="facebook" name="mengetahuidari" required>
            <span class="normal-label2 form-check-label" for="inlineCheckbox1">Facebook</span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" v-model="dataFormulir.mengetahuidari" id="inlineCheckbox2" value="instagram" name="mengetahuidari" required>
            <span class="normal-label2 form-check-label" for="inlineCheckbox2">Instagram</span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" v-model="dataFormulir.mengetahuidari" id="inlineCheckbox3" value="website"  name="mengetahuidari" required>
            <span class="normal-label2 form-check-label" for="inlineCheckbox3">Website</span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" v-model="dataFormulir.mengetahuidari" id="inlineCheckbox4" value="poster"  name="mengetahuidari" required>
            <span class="normal-label2 form-check-label" for="inlineCheckbox4">Poster</span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" v-model="dataFormulir.mengetahuidari" id="inlineCheckbox5" value="lainlain"  name="mengetahuidari" required>
            <span class="normal-label2 form-check-label" for="inlineCheckbox5">Lain lain</span>
          </div>
        </div>
        <div class="col-12 spacer-field-sga">
          <label>Pernah hadir di gelar griya SD Gemala Ananda?</label><br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataFormulir.pernahhadir" name="pernahhadir" id="inlineRadio1" value="ya" required>
            <span class="normal-label2 form-check-label" for="inlineRadio1">Pernah</span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataFormulir.pernahhadir" name="pernahhadir" id="inlineRadio2" value="tidak" required>
            <span class="normal-label2 form-check-label" for="inlineRadio2">Tidak</span>
          </div>
        </div>
        <div class="col-12 spacer-field-sga">
          <span class="normal-label" :class="v$.dataFormulir.tanggal.$error?'text-danger':''">Bila pernah, tuliskan tanggal kehadiran</span>
          <input type="text" v-model="dataFormulir.tanggal" class="w-100 form-control"  >
          <span class="keterangan-form"></span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <label>Referensi dari orang tua siswa SD Gemala Ananda?</label><br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataFormulir.referensi" name="referensi" value="ya" required>
            <span class="normal-label2 form-check-label" for="inlineRadio1">Ya</span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataFormulir.referensi" name="referensi" value="tidak" required>
            <span class="normal-label2 form-check-label" for="inlineRadio2">Tidak</span>
          </div>
        </div>
        <div class="col-12 spacer-field-sga">
          <span class="normal-label" :class="v$.dataFormulir.referensinama.$error?'text-danger':''">Bila ya, sebutkan satu nama orang tua pemberi referensi </span>
          <input type="text" v-model="dataFormulir.referensinama" class="w-100 form-control"  >
        </div>
        <div class="col-12 spacer-field-sga">
          <span class="normal-label">Nama Ananda </span>
          <input type="text" v-model="dataFormulir.nama" class="w-100 form-control"  >
        </div>
        <div class="col-12 spacer-field-sga">
          <span class="normal-label">Kelas</span>
          <input type="text" v-model="dataFormulir.kelas" class="w-100 form-control"  >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import {required,requiredIf} from "@vuelidate/validators";

export default {
  name: "PageFormReferensi",
  props:{
    data:[],
    initdata: undefined
  },
  emits: ['change-data'],
  data:()=>({
    v$: useValidate(),
    loading: false,
    message:'',
    success:false,
    dataFormulir:{
      referensi:'',
      referensinama:'',
      nama:'',
      mengetahuidari:[],
      pernahhadir:'',
      tanggal:''
    }
  }),
  methods:{
    validate() {
      this.v$.dataFormulir.$validate();
    },
  },
  mounted() {
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
    this.validate();
  },
  validations() {
    return {
      dataFormulir:{
        referensi: {required },
        referensinama:{required:requiredIf(this.dataFormulir.referensi==='ya')},
        mengetahuidari: {required },
        pernahhadir: {required },
        tanggal: {required:requiredIf(this.dataFormulir.pernahhadir==='ya')}
      }
    }
  }
}
</script>

<style scoped>

</style>