<template>
  <div class="row">
    <div class="col-12">
      <!--opening-row-->
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <label>Nama Ayah</label>
        </div>
        <div class="col-md-10 spacer-field-sga">
          <input v-model="dataFormulir.namaayah" class="w-100 form-control" required="">
          <keterangan-form>Tuliskan nama lengkap</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <label>Nama Ibu</label>
        </div>
        <div class="col-md-10 spacer-field-sga">
          <input v-model="dataFormulir.namaibu" class="w-100 form-control" required="">
          <keterangan-form>Tuliskan nama lengkap</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <label>Nama Anak</label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.anak.nama" class="w-100 form-control" required="">
          <keterangan-form>Tuliskan nama lengkap</keterangan-form>
        </div>
        <div class="col-md-2 spacer-field-sga">
          <label>Jenis kelamin </label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.anak.jeniskelamin" class="w-100 form-control">
          <keterangan-form>(tulis L untuk lelaki dan P untuk perempuan)</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 spacer-field-sga" style="margin-top: 30px;">
          <p><strong></strong>Selain Ayah/Wali dan Ibu/Wali, Orang tua memberi wewenang bagi nama-nama di bawah ini untuk mengantarkan dan/atau menjemput ke dan dari SD Gemala Ananda:
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <normal-label>Nama </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.penjemput1.nama" class="w-100 form-control" required="">
          <keterangan-form>Tuliskan nama lengkap</keterangan-form>
        </div>
        <div class="col-md-2 spacer-field-sga">
          <normal-label>Jenis kelamin </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.penjemput1.jeniskelamin" class="w-100 form-control">
          <keterangan-form>(tulis L untuk lelaki dan P untuk perempuan)</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <normal-label>Hubungan dengan ananda </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.penjemput1.hubungan" class="w-100 form-control" required="">
          <keterangan-form>Contoh: Paman</keterangan-form>
        </div>
        <div class="col-md-2 spacer-field-sga">
          <normal-label>No. KTP/SIM </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.penjemput1.ktp" class="w-100 form-control">
          <keterangan-form>Tuliskan nomor SIM atau KTP</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12"><hr></div>
      </div>
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <normal-label>Nama </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.penjemput2.nama" class="w-100 form-control" required="">
          <keterangan-form>Tuliskan nama lengkap</keterangan-form>
        </div>
        <div class="col-md-2 spacer-field-sga">
          <normal-label>Jenis kelamin </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.penjemput2.jeniskelamin" class="w-100 form-control">
          <keterangan-form>(tulis L untuk lelaki dan P untuk perempuan)</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <normal-label>Hubungan dengan ananda </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.penjemput2.hubungan" class="w-100 form-control" required="">
          <keterangan-form>Contoh: Paman</keterangan-form>
        </div>
        <div class="col-md-2 spacer-field-sga">
          <normal-label>No. KTP/SIM </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.penjemput2.ktp" class="w-100 form-control">
          <keterangan-form>Tuliskan nomor SIM atau KTP</keterangan-form>
        </div>
      </div>
      <div class="row" style="margin-top: 20px; margin-bottom: 20px;">
        <div class="col-12"></div>
        <div class="col-12 spacer-field-sga">
          <normal-label><i class="fa-solid fa-image"></i> Unggah KTP atau SIM pengantar dan/atau penjemput</normal-label>
          <div class="custom-file">
            <FileLister v-if="!loading" :filedata="filelist.list" :num-visible="filelist.size"></FileLister>
            <FileUpload v-if="showupload" :class="classupload1" :fileLimit="1" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel1" @before-send="beforeUploadSatu" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="5000000" @progress="onProgressSatu" @upload="onUploadSatu" @select="onSelectSatu"></FileUpload>
            <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg,.pdf | Maksimum 10 berkas </keterangan-form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 spacer-field-sga" style="margin-top: 30px;">
          <p>Orang tua/Wali:
          <ul>
            <li>Memberi hak pada SD Gemala Ananda untuk melakukan serah-terima antar-jemput anak selama berada di SD Gemala Ananda pada nama-nama di atas.</li>
            <li>Menyerahkan foto kopi KTP penjemput.  </li>
            <li>SD Gemala Ananda berwenang menolak penjemput yang tidak tercantum dalam formulir ini dan/atau yang tidak membawa Kartu Penjemput pada saat menjemput.  </li>
            <li>Orang tua akan memberitahukan secara tertulis apabila terjadi perubahan sementara ataupun permanen dalam hal pengantaran dan/atau penjemputan Ananda.  </li>
            <li>Mendaftarkan kendaraan penjemput dengan keterangan sebagai berikut:</li>
          </ul>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <normal-label>Merk dan jenis kendaraan</normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.kendaraan1.merk" class="w-100 form-control" required="">
          <keterangan-form>contoh : Mobil Toyota Avanza </keterangan-form>
        </div>
        <div class="col-md-2 spacer-field-sga">
          <normal-label>No. pol </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.kendaraan1.nopol" class="w-100 form-control">
          <keterangan-form>Tuliskan nomor tanda kendaraan lengkap</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12"><hr></div>
      </div>
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <normal-label>Merk dan jenis kendaraan</normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.kendaraan2.merk" class="w-100 form-control" required="">
          <keterangan-form>contoh : Mobil Toyota Avanza </keterangan-form>
        </div>
        <div class="col-md-2 spacer-field-sga">
          <normal-label>No. pol </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.kendaraan2.nopol" class="w-100 form-control">
          <keterangan-form>Tuliskan nomor tanda kendaraan lengkap</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12"><hr></div>
      </div>
      <div class="row">
        <div class="col-md-2 spacer-field-sga">
          <normal-label>Merk dan jenis kendaraan</normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.kendaraan3.merk" class="w-100 form-control" required="">
          <keterangan-form>contoh : Mobil Toyota Avanza </keterangan-form>
        </div>
        <div class="col-md-2 spacer-field-sga">
          <normal-label>No. pol </normal-label>
        </div>
        <div class="col-md-4 spacer-field-sga">
          <input v-model="dataFormulir.kendaraan3.nopol" class="w-100 form-control">
          <keterangan-form>Tuliskan nomor tanda kendaraan lengkap</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 spacer-field-sga" style="margin-top: 30px;">
          <p>Dengan menyetujui formulir ini, Orang tua akan menerima Kartu Penjemput dan/atau penanda lainnya sebagai tanda masuk resmi kendaraan siswa SD Gemala Ananda. Orang tua juga bersedia menempelkan Kartu Penjemput dan/atau penanda lain tsb. pada kaca depan sebelah kiri atas pada kendaraan bernomor polisi sesuai dengan yang terdaftar, serta meletakkan nomor kendaraan penjemput pada tempat yang terlihat.
            Orang tua memahami bahwa sesuai perkembangan sekolah, dimungkinkan adanya perubahan sistem sarana-prasarana, termasuk sistem penjemputan, dan bersedia untuk mengikuti ketetapan yang berlaku.
          </p>
          <div class="form-check form-check-inline spacing-checkbox-sga">
            <input class="form-check-input" v-model="dataFormulir.setuju" type="checkbox" id="inlineCheckbox1" value="Facebook" required="">
            <normal-label2 class="form-check-label" for="inlineCheckbox1">Kami menyatakan setuju</normal-label2>
          </div>
        </div>
      </div>
      <!---closing-row-->
    </div>
  </div>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import FileUpload from "primevue/fileupload";
import FileLister from "@/components/FileLister";
export default {
  name: "PageFormulirKendaraan",
  components: { FileUpload,FileLister },
  props:{
    initdata: undefined,
    idanak:String,
  },
  emits: ['change-data'],
  data:()=>({
    showupload: true,
    classupload1:'',
    urlupload:'',
    loading:false,
    filelist:[],
    uploadLabel1:'',
    dataFormulir:{
      datakendaraan:'',
      namaayah:'',
      namaibu:'',
      anak:{
        nama:'',
        jeniskelamin:'',
      },
      penjemput1:{
        nama:'',
        jeniskelamin:'',
        hubungan:'',
        ktp:'',
      },
      penjemput2:{
        nama:'',
        jeniskelamin:'',
        hubungan:'',
        ktp:'',
      },
      kendaraan1:{
        merk:'',
        nopol:'',
      },
      kendaraan2:{
        merk:'',
        nopol:'',
      },
      kendaraan3:{
        merk:'',
        nopol:'',
      },
      setuju:''
    }
  }),
  methods:{
    beforeUploadSatu(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','kendaraan');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgressSatu(event){
      this.isupload1 = true;
      this.uploadprogress1 = event.progress;
    },
    onUploadSatu(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.classupload1='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload1='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload1 = false;
    },
    onSelectSatu(event){
      if(event.files.length >0){
        //this.uploadLabel1 = event.files[0].name;
      }
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paramname:this.idanak,
        filecat:'kendaraan'
      };
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              if (typeof this.response.data.kendaraan !== 'undefined') {
                this.filelist = this.response.data.kendaraan;
              }
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
    this.getDataUploadedFile();
  }
}
</script>

<style scoped>

</style>