<template>
  <div class="row">
    <div class="col-12">
      <div class="table-data-sga">
        <div class="table-responsive">
          <table class="table table-striped ">
            <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Formulir</th>
              <th scope="col">Tanggal</th>
              <th scope="col">Nama ananda</th>
              <th scope="col">Nama orang tua</th>
              <th scope="col">Jumlah</th>
            </tr>
            </thead>
            <tbody >
            <tr v-for="(anak,idx) in datapendaftaran" :key="idx">
              <td>{{++idx}}</td>
              <td>{{anak.childinvid}}</td>
              <td>{{anak.tgl}}</td>
              <td>{{anak.namaanak}}</td>
              <td>{{anak.namaortu}}</td>
              <td>{{anak.jumlah}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabelDataBiayaPelunasan",
  props: {
    datapendaftaran:undefined,
    buttontitle: String,
    routepath: String,
    withbutton: Boolean,
    withharga: Boolean,
    ispembayaran: Boolean
  },
  computed:{
  },
}
</script>

<style scoped>

</style>