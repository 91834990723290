<template>
  <div class="container breadcrumb-area">
    <div class="row">
      <div class="col-12 breadcrumb-text">
        <router-link v-for="(p,idx) in listpage" :key="idx" :to=generateLink(p,idx) class="text-reset text-capitalize">{{p}}<span v-if="idx!=Object.keys(listpage).length - 1">></span></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  props:{
    page: String
  },
  data: () => ({
    listpage: []
  }),
  methods:{
    display(p,idx){
      if(idx < this.listpage.length-1){
        return "<a href="+this.generateLink(p,idx)+" class='text-reset text-capitalize'>"+p+"</a> >";
      }else {
        if(this.listpage.length>1){
          return "<span class='font-weight-bold text-capitalize'>"+p+"</span>";
        }else{
          return p;
        }
      }
    },
    generateLink(page,idx){
      if(idx === this.listpage.length-1){
        return '#';
      }else{
        switch (page.trim().toLowerCase()){
          case 'beranda':
            return '/beranda';
          case 'pendaftaran':
            return '/gemala/pendaftaran';
          case 'pembelian formulir':
            return '/gemala/formulir/pengisian';
          default:
            return '/beranda'
        }
      }
    }
  },
  watch: {
    page: {
      immediate: true,
      deep: false,
      handler() {
        this.listpage = this.page.split('>');
      }
    }
  }
}
</script>

<style scoped>

</style>