<template>
  <!--DATA PRIBADI-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>E. Kontak darurat (bila orang tua tidak dapat dihubungi)
    </h3><hr><keterangan-form>Mohon pastikan orang yang ditunjuk, mengetahui dan menyetujui namanya dicantumkan sebagai kontak darurat.</keterangan-form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <label>Nama lengkap</label>
          <input type="text" v-model="dataFormulir.nama" class="w-100 form-control" required>
          <span class="keterangan-form">Tuliskan nama lengkap</span>
        </div>
        <div class="col-12 spacer-field-sga">
          <label>Hubungan dengan siswa</label>
          <input type="text" v-model="dataFormulir.hubungan" class="w-100 form-control" required>
        </div>
        <div class="col-12 spacer-field-sga">
          <label>Telepon genggam</label>
          <input type="text" v-model="dataFormulir.hp" class="w-100 form-control" required>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <span class="normal-label">No. Telepon Rumah</span>
          <input type="text" v-model="dataFormulir.telprumah" class="w-100 form-control"  >
        </div>
        <div class="col-12 spacer-field-sga">
          <span class="normal-label">Telepon kantor</span>
          <input type="text" v-model="dataFormulir.telpkantor" class="w-100 form-control" >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFormKontakDarurat",
  props:{
    data:[],
    initdata: undefined
  },
  emits: ['change-data'],
  data:()=>({
    loading: false,
    message:'',
    success:false,
    dataFormulir:{
      nama:'',
      hubungan:'',
      hp:'',
      telprumah:'',
      telpkantor:''
    }
  }),
  mounted() {
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
  }
}
</script>

<style scoped>

</style>