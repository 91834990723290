<template>
  <HeaderAdmin></HeaderAdmin>
  <PageParameterTahunAjaran v-if="page==='setup-tahunajaran'" :initdata="1"></PageParameterTahunAjaran>
  <PageUpdateJadwalWawancara v-if="page==='xx'"></PageUpdateJadwalWawancara>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderAdmin from "@/components/HeaderAdmin";
import FooterWeb from "@/components/FooterWeb";
import PageParameterTahunAjaran from "@/components/viewadmin/tahunajaran/PageParameterTahunAjaran";
import PageUpdateJadwalWawancara from "@/components/viewadmin/jadwalwawancarapsikolog/PageUpdateJadwalWawancara";
export default {
  name: "routerViewEditAdmin",
  components: {PageUpdateJadwalWawancara, PageParameterTahunAjaran, FooterWeb, HeaderAdmin},
  data:()=>({
    page:'',
    id:'',
  }),
  watch:{
    '$route.params.page':{
      immediate: true,
      deep: false,
      handler(){
        this.page = this.$route.params.page.toLowerCase();
      }
    },
    '$route.params.id':{
      immediate: true,
      deep: false,
      handler(){
        this.id = this.$route.params.id.toLowerCase();
      }
    }
  },
}
</script>

<style scoped>

</style>