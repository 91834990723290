<template>
  <!--Pernyataan Orang Tua   -->
  <surat-pernyataan-orangtua>
    <div class="row">
      <div class="col-12">
        <label :class="v$.dataFormulir.setujuketentuan.$error?'text-danger':''">Dengan ini saya menyatakan setuju dengan ketentuan sekolah sebagai mana terlampir pada tautan berikut : <a href="https://sa.gemalaananda.sch.id/file/KetentuanUmum.pdf" target="_blank">Ketentuan Umum Sekolah Gemala Ananda</a></label>
        <div class="form-check form-check-inline spacing-checkbox-sga">
          <input v-model="dataFormulir.setujuketentuan" class="form-check-input" type="checkbox" id="inlineCheckbox1" value="ya"  required>
          <normal-label2 class="form-check-label" for="inlineCheckbox1">Kami menyatakan setuju</normal-label2>
        </div>
      </div></div>
  </surat-pernyataan-orangtua>
  <!--Penyerahan dokumen "Persetujuan Ketentuan Umum" -->
  <div class="row ">
    <div class="col-12">
      <hr><h3>Penyerahan dokumen "Persetujuan Ketentuan Umum"
    </h3><hr>
    </div>
  </div>

  <surat-pernyataan-orangtua>
    <p>Harap unduh surat persetujuan ketentuan umum dari tautan berikut ini ( <a href="https://sa.gemalaananda.sch.id/file/KetentuanUmum.pdf" target="_blank"> <i class="fa-solid fa-download"></i> Berkas surat ketentuan umum</a> ), <strong>kemudian cetak dan unggah berkas yang sudah ditandatangani di atas meterai</strong>.</p>
    <p>Harap <strong>kirimkan berkas persetujuan ketentuan umum  asli</strong> yang telah ditandatangani basah <strong>di atas meterai</strong> ke alamat berikut : <br><br>
      <em>SD Gemala Ananda
        Bagian Administrasi<br>
        Jl. Lebak Bulus III/93, Gn. Balong<br>
        Cilandak, Jakarta 12440</em></p>

    <div class="row">
      <div class="col-12 spacer-field-sga">
        <label :class="v$.dataFormulir.dokumen.$error?'text-danger':''">Unggah dokumen Persetujuan Ketentuan Umum</label>
        <div class="custom-file">
          <FileLister v-if="files.ketentuanumum.size!=0" :filedata="files.ketentuanumum.list" :num-visible="files.ketentuanumum.size"></FileLister>
          <ProgressBar v-if="isupload" class="mt-2 mb-2" :value="uploadprogress" style="height: 10px;font-size: 14px"></ProgressBar>
          <FileUpload v-if="showupload" :class="classupload" :fileLimit="1" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel" @before-send="beforeUpload" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="1200000" @progress="onProgress" @upload="onUpload" @select="onSelect">
            <template #empty>
              <p>Drag and drop files to here to upload.</p>
            </template>
          </FileUpload>
          <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg, .pdf</keterangan-form>
        </div>
      </div>
    </div>
  </surat-pernyataan-orangtua>
</template>

<script>
//import "primevue/resources/themes/lara-light-indigo/theme.css";
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import useValidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import DashboardServices from "@/services/dashboard.services";
import FileLister from "@/components/FileLister";
export default {
  name: "PageFormKetentuanUmum",
  props:{
    data:[],
    idanak:String,
    initdata: undefined,
    showupload: Boolean
  },
  components:{FileLister, FileUpload,ProgressBar},
  emits: ['change-data','form-ok'],
  data:()=>({
    v$: useValidate(),
    urlupload: '',
    uploadprogress:0,
    classupload:'',
    uploadLabel: 'Pilih',
    isupload: false,
    formerror:true,
    files:{
      ketentuanumum:{
        size:0,
        list:[]
      }
    },
    dataFormulir:{
      setujuketentuan:{checked: value => value === true},
      dokumen:''
    }
  }),
  methods:{
    validate() {
      this.v$.dataFormulir.$validate();
    },
    beforeUpload(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','ketentuanumum');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgress(event){
      this.isupload = true;
      this.uploadprogress = event.progress;
    },
    onUpload(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.dokumen = obj.data.fileid;
        this.classupload='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload = false;
    },
    onSelect(event){
      if(event.files.length >0){
        //this.uploadLabel = event.files[0].name;
      }
      this.photo = '';
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.idanak};
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              if (typeof this.response.data.ketentuanumum != "undefined") {
                this.files.ketentuanumum.list = this.response.data.ketentuanumum.list;
                this.files.ketentuanumum.size = this.response.data.ketentuanumum.size;
                if(this.files.ketentuanumum.size>0){
                  this.dataFormulir.dokumen = 'uploaded';
                }
              }
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    this.validate();
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
    //this.$emit('change-data',this.dataFormulir);
    this.$emit('form-ok',{id:5, value:this.v$.dataFormulir.$error});
    this.getDataUploadedFile();
  },
  validations() {
    return {
      dataFormulir:{
        setujuketentuan:{checked: value => value === true},
        dokumen:{required},
      },
    }
  },
}
</script>

<style scoped>

</style>