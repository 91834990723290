<template>
  <BreadCrumb page="Beranda > Pendaftaran > Penilaian > Pengaturan Jadwal Kelas Coba Belajar"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Pengaturan Jadwal Kelas Coba Belajar Tahun Ajaran {{this.$store.getters.getTahunAJaran}}</h3>
        <normal-label>Status jadwal : <select v-model="statusjadwal" class="sga-status-dropdown">
          <option value="ditutup">Dalam persiapan</option>
          <option value="dibuka">Dibuka</option>
        </select></normal-label>
        <LoadSpinner v-if="loading"></LoadSpinner>
        <button v-else @click="ubahStatusKelasPercobaan" href="#" class="btn btn-sm btn-success">Ubah</button>
        <hr>
        <div class="notes-notification-sga">
          <p><strong>Catatan : </strong>
          <ul>
            <li>Setiap hari dipersiapkan jadwal kelas coba belajar yang terdiri dari 4 slot perhari.</li>
            <li><strong>Setelah jadwal dipersiapkan</strong>, ubah status jadwal kelas coba belajar <strong>menjadi
              dibuka</strong> agar orang tua mulai dapat memilih jadwal
            </li>
          <li>Setiap perubahan status menjadi <strong>"Dibuka"</strong>, akan mengirimkan email informasi ke pengguna, pastikan data sesuai sebelum melakukan ubah data.</li>
          </ul>
          </p></div>
          <div class="form-general">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-striped ">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Hari</th>
                      <th scope="col">Tanggal</th>
                      <th scope="col">Slot</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(dataKelas,idx) in dataKelasPercobaan" :key="idx">
                      <th scope="row">{{dataKelas.no}}</th>
                      <td>{{ dataKelas.namahari }}</td>
                      <td>{{ dataKelas.tanggal}}</td>
                      <td>{{ dataKelas.slot_id}}</td>
                      <td>{{ dataKelas.status}}</td>
                      <td>
                        <LoadSpinner v-if="loadingdelete"></LoadSpinner>
                        <a href="#" v-else @click="deleteSlotKelasPercobaan(dataKelas.id_kelas_percobaan)"><i class="fa-solid fa-trash"></i></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr>
            <h4>Tambah jadwal</h4>
            <div class="row">
              <div class="col-md-11">
                <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
                <AlertDanger v-else :message="messagetrx"></AlertDanger>
              </div>
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-3 spacer-field-sga">
                    <div class="datepicker date input-group">
                      <input type="date" placeholder="" v-model="tanggalkelas" class="date-picker-sga form-control" id=""
                             required="">
                    </div>
                    <keterangan-form>tekan simbol kalendar, pilih bulan, hari & tanggal</keterangan-form>
                  </div>
                  <div class="col-md-3 button-area-sga text-left">
                    <LoadSpinner v-if="loading"></LoadSpinner>
                    <button v-else type="button" @click="saveDataKelasPercobaan" class="btn btn-md btn-success"><i class="fa-solid fa-caret-right"></i> Tambah jadwal</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import AlertDanger from "@/components/AlertDanger";
import AlertSuccess from "@/components/AlertSuccess";
import LoadSpinner from "@/components/LoadSpinner";

export default {
  name: "SetupKelasPercobaan",
  components: {LoadSpinner, AlertSuccess, AlertDanger, BreadCrumb},
  data:()=>({
    loading: false,
    loadingdelete: false,
    response: '',
    message: '',
    success: false,
    successtrx: false,
    statusjadwal:"",
    messagetrx: '',
    tanggalkelas:'',
    dataKelasPercobaan:[],
  }),
  methods:{
    setStatusKelas(status){
      if(status!=='DIBUKA'){
        this.statusjadwal = 'ditutup';
      }else{
        this.statusjadwal = 'dibuka';
      }
    },
    getDataKelasPercobaan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataKelasPercobaan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataKelasPercobaan = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    deleteSlotKelasPercobaan(idslot){
      this.loadingdelete = true;
      this.$store.commit('loading',this.loadingdelete);
      let data = {
        paramname: idslot,
        status: 'ACTIVE'
      };
      let path = '/dashboard/deleteSlotKelasPercobaan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.messagetrx = response.data.message;
            if(response.data.status===200){
              this.successtrx = true;
              this.getDataKelasPercobaan();
            }else {
              this.successtrx = false;
            }
            this.loadingdelete = false;
            this.$store.commit('loading',this.loadingdelete);
          }
      );
    },
    saveDataKelasPercobaan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paramname: this.tanggalkelas,
        status: 'ACTIVE'
      };
      let path = '/dashboard/simpanKelasPercobaan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.messagetrx = response.data.message;
            if(response.data.status===200){
              this.successtrx = true;
              this.getDataKelasPercobaan();
            }else {
              this.successtrx = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    ubahStatusKelasPercobaan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paramname: this.statusjadwal,
        status: 'ACTIVE'
      };
      let path = '/dashboard/updateStatusKelasPercobaan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.messagetrx = response.data.message;
            if(response.data.status===200){
              this.successtrx = true;
              this.getDataKelasPercobaan();
            }else {
              this.successtrx = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataKelasPercobaan();
  }
}
</script>

<style scoped>

</style>