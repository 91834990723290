<template>
  <div class="row">
    <div class="col-md-2 my-auto">
      <span class="normal-label">{{ label }}</span>
    </div>
    <div class="col-md-10">
      <div class="row">
        <div class="col-md-3 col-keterangan-form">
          <input type="text" v-model="anak.nama" class="w-100 form-control">
          <span class="keterangan-form">Tuliskan nama lengkap</span>
        </div>
        <div class="col-md-3 col-keterangan-form">
          <select v-model="anak.jeniskelamin" class="w-100 form-control" >
            <option value="" disabled selected>Pilih</option>
            <option value="L">Lelaki</option>
            <option value="P">Perempuan</option>
          </select>
          <span class="keterangan-form">Jenis kelamin (tulis L untuk lelaki dan P untuk perempuan)</span>
        </div>
        <div class="col-md-3 col-keterangan-form">
          <input type="date" v-model="anak.ttl" class="w-100 form-control">
          <span class="keterangan-form">Tanggal lahir (gunakan format DD/MM/YYYY)</span>
        </div>
        <div class="col-md-3 col-keterangan-form">
          <input type="text" v-model="anak.sekolah" class="w-100 form-control">
          <span class="keterangan-form">Sekolah</span>
        </div>
      </div>
    </div>
  </div>
  <hr class="hr-spacer">
</template>

<script>
export default {
  name: "PageFormDataAnak",
  props: {
    label: String,
    data:[],
    initdata: undefined
  },
  emits: ['change-data'],
  data:()=>({
    anak:{
      nama:'',
      jeniskelamin:'',
      ttl:'',
      sekolah:''
    }
  }),
  mounted() {
    this.$emit('change-data',this.initdata);
    this.anak = this.initdata;
  }
}
</script>

<style scoped>

</style>