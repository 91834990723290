<template>
  <div class="wrapper-login-daftar-sga">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <div class="logo-login">
            <img :src="logo" class="img-fluid"></div>
          <h2>Buat Akun</h2>
          <p>Harap masukkan data data dengan lengkap dan sesuai untuk akun Anda. Selanjutnya kami akan mengirimkan
            surel untuk melakukan validasi alamat surel Anda.</p>
          <div class="form-login-daftar">
            <form class="needs-validation" novalidate>
              <div class="row">
                <div class="col-md-6 text-left">Nama pengguna</div>
                <div class="col-md-6 text-left"><input v-model="accountData.nama" type="text" class="w-100 form-control" :disabled="!loading ? disabled : ''" required>
                  <span class="keterangan-form text-danger" v-if="v$.accountData.nama.$error">Mohon isi username</span>
                </div>
                <div class="col-md-6 text-left spacer-field-sga">Alamat surel</div>
                <div class="col-md-6 spacer-field-sga text-left"><input v-model="accountData.email" type="text"
                                                                        class="w-100 form-control" :disabled="!loading ? disabled : ''" required>
                  <span class="keterangan-form text-danger" v-if="v$.accountData.email.$error">Mohon isi email anda</span>
                </div>
                <div class="col-md-6 text-left spacer-field-sga">Ulangi alamat surel</div>
                <div class="col-md-6  spacer-field-sga text-left"><input v-model="accountData.email2" type="text"
                                                                         class="w-100 form-control" :disabled="!loading ? disabled : ''"
                                                                         required>
                  <span class="keterangan-form text-danger" v-if="v$.accountData.email2.$error">Mohon isi dan cek kembali email anda</span>
                </div>
                <div class="col-md-6 text-left spacer-field-sga">Kata sandi</div>
                <div class="col-md-6 text-left spacer-field-sga"><input v-model="accountData.password" type="password"
                                                                        class="w-100 form-control" :disabled="!loading ? disabled : ''" required>
                  <span class="keterangan-form text-danger" v-if="v$.accountData.password.$error">Mohon isi password anda</span>
                </div>
                <div class="col-md-6 text-left spacer-field-sga">Ulangi kata sandi</div>
                <div class="col-md-6 text-left spacer-field-sga"><input v-model="accountData.password2" type="password"
                                                                        class="w-100 form-control" :disabled="!loading ? disabled : ''" required>
                  <span class="keterangan-form text-danger" v-if="v$.accountData.password2.$error">Mohon isi dan cek kembali password anda</span>
                </div>
                <div class="col-md-12 text-right spacer-field-sga">
                  <AlertSuccess v-if="success" :message="message"></AlertSuccess>
                  <AlertDanger v-else :message="message"></AlertDanger>
                  <ButtonLoading v-if="loading"></ButtonLoading>
                  <button v-else class="btn btn-md btn-success" type="button" @click="doSubmitRegister"><i
                      class="fa-solid fa-caret-right"></i> Daftar
                  </button>
                </div>
                <div class="col-md-12 text-left spacer-field-sga" v-if="!loading">
                  <span>Sudah memiliki akun?. <router-link to="/login">Layar login</router-link></span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import {required,email,minLength} from '@vuelidate/validators'
import ButtonLoading from "@/components/ButtonLoading";
import AlertDanger from "@/components/AlertDanger";
import DashboardServices from "@/services/dashboard.services";
import AlertSuccess from "@/components/AlertSuccess";
export default {
  name: "PageRegister",
  components: {AlertSuccess, AlertDanger,ButtonLoading},
  methods:{
    doClearForm(){
      this.accountData.nama = '';
      this.accountData.email = '';
      this.accountData.email2 = '';
      this.accountData.password = '';
      this.accountData.password2 = '';
    },
    doSubmitRegister(){
      this.v$.accountData.$validate();
      if(!this.v$.accountData.$error){
        this.loading = true;
        let data = this.accountData;
        let path = '/api/register';
        DashboardServices.postData(data,path).then(
            (response)=>{
              this.response = response.data;
              this.message = response.data.message;
              if(response.data.status===200){
                this.success = true;
              }else {
                this.success = false;
              }
              this.loading = false;
            }
        );
      }else{
        this.loading = false;
        this.message = 'Mohon isi data dengan benar.';
      }
    }
  },
  data:()=>({
    v$: useValidate(),
    loading: false,
    logo: require('@/assets/images/logo-sga.png'),
    imgLoading: require('@/assets/images/loading.gif'),
    response:'',
    message: '',
    success: false,
    accountData:{
      nama:'',
      email:'',
      email2:'',
      password:'',
      password2:'',
    }
  }),
  validations() {
    return {
      accountData: {
        nama:{required,minLength:minLength(2)},
        email:{required,email},
        email2:{required,email},
        password:{required,minLength:minLength(8)},
        password2:{required,minLength:minLength(8)},
      }
    }
  },
  beforeCreate: function() {
    document.body.style="";
    document.body.className = 'home';
  }
}
</script>

<style scoped>
body.home {
  padding-top: 0px!important;
}
</style>