<template>
  <HeaderParent></HeaderParent>
  <ParentIndex></ParentIndex>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderParent from "@/components/HeaderParent";
import FooterWeb from "@/components/FooterWeb";
import ParentIndex from "@/components/ParentIndex";
export default {
  name: "routeOrangTua",
  components: {
    ParentIndex,
    FooterWeb,
    HeaderParent,
  }
}
</script>

<style scoped>

</style>