<template>
  <div class="modal show" id="modalpilihjadwal" style="display: block;" aria-modal="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h3 class="modal-title">Pilih Jadwal Kelas Coba Belajar</h3>
          <button @click="closeForm" type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body custom-modal-sga-scroll" v-if="belumpilih">
          <div class="row">
            <div class="col-12">
              <form>
                <div class="form-general">
                  <div class="row">
                    <div class="col-12">
                      <div class="notes-notification-sga">
                        <p><strong>Catatan : </strong></p><ul> <li>Silahkan pilih dari jadwal yang tersedia untuk pelaksanaan proses penilaian Ananda. Jadwal yang sudah dipilih tidak dapat diubah.</li></ul></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-striped ">
                          <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Hari</th>
                            <th scope="col">Tanggal</th>
                            <th scope="col">Slot</th>
                            <th scope="col">Pilihan jadwal</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(kelas,idx) in dataKelasPercobaan" :key="idx">
                            <th scope="row">{{kelas.no}}</th>
                            <td>{{kelas.namahari}}</td>
                            <td>{{kelas.tanggalid}}</td>
                            <td>{{kelas.slot_id}}</td>
                            <td><div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineCheckbox3" :value="kelas.id_kelas_percobaan" v-model="slot_id" name="sesi">
                              <normal-label2 class="form-check-label" for="inlineCheckbox3"></normal-label2>
                            </div>
                            </td>
                          </tr>
                          <tr v-if="dataKelasPercobaan.length===0">
                            <td colspan="5">Maaf slot kelas coba belajar belum tersedia.</td>
                          </tr>
                          </tbody>
                        </table><hr>
                      </div>
                    </div></div>
                  <div class="row">
                    <div class="col-12 spacer-field-sga text-right" v-if="dataKelasPercobaan.length!=0">
                      <button :disabled="isDisabled" type="button" @click="savePilihanJadwal" class="btn btn-success btn-md" data-dismiss="modal" data-toggle="modal" data-target="#modalpilihnotifikasi"><i class="fa-solid fa-caret-right"></i> Pilih jadwal</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-body" v-if="!belumpilih">
          <div class="row">
            <div class="col-12">
              <p>Terima kasih</p>
              <p>
                Anda telah memilih jadwal untuk pelaksanaan kelas coba belajar untuk Ananda
              </p>
              <p>Anda akan menerima email konfirmasi jadwal sekaligus informasi mengenai <strong>kelengkapan
                yang harus dibawa</strong> dan <strong>dipersiapkan</strong> untuk kelas coba belajar
              </p>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button @click="closeForm" type="button" class="btn btn-danger mr-auto" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import useValidate from '@vuelidate/core'
import {required} from "@vuelidate/validators/dist/index";
export default {
  name: "PageModalPilihJadwal",
  props:{
    showmodal:Boolean,
    idanak: String,
  },
  data:()=>({
    v$: useValidate(),
    close: false,
    belumpilih: true,
    dataKelasPercobaan:[],
    slot_id:'',
  }),
  computed:{
    isDisabled(){
      if(this.slot_id>0){
        return false;
      }else{
        return true;
      }
    }
  },
  methods:{
    closeForm(){
      this.close = true;
      this.$emit('change-modal',false);
    },
    getDataKelasPercobaan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataKelasPercobaan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataKelasPercobaan = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    savePilihanJadwal(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        idanak:this.idanak,
        idslot:this.slot_id
      };
      let path = '/dashboard/simpanKelasPercobaan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataKelasPercobaan = this.response.data;
              this.belumpilih = false;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  validations(){
    return {
      slot_id:{required},
    }
  },
  mounted() {
    this.getDataKelasPercobaan();
  }
}
</script>

<style scoped>
.row {
  overflow-y: scroll;
}
.col-sm-8 {
  color: white;
  font-size: 24px;
  padding-bottom: 20px;
  padding-top: 18px;
}
.col-sm-8:nth-child(2n+1) {
  background: green;
}
.col-sm-8:nth-child(2n+2) {
  background: black;
}
</style>