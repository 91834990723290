<template>
  <!--Formulir-kesehatan-->
  <!--Data Umum-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Data Umum
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <h5>Data kelahiran</h5>
      <hr>
      <kelahiran>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12 spacer-field-sga "><label>Kelahiran</label></div>
              <div class="col-12 spacer-field-sga ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.kelahiran" type="radio"  value="Normal" name="kelahiran" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">Normal</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.kelahiran" type="radio"  value="Caesar" name="kelahiran" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox2">Caesar</normal-label2>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12  spacer-field-sga "><label>Lahir prematur</label></div>
              <div class="col-12 spacer-field-sga ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.prematur" type="radio"  value="Ya" name="prematur" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.prematur" type="radio"  value="Tidak" name="prematur" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak</normal-label2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 spacer-field-sga">
            <label>Berat lahir</label>
            <input type="text" v-model="dataFormulir.beratlahir" class="w-100 form-control"  required>
            <keterangan-form>dalam kg</keterangan-form>
          </div>
          <div class="col-md-6 spacer-field-sga">
            <label>Panjang lahir</label>
            <input type="text" v-model="dataFormulir.panjanglahir" class="w-100 form-control"  required>
            <keterangan-form>dalam cm</keterangan-form>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12  spacer-field-sga "><label>Metode kelahiran?</label></div>
              <div class="col-12 spacer-field-sga ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.metodekelahiran" type="radio"  value="Vacum" name="Vacum" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">Vacum</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.metodekelahiran" type="radio"  value="Forceps" name="Forceps" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">Forceps</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.metodekelahiran" type="radio"  value="Normal" name="Normal" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">Normal</normal-label2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 spacer-field-sga "><label>Pernah kejang?</label></div>
              <div class="col-12 spacer-field-sga ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.pernahkejang" type="radio"  value="Ya" name="kejang" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.pernahkejang" type="radio"  value="Tidak" name="kejang" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak</normal-label2>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12  spacer-field-sga "><label>Pernah <i>Speech delay</i>?</label></div>
              <div class="col-12 spacer-field-sga ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.speechdelay" type="radio"  value="Ya" name="speech" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.speechdelay" type="radio"  value="Tidak" name="speech" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak</normal-label2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12  spacer-field-sga "><label>Pernah ikut terapi atau pernah disarankan ikut terapi berikut?</label></div>
              <div class="col-12 spacer-field-sga ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.terapi" type="checkbox" value="okupasi" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">Okupasi</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.terapi" type="checkbox" value="sensori integrasi" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox2">Integrasi sensori</normal-label2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 spacer-field-sga">
            <label>Golongan darah</label>
            <input type="text" v-model="dataFormulir.golongandarah" class="w-100 form-control"  required>
          </div>
        </div>
      </kelahiran>

    </div>
    <div class="col-md-6 spacer-col-sga">
      <h5>Data saat ini</h5>
      <hr>
      <saatini>
        <div class="row">
          <div class="col-md-6 spacer-field-sga">
            <label>Berat badan</label>
            <input type="text" v-model="dataFormulir.beratbadan" class="w-100 form-control"  required>
            <keterangan-form>dalam kg</keterangan-form>
          </div>
          <div class="col-md-6 spacer-field-sga">
            <label>Tinggi</label>
            <input type="text" v-model="dataFormulir.tinggibadan" class="w-100 form-control"  required>
            <keterangan-form>dalam cm</keterangan-form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12 spacer-field-sga "><label>Berkacamata?</label></div>
              <div class="col-12 spacer-field-sga ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.berkacamata" type="radio"  value="Ya" name="katamata" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.berkacamata" type="radio"  value="Tidak" name="katamata" required>
                  <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak</normal-label2>
                </div>
              </div>

            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12  spacer-field-sga "><normal-label>Bila berkacamata</normal-label></div>
              <div class="col-12 spacer-field-sga ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.jeniskacamata" type="checkbox"  value="minus" name="preskripsikacamata" >
                  <normal-label2 class="form-check-label" for="inlineCheckbox1">minus</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.jeniskacamata" type="checkbox"  value="plus" name="preskripsikacamata" >
                  <normal-label2 class="form-check-label" for="inlineCheckbox2">plus</normal-label2>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataFormulir.jeniskacamata" type="checkbox"  value="silindris" name="preskripsikacamata" >
                  <normal-label2 class="form-check-label" for="inlineCheckbox2">silindris</normal-label2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 spacer-field-sga">
            <normal-label>Preskripsi kacamata mata kiri</normal-label>
            <input type="text" v-model="dataFormulir.kacamatakiri" class="w-100 form-control"  required>
          </div>
          <div class="col-md-6 spacer-field-sga">
            <normal-label>Preskripsi kacamata mata kanan</normal-label>
            <input type="text" v-model="dataFormulir.kacamatakanan" class="w-100 form-control"  required>
          </div>
        </div>
      </saatini>
    </div>
  </div>


  <!--Imunisasi-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Imunisasi
    </h3><hr>
    </div>
  </div>
  <imunisasi>

    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>Hepatitis B</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit-desktop >Tanggal imunisasi ke I </normal-label-khusus-penyakit-desktop >
        <input type="date" v-model="dataFormulir.imunisasi.hepatitisB.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit-desktop >Tanggal imunisasi ke II </normal-label-khusus-penyakit-desktop >
        <input type="date" v-model="dataFormulir.imunisasi.hepatitisB.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit-desktop >Tanggal imunisasi ke III </normal-label-khusus-penyakit-desktop >
        <input type="date" v-model="dataFormulir.imunisasi.hepatitisB.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>

      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit-desktop >Booster  </normal-label-khusus-penyakit-desktop >
        <input type="date" v-model="dataFormulir.imunisasi.hepatitisB.Booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit-desktop >Keterangan  </normal-label-khusus-penyakit-desktop >
        <input type="text" v-model="dataFormulir.imunisasi.hepatitisB.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>

  <imunisasi>
    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>BCG</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke I </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.BCG.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke II </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.BCG.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">

        <normal-label-khusus-penyakit>Tanggal imunisasi ke III </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.BCG.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Booster  </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.BCG.booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Keterangan  </normal-label-khusus-penyakit>
        <input type="text" v-model="dataFormulir.imunisasi.BCG.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>
  <imunisasi>
    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>DPT</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke I </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.DPT.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke II </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.DPT.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">

        <normal-label-khusus-penyakit>Tanggal imunisasi ke III </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.DPT.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Booster  </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.DPT.booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Keterangan  </normal-label-khusus-penyakit>
        <input type="text" v-model="dataFormulir.imunisasi.DPT.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>
  <imunisasi>
    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>Polio</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke I </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Polio.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke II </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Polio.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">

        <normal-label-khusus-penyakit>Tanggal imunisasi ke III </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Polio.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Booster  </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Polio.booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Keterangan  </normal-label-khusus-penyakit>
        <input type="text" v-model="dataFormulir.imunisasi.Polio.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>
  <imunisasi>
    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>HIB Meningitis</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke I </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.HIB.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke II </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.HIB.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">

        <normal-label-khusus-penyakit>Tanggal imunisasi ke III </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.HIB.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Booster  </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.HIB.booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Keterangan  </normal-label-khusus-penyakit>
        <input type="text" v-model="dataFormulir.imunisasi.HIB.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>
  <imunisasi>
    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>Campak</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke I </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.campak.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke II </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.campak.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke III </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.campak.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Booster  </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.campak.booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Keterangan  </normal-label-khusus-penyakit>
        <input type="text" v-model="dataFormulir.imunisasi.campak.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>
  <imunisasi>
    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>MMR</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke I </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.MMR.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke II </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.MMR.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke III </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.MMR.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Booster  </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.MMR.booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Keterangan  </normal-label-khusus-penyakit>
        <input type="text" v-model="dataFormulir.imunisasi.MMR.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>
  <imunisasi>
    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>Cacar air</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke I </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Cacar.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke II </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Cacar.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke III </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Cacar.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Booster  </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Cacar.booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Keterangan  </normal-label-khusus-penyakit>
        <input type="text" v-model="dataFormulir.imunisasi.Cacar.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>
  <imunisasi>
    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>Tifus</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke I </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Tifus.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke II </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Tifus.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">

        <normal-label-khusus-penyakit>Tanggal imunisasi ke III </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Tifus.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Booster  </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.Tifus.booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Keterangan  </normal-label-khusus-penyakit>
        <input type="text" v-model="dataFormulir.imunisasi.Tifus.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>

  <imunisasi>
    <div class="row">
      <div class="col-lg-2 my-auto"><penyakit>Hepatitis A</penyakit></div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke I </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.HepatitisA.tahap1" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke II </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.HepatitisA.tahap2" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Tanggal imunisasi ke III </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.HepatitisA.tahap3" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2  col-keterangan-form">
        <normal-label-khusus-penyakit>Booster  </normal-label-khusus-penyakit>
        <input type="date" v-model="dataFormulir.imunisasi.HepatitisA.booster" class="w-100 form-control">
        <keterangan-form>(DD/MM/YYYY)</keterangan-form>
      </div>
      <div class="col-lg-2 col-keterangan-form">
        <normal-label-khusus-penyakit>Keterangan  </normal-label-khusus-penyakit>
        <input type="text" v-model="dataFormulir.imunisasi.HepatitisA.keterangan" class="w-100 form-control">
      </div>
    </div>
  </imunisasi>
  <imunisasi>
    <div class="row">
      <div class="col-12 spacer-field-sga">
        <normal-label :class="v$.dataFormulir.filedata.imunisasi.$error?'text-danger':''"><i class="fa-solid fa-image"></i> Unggah foto data imunisasi dari buku catatan kesehatan ananda sebagai penguat</normal-label>
        <div class="custom-file">
          <FileLister v-if="files.fileimunisasi.size!=0" :filedata="files.fileimunisasi.list" :num-visible="files.fileimunisasi.size"></FileLister>
          <ProgressBar v-if="isupload1" class="mt-2 mb-2" :value="uploadprogress1" style="height: 10px;font-size: 14px"></ProgressBar>
          <FileUpload v-if="showupload" :class="classupload1" :showUploadButton="false" :showCancelButton="false" :multiple="false" :fileLimit="1" :chooseLabel="uploadLabel1" @before-send="beforeUploadSatu" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="1200000" @progress="onProgressSatu" @upload="onUploadSatu" @select="onSelectSatu">
            <template #empty>
              <p>Drag and drop files to here to upload.</p>
            </template>
          </FileUpload>
          <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg, .pdf</keterangan-form>
        </div>
      </div>
    </div>
  </imunisasi>
  <imunisasi>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga "><label>Bagaimana sikap orang tua terhadap imunisasi?</label></div>
          <div class="col-12 spacer-field-sga ">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.sikapimunsasi" type="radio"  value="mendukung" name="sikap" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">mendukung</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.sikapimunsasi" type="radio"  value="menolak" name="sikap" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">menolak</normal-label2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <label>Berikan penjelasan</label>
            <textarea v-model="dataFormulir.sikapimunsasipenjelasan" class="w-100 form-control textarea-sga"  required></textarea>
          </div>
        </div>
      </div>
    </div>
  </imunisasi>

  <!--Alergi-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Alergi
    </h3><hr>
    </div>
  </div>
  <alergi>
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div class="col-12 spacer-field-sga "><label>Apakah anak memiliki alergi?</label></div>
          <div class="col-12 spacer-field-sga ">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.alergi" type="radio"  value="Ya" name="alergi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.alergi" type="radio"  value="Tidak" name="alergi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak</normal-label2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <normal-label>Bila ya, berikan penjelasan secara lengkap mengenai alergi terhadap makan, obat-obatan, atau lainnya</normal-label>
            <textarea  v-model="dataFormulir.alergiketerangan" class="w-100 form-control textarea-sga" ></textarea>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <normal-label>Keterangan dokter bila ada</normal-label>
            <textarea v-model="dataFormulir.alergiketerangandokter" class="w-100 form-control textarea-sga" ></textarea>
          </div>
        </div>
      </div>
    </div>
  </alergi>
  <!--Sejarah kesehatan-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Sejarah kesehatan
    </h3><hr>
    </div>
  </div>
  <sejarahkesehatan>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga "><label>Apakah anak memiliki limitasi fisik yang membatasi kegiatannya?</label></div>
          <div class="col-12 spacer-field-sga ">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.limitasifisik" type="radio"  value="Ya" name="sejarah" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.limitasifisik" type="radio"  value="Tidak" name="sejarah" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak</normal-label2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <normal-label>Bila ya, jelaskan</normal-label>
            <textarea v-model="dataFormulir.limitasifisikketerangan" class="w-100 form-control textarea-sga" ></textarea>
          </div>
        </div>
      </div>
    </div>

  </sejarahkesehatan>
  <sejarahkesehatan>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga "><label>Apakah anak pernah dioperasi?</label></div>
          <div class="col-12 spacer-field-sga ">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.pernahoperasi" type="radio"  value="Ya" name="operasi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.pernahoperasi" type="radio"  value="Tidak" name="operasi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak</normal-label2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <normal-label>Bila ya, jelaskan</normal-label>
            <textarea v-model="dataFormulir.pernahoperasiketerangan" class="w-100 form-control textarea-sga" ></textarea>
          </div>
        </div>
      </div>
    </div>
  </sejarahkesehatan>
  <sejarahkesehatan>
    <div class="row">
      <div class="col-12 spacer-field-sga " style="margin-bottom: 15px;"><label>Apakah anak pernah menderita masalah kesehatan berikut? Pada usia berapa? Bila tidak pernah, beri tanda - (strip)</label></div></div>
    <div class="row">
      <div class="col-md-6">
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Batuk rejan</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.batukrejan" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Asma</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.asma" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Eksim</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.eksim" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Rheumatic fever</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.rheumaticfever" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Kejang kejang</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.kejangkejang" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Sakit kepala persisten</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.sakitkepalapersisten" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Infeksi paru persisten</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.infeksiparu" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Infeksi telinga persisten</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.infeksitelingapersisten" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Cacar air</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.cacarair" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Campak</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.campak" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Campak Jerman</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.campakjerman" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
      </div>
      <div class="col-md-6">
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Gondongan</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.gandongan" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Rubella</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.rubella" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Epilepsi</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.epilepsi" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Tuberkulosis</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.tuberkilosis" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Mimisan</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.mimisan" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Gangguan penglihatan</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.gangguanpenglihatan" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Gangguan pendengaran</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.gangguanpendengaran" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Masalah jantung</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.masalahjantung" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Infeksi ginjal/uriner</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.infeksiginjal" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
        <sejarahsakit>
          <div class="row">
            <div class="col-md-5">
              <normal-label>Diabetes</normal-label>
            </div>
            <div class="col-md-3">
              <input type="text" v-model="dataFormulir.riwayatsakit.diabetes" class="w-100 form-control"  required>
              <keterangan-form>pada usia</keterangan-form>
            </div>
          </div>
        </sejarahsakit>
      </div>
    </div>
  </sejarahkesehatan>
  <!--Pengobatan/Terapi Medis Rutin-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Pengobatan/Terapi Medis Rutin
    </h3><hr>
    </div>
  </div>
  <terapimedis>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga "><label>Apakah anak sedang menjalani pengobatan/terapi medis rutin?</label></div>
          <div class="col-12 spacer-field-sga ">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.sedangpengobatanrutin" type="radio"  value="Ya" name="terapipengobatan" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.sedangpengobatanrutin" type="radio"  value="Tidak" name="terapipengobatan" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak</normal-label2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <normal-label>Bila ya, jelaskan kondisi yang diobati, jenis obat/terapi, fungsi, dosis/frekuensi dan cara pemberiannya.</normal-label>
            <textarea v-model="dataFormulir.sedangpengobatanrutinketerangan" class="w-100 form-control textarea-sga" ></textarea>
          </div>
        </div>
      </div>
    </div>
  </terapimedis>
  <!--Kebutuhan Khusus/Kesukaran Belajar -->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Kebutuhan Khusus/Kesukaran Belajar
    </h3><hr>
    </div>
  </div>
  <kebutuhankhusus>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga "><label>Apakah anak memiliki kebutuhan khusus atau kondisi yang dapat menimbulkan kesukaran belajar, misalnya kesukaran berkonsentrasi, autisme, hiperaktivitas, dll. ?</label>
            <keterangan-form>Bila ananda berkebutuhan khusus/berkesulitan belajar namun tidak mencentang “ya”, maka ananda dapat didiskualifikasi atau dibatalkan penerimaannya.</keterangan-form>
          </div>
          <div class="col-12 spacer-field-sga ">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.kesehatankebutuhankhusus" type="radio"  value="Ya" name="berkebutuhankhusus" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.kesehatankebutuhankhusus" type="radio"  value="Tidak" name="berkebutuhankhusus" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak</normal-label2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <normal-label>Bila ya, jelaskan secara singkat dan sertakan keterangan terinci dari dokter/psikolog dalam bentuk lampiran.</normal-label>
            <textarea v-model="dataFormulir.kesehatankebutuhankhususketerangan" class="w-100 form-control textarea-sga" ></textarea>
          </div>
        </div>
      </div>
    </div>
  </kebutuhankhusus>
  <kebutuhankhusus>
    <div class="row">
      <div class="col-12 spacer-field-sga">
        <normal-label><i class="fa-solid fa-image"></i> Lampiran keterangan dokter/psikolog</normal-label>
        <div class="custom-file">
          <FileLister v-if="files.keterangandokter.size!=0" :filedata="files.keterangandokter.list" :num-visible="files.keterangandokter.size"></FileLister>
          <ProgressBar v-if="isupload2" class="mt-2 mb-2" :value="uploadprogress2" style="height: 10px;font-size: 14px"></ProgressBar>
          <FileUpload v-if="showupload" :class="classupload2" :showUploadButton="false" :showCancelButton="false" :multiple="false" :fileLimit="1" :chooseLabel="uploadLabel2" @before-send="beforeUploadDua" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="1200000" @progress="onProgressDua" @upload="onUploadDua" @select="onSelectDua">
            <template #empty>
              <p>Drag and drop files to here to upload.</p>
            </template>
          </FileUpload>
          <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg, .pdf</keterangan-form>
        </div>
      </div>
    </div>
  </kebutuhankhusus>
  <!--Verifikasi Dokter  -->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Verifikasi Dokter
    </h3><hr>
    </div>
  </div>
  <verifikasidokter>
    <div class="row">
      <div class="col-12 spacer-field-sga">
        <normal-label>Saya telah memverifikasi data kesehatan ini dan melakukan pemeriksaan kondisi kesehatan ananda. Berikut adalah penilaian dan rekomendasi saya.</normal-label>
        <textarea rows="5" type="text" v-model="dataFormulir.dokter.penilaian" class="w-100 form-control"> </textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 spacer-field-sga">
        <normal-label><i class="fa-solid fa-image"></i> Foto surat keterangan sehat dari Dokter</normal-label>
        <div class="custom-file">
          <FileLister v-if="files.keterangansehat.size!=0" :filedata="files.keterangansehat.list" :num-visible="files.keterangansehat.size"></FileLister>
          <ProgressBar v-if="isupload3" class="mt-2 mb-2" :value="uploadprogress3" style="height: 10px;font-size: 14px"></ProgressBar>
          <FileUpload v-if="showupload" :class="classupload3" :showUploadButton="false" :showCancelButton="false" :multiple="false" :fileLimit="10" :chooseLabel="uploadLabel3" @before-send="beforeUploadTiga" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="1200000" @progress="onProgressTiga" @upload="onUploadTiga" @select="onSelectTiga">
            <template #empty>
              <p>Drag and drop files to here to upload.</p>
            </template>
          </FileUpload>
          <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg,.pdf | Maksimum 10 berkas | Minimum 1 berkas</keterangan-form>
        </div>
      </div>
    </div>
  </verifikasidokter>
  <verifikasidokter>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <label>Nama dokter</label>
            <input type="text" v-model="dataFormulir.dokter.nama" class="w-100 form-control" >
          </div>
          <div class="col-12 spacer-field-sga">
            <label>Alamat dokter</label>
            <textarea type="text" v-model="dataFormulir.dokter.alamat" class="w-100 form-control" > </textarea>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <normal-label>Telepon dokter</normal-label>
            <input type="text" v-model="dataFormulir.dokter.telepon" class="w-100 form-control" >
          </div>
          <div class="col-12 spacer-field-sga "><label>Kesediaan dokter</label>
          </div>
          <div class="col-12 spacer-field-sga ">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.dokter.bersedia" type="radio"  value="bersedia" name="dihubungi" >
              <normal-label2 class="form-check-label" for="inlineCheckbox1">
                Dokter bersedia  dihubungi untuk memberikan keterangan lebih lanjut.	</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.dokter.bersedia" type="radio"  value="Tidak bersedia" name="dihubungi" >
              <normal-label2 class="form-check-label" for="inlineCheckbox2">
                Dokter tidak bersedia  dihubungi untuk memberikan keterangan lebih lanjut.	</normal-label2>
            </div>
          </div>
          <div class="col-12 spacer-field-sga">
            <div style="height:100px; border:1px solid #ccc; margin-top:20px; font-size:10px; font-style:italic;">Tanda tangan Dokter</div>
          </div>
        </div>
      </div>
    </div>
  </verifikasidokter>
  <!--Pernyataan Orang Tua   -->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Pernyataan Orang Tua
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <label>Kami memahami dan menyetujui pernyataan berikut</label>
        </div>
        <div class="col-12">
          <div class="form-check form-check-inline spacing-checkbox-sga">
            <input class="form-check-input" v-model="dataFormulir.pernyataanortu.menjamin" type="checkbox"  value="Facebook"  required>
            <normal-label2 class="form-check-label" for="inlineCheckbox1">

              Kami menjamin kebenaran data yang kami berikan. Bila terjadi perubahan data, kami akan segera memberi tahu Sekolah Gemala Ananda secara tertulis.							</normal-label2>
          </div>
          <div class="form-check form-check-inline spacing-checkbox-sga">
            <input class="form-check-input" v-model="dataFormulir.pernyataanortu.memberikanizin" type="checkbox" id="inlineCheckbox3" value="Website"   required>
            <normal-label2 class="form-check-label" for="inlineCheckbox3">
              Bila anak kami diterima dan jadi bersekolah di Sekolah Gemala Ananda, dan kemudian selama bersekolah terjadi kondisi yang memerlukan tindakan medis segera dan/atau darurat sementara kami ataupun kontak darurat yang telah kami tunjuk tidak dapat dihubungi, maka kami memberikan izin kepada Kepala Sekolah Gemala Ananda, atau orang yang ditunjuknya, untuk bertindak untuk dan atas nama kami dalam penanganan keadaan darurat tersebut.	</normal-label2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import useValidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import DashboardServices from "@/services/dashboard.services";
import FileLister from "@/components/FileLister";
export default {
  name: "PageFormKesehatan",
  props:{
    data:[],
    idanak:String,
    initdata: undefined,
    showupload: Boolean
  },
  components:{FileLister, ProgressBar,FileUpload},
  emits: ['change-data'],
  data:()=>({
    v$: useValidate(),
    loading: false,
    message:'',
    success:false,
    urlupload:'',
    classupload1:'',
    classupload2:'',
    classupload3:'',
    isupload1: false,
    isupload2: false,
    isupload3: false,
    uploadprogress1:0,
    uploadprogress2:0,
    uploadprogress3:0,
    uploadLabel1: 'Pilih',
    uploadLabel2: 'Pilih',
    uploadLabel3: 'Pilih',
    files:{
      fileimunisasi:{
        size:0,
        list:[]
      },
      keterangandokter:{
        size:0,
        list:[]
      },
      keterangansehat:{
        size:0,
        list:[]
      },
    },
    dataFormulir:{
      kelahiran:'',
      prematur:'',
      beratlahir:'',
      panjanglahir:'',
      pernahkejang:'',
      speechdelay:'',
      terapi:[],
      metodekelahiran:'',
      golongandarah:'',
      beratbadan:'',
      tinggibadan:'',
      berkacamata:'',
      jeniskacamata:[],
      kacamatakiri:'',
      kacamatakanan:'',
      imunisasi:{
        hepatitisB:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
        BCG:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
        DPT:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
        Polio:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
        HIB:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
        campak:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
        MMR:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
        Cacar:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
        Tifus:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
        HepatitisA:{
          tahap1:'',
          tahap2:'',
          tahap3:'',
          booster:'',
          keterangan:'',
        },
      },
      sikapimunsasi:'',
      sikapimunsasipenjelasan:'',
      alergi:'',
      alergiketerangan:'',
      alergiketerangandokter:'',
      limitasifisik:'',
      limitasifisikketerangan:'',
      pernahoperasi:'',
      pernahoperasiketerangan:'',
      riwayatsakit:{
        batukrejan:'',
        asma:'',
        eksim:'',
        rheumaticfever:'',
        kejangkejang:'',
        sakitkepalapersisten:'',
        infeksiparu:'',
        infeksitelingapersisten:'',
        cacarair:'',
        campak:'',
        campakjerman:'',
        gandongan:'',
        rubella:'',
        epilepsi:'',
        tuberkilosis:'',
        mimisan:'',
        gangguanpenglihatan:'',
        gangguanpendengaran:'',
        masalahjantung:'',
        infeksiginjal:'',
        diabetes:'',
      },
      sedangpengobatanrutin:'',
      sedangpengobatanrutinketerangan:'',
      kesehatankebutuhankhusus:'',
      kesehatankebutuhankhususketerangan:'',
      dokter:{
        penilaian:{},
        nama:{required},
        alamat:{required},
        telepon:'',
        bersedia:'',
      },
      pernyataanortu:{
        menjamin:'',
        bersedia:'',
        memberikanizin:'',
      },
      filedata:{
        imunisasi:'',
        keterangandokter:'',
        keterangansehat:'',
      }
    }
  }),
  methods:{
    validate() {
      this.v$.dataFormulir.$validate();
    },
    beforeUploadSatu(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','fileimunisasi');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgressSatu(event){
      this.isupload1 = true;
      this.uploadprogress1 = event.progress;
    },
    onUploadSatu(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.filedata.imunisasi = obj.data.fileid;
        this.classupload1='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload1='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload1 = false;
    },
    onSelectSatu(event){
      if(event.files.length >0){
        //this.uploadLabel1 = event.files[0].name;
      }
      this.dataFormulir.filedata.imunisasi = '';
    },
    beforeUploadDua(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','keterangandokter');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgressDua(event){
      this.isupload2 = true;
      this.uploadprogress2 = event.progress;
    },
    onUploadDua(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.filedata.keterangandokter = obj.data.fileid;
        this.classupload2='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload2='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload2 = false;
    },
    onSelectDua(event){
      if(event.files.length >0){
        //this.uploadLabel2 = event.files[0].name;
      }
      this.dataFormulir.filedata.keterangandokter = '';
    },
    beforeUploadTiga(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','keterangansehat');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgressTiga(event){
      this.isupload3 = true;
      this.uploadprogress3 = event.progress;
    },
    onUploadTiga(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.filedata.keterangansehat = obj.data.fileid;
        this.classupload3='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload3='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload3 = false;
    },
    onSelectTiga(event){
      if(event.files.length >0){
        //this.uploadLabel3 = event.files[0].name;
      }
      this.dataFormulir.filedata.keterangansehat = '';
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.idanak};
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              if (typeof this.response.data.fileimunisasi !== 'undefined') {
                this.files.fileimunisasi.list = this.response.data.fileimunisasi.list;
                this.files.fileimunisasi.size = this.response.data.fileimunisasi.size;
                if(this.files.fileimunisasi.size>0){
                  if(typeof this.dataFormulir.filedata !== 'undefined'){
                    if(typeof this.dataFormulir.filedata.imunisasi !== 'undefined'){
                      this.dataFormulir.filedata.imunisasi = 'uploaded';
                    }
                  }
                }
              }
              if (typeof this.response.data.keterangandokter != "undefined") {
                this.files.keterangandokter.list = this.response.data.keterangandokter.list;
                this.files.keterangandokter.size = this.response.data.keterangandokter.size;
                if(this.files.keterangandokter.size>0){
                  if(typeof this.dataFormulir.filedata !== 'undefined'){
                    if(typeof this.dataFormulir.filedata.keterangandokter !== 'undefined'){
                      this.dataFormulir.filedata.keterangandokter = 'uploaded';
                    }
                  }
                }
              }
              if (typeof this.response.data.keterangansehat != "undefined") {
                this.files.keterangansehat.list = this.response.data.keterangansehat.list;
                this.files.keterangansehat.size = this.response.data.keterangansehat.size;
                if(this.files.keterangansehat.size>0){
                  if(typeof this.dataFormulir.filedata !== 'undefined'){
                    if(typeof this.dataFormulir.filedata.keterangansehat !== 'undefined'){
                      this.dataFormulir.filedata.keterangansehat = 'uploaded';
                    }
                  }
                }
              }
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    this.getDataUploadedFile();
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
    this.validate();
  },
  validations() {
    return {
      dataFormulir:{
        kelahiran:{required},
        filedata: {
          imunisasi:{required},
          keterangandokter: {required},
          keterangansehat: {required}
        }
      }
    }
  }
}
</script>

<style scoped>

</style>