<template>
  <BreadCrumb page="Beranda > Pendaftaran > Isi formulir Berasa"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Formulir Berasa</h3>
        <hr>
        <div class="row">
          <div class="col-12">
            <div class="notes-notification-sga">
              <p><strong>Catatan</strong></p>
              <ul>
                <li>
                  Form Berasa <strong>berlaku 5 hari</strong> sejak dikeluarkannya undangan untuk
                  mengisi formulir, yakni pada hari {{mulai}}.
                </li>
                <li>
                  Waktu pengisian formulir adalah <strong>2 jam </strong>sejak tombol dimulai ditekan
                </li>
                <li>Formulir akan otomatis terkirim setelah batas waktu terlewati</li>
                <li>Sebelum mulai mengisi form Berasa, <strong>harap baca dengan saksama terlebih
                  dahulu</strong> bagian <strong>instruksi</strong> dan <strong>contoh</strong>
                  yang tersedia.
                </li>
                <li>Tautan untuk mulai mengisi form Berasa akan dikirimkan melalui <em>email</em>.</li>
              </ul>
            </div>
          </div>
        </div>
        <LoadSpinner v-if="loading"></LoadSpinner>
        <TabelDataAnakBerasa v-else
                             :data="dataPendaftaran"
                             buttontitle="Isi Berasa"
                             routepath="/gemala/psb/isi-formulir-berasa/"
        ></TabelDataAnakBerasa>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import TabelDataAnakBerasa from "@/components/views/berasa/part/TabelDataAnakBerasa";
import LoadSpinner from "@/components/LoadSpinner";
export default {
  name: "PageFormulirBerasa",
  components:{LoadSpinner, TabelDataAnakBerasa, BreadCrumb},
  data:()=>({
    response:'',
    message:'',
    success: false,
    loading: false,
    mulai:"",
    dataPendaftaran:[],
  }),
  methods:{
    getDataSiapBerasa(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/getDataSiapBerasa';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data;
              this.mulai = this.dataPendaftaran[0].mulai;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataSiapBerasa()
  }
}
</script>

<style scoped>

</style>