<template>
  <div class="form-general">
    <div class="group-sga">
      <div class="row">
        <div class="col-12"><h4>Topik 1</h4></div>
      </div>
      <div class="row">
        <div class="col-12">
          <normal-label-nilai>Nilai apakah yang ditanamkan orang tua Anda kepada Anda, yang
            sekarang Anda terapkan dalam kehidupan sehari-hari?
          </normal-label-nilai>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Nilai</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikSatu.jawaban1" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Mengapa nilai tersebut Anda anggap penting untuk diterapkan dalam kehidupan Anda?</label></div>
        <div class="col-md-8 spacer-field-sga">
          <textarea :value="databerasa.topikSatu.jawaban2" class="form-control textarea-sga" rows="4" required></textarea>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Ceritakan satu peristiwa spesifik dalam
          kurun waktu satu tahun terakhir, di mana Anda menerapkan nilai tersebut dalam
          kehidupan sehari-hari.</label></div>
        <div class="col-md-8 spacer-field-sga">
          <textarea :value="databerasa.topikSatu.jawaban3" class="form-control textarea-sga" rows="4" required></textarea>
        </div>
      </div>
    </div>
    <hr>
    <div class="group-sga">
      <div class="row">
        <div class="col-12"><h4>Topik 2</h4></div>
      </div>
      <div class="row">
        <div class="col-12">
          <normal-label-nilai>Apakah nilai lain yang menurut Anda pribadi juga penting dalam
            mendidik anak?
          </normal-label-nilai>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Nilai</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikDua.jawaban1" class="w-100 form-control" required></div>
        <div class="col-md-4 spacer-field-sga"><label>Mengapa nilai tersebut Anda anggap penting
          untuk diterapkan dalam kehidupan Anda?</label></div>
        <div class="col-md-8 spacer-field-sga">
          <textarea :value="databerasa.topikDua.jawaban2" class="form-control textarea-sga" rows="4" required></textarea>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Ceritakan satu peristiwa spesifik dalam
          kurun waktu satu tahun terakhir, di mana Anda menerapkan nilai tersebut dalam
          kehidupan sehari-hari.</label></div>
        <div class="col-md-8 spacer-field-sga">
          <textarea :value="databerasa.topikDua.jawaban3" class="form-control textarea-sga" rows="4" required></textarea>
        </div>
      </div>
    </div>
    <hr>
    <div class="group-sga">
      <div class="row">
        <div class="col-12"><h4>Topik 3</h4></div>
      </div>
      <div class="row">
        <div class="col-12">
          <normal-label-nilai> Dalam kurun waktu satu tahun ini, adakah peristiwa spesifik di
            mana Anda mengalah kepada orang lain atau kesabaran Anda diuji? Apa
            peristiwanya, apa yang Anda lakukan, dan bagaimana hasilnya? Ceritakanlah dengan
            menggunakan format di bawah ini.
          </normal-label-nilai>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Judul peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikTiga.jawaban1" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Waktu dan durasi peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikTiga.jawaban2" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Latar belakang peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga">
          <textarea :value="databerasa.topikTiga.jawaban3" class="form-control textarea-sga" rows="4" required></textarea>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Orang-orang yang terlibat</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikTiga.jawaban4" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Hasil akhir dari peristiwa ini</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikTiga.jawaban5" class="w-100 form-control" required>
        </div>
        <div class="col-12 spacer-field-sga"></div>
        <div class="col-md-4 spacer-field-sga"><label>Secara berurutan, langkah-langkah penting apa yang Anda lakukan untuk mencapainya</label>
          <p style="margin-top: 15px;"><strong>Catatan:</strong></p>
          <ul>
            <li>Pastikan Anda menggunakan kata “saya” ketika Anda sendirilah yang melakukan
              hal tersebut
            </li>
            <li> minimal 350 kata</li>
            <li> maksimal 500 kata</li>
          </ul>
        </div>
        <div class="col-md-8 spacer-field-sga">
          <textarea :value="databerasa.topikTiga.jawaban6" class="form-control textarea-sga" rows="9" required></textarea>
          <p class="text">{{wordsLen(dataBerasa.topikTiga.jawaban6)}}/500</p>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Apa perasaan tentang hasilnya? Mengapa?</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikTiga.jawaban7" class="w-100 form-control" required></div>
        <div class="col-md-4 spacer-field-sga"><label>Pelajaran/hikmah apa yang Anda petik dari peristiwa ini?</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikTiga.jawaban8" class="w-100 form-control" required>
        </div>
      </div>
    </div>
    <hr>
    <div class="group-sga">
      <div class="row">
        <div class="col-12"><h4>Topik 4</h4></div>
      </div>
      <div class="row">
        <div class="col-12">
          <normal-label-nilai>Dalam kurun waktu satu tahun terakhir ini, pernahkah Anda berada
            dalam situasi di mana anak Anda menunjukkan masalah/perilaku yang Anda anggap
            berat? Apa peristiwanya, apa yang Anda lakukan, dan bagaimana hasilnya?
            Ceritakanlah dengan menggunakan format di bawah ini.
          </normal-label-nilai>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Judul peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikEmpat.jawaban1" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Waktu dan durasi peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikEmpat.jawaban2" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Latar belakang peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga">
          <textarea :value="databerasa.topikEmpat.jawaban3" class="form-control textarea-sga" rows="4" required></textarea>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Orang-orang yang terlibat</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikEmpat.jawaban4" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Hasil akhir dari peristiwa ini</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikEmpat.jawaban5" class="w-100 form-control" required>
        </div>
        <div class="col-12 spacer-field-sga"></div>
        <div class="col-md-4 spacer-field-sga">
          <label>Secara berurutan, langkah-langkah penting apa yang Anda lakukan untuk
            mencapainya</label>
          <p style="margin-top: 15px;"><strong>Catatan:</strong></p>
          <ul>
            <li>Pastikan Anda menggunakan kata “saya” ketika Anda sendirilah yang melakukan
              hal tersebut
            </li>
            <li> minimal 350 kata</li>
            <li> maksimal 500 kata</li>
          </ul>
        </div>
        <div class="col-md-8 spacer-field-sga">
          <textarea :value="databerasa.topikEmpat.jawaban6" class="form-control textarea-sga" rows="9" required></textarea>
          <p class="text">{{wordsLen(dataBerasa.topikEmpat.jawaban6)}}/500</p>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Apa perasaan tentang hasilnya? Mengapa?</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikEmpat.jawaban7" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Pelajaran/hikmah apa yang Anda petik dari peristiwa ini?</label></div>
        <div class="col-md-8 spacer-field-sga">
          <input type="text" :value="databerasa.topikEmpat.jawaban8" class="w-100 form-control" required>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  name: "ViewHasilBerasa",
  props:{
    databerasa:undefined
  },
  data:()=>({
    dataBerasa:{
      tokenlink:'',
      topikSatu:{
        jawaban1:'',
        jawaban2:'',
        jawaban3:'',
      },
      topikDua:{
        jawaban1:'',
        jawaban2:'',
        jawaban3:'',
      },
      topikTiga:{
        jawaban1:'',
        jawaban2:'',
        jawaban3:'',
        jawaban4:'',
        jawaban5:'',
        jawaban6:'',
        jawaban7:'',
        jawaban8:'',
      },
      topikEmpat:{
        jawaban1:'',
        jawaban2:'',
        jawaban3:'',
        jawaban4:'',
        jawaban5:'',
        jawaban6:'',
        jawaban7:'',
        jawaban8:'',
      }
    }
  }),
  methods:{
    wordsLen(str) {
      const array = str.trim().split(/\s+/);
      return array.length;
    },
  },
  mounted() {
    //this.dataBerasa = this.databerasa;
  }
}
</script>

<style scoped>

</style>