<template>
  <HeaderParent></HeaderParent>
  <PageTataCaraDaftar v-if="page==='tatacara'" :page="page"></PageTataCaraDaftar>
  <PageFormulirPembelian v-else-if="page==='pengisian'"></PageFormulirPembelian>
  <PagePengambilanFormulir v-else></PagePengambilanFormulir>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderParent from "@/components/HeaderParent";
import FooterWeb from "@/components/FooterWeb";
import PagePengambilanFormulir from "@/components/views/formulir/PagePengambilanFormulir";
import PageTataCaraDaftar from "@/components/views/formulir/PageTataCaraDaftar";
import PageFormulirPembelian from "@/components/views/formulir/PageFormulirPembelian";
import router from "@/router";
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "routerFormulir",
  components: {
    PageFormulirPembelian,
    PageTataCaraDaftar, PagePengambilanFormulir, FooterWeb, HeaderParent},
  computed:{

  },
  data:()=>({
    page:'',
    dataPendaftaran:[],
    notFormulirPage : ['tatacara','pengisian'],
    pageAllow : ['psb','psb-pindahan','psb-khusus','psb-pindahan-khusus']
  }),
  methods:{
    getDataFormulirPendaftaran(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/getDataFormulirPendaftaran';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data;
              this.isHaveNoFormulir = this.dataPendaftaran.length > 0;
              if(this.isHaveNoFormulir){
                this.page='pengisian';
              }
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    this.page = this.$route.params.page.toLowerCase();
    this.$store.commit('tipepsb',this.page);
    if(this.pageAllow.includes(this.page)){
      localStorage.setItem('tipepsb',this.page);
    }else {
      if(!this.notFormulirPage.includes(this.page)){
        router.push('/gemala/pendaftaran');
      }
    }
    this.getDataFormulirPendaftaran();
  },
  watch:{
    '$route.params.page':{
      immediate: false,
      deep: false,
      handler(){
        this.page = this.$route.params.page.toLowerCase();
        if(!this.notFormulirPage.includes(this.page)){
          if(this.pageAllow.includes(this.page)){
            this.$store.commit('tipepsb',this.page);
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>