<template>
  <div class="row">
    <div class="col-12">
      <h3 class="judul-seksi-sga">Informasi Pendaftaran Penerimaan Siswa Baru (PSB) SD Gemala Ananda
        {{ dataInformasi.tahunajaran }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr>
    </div>
  </div>
  <div class="form-general">
    <div class="row">
      <div class="col-12 text-left" v-html="dataInformasi.artikel">
      </div>
    </div>
    <div class="info-pendaftaran-wrapper">
      <div class="row">
        <div class="col-md-4 text-center my-auto"><p><strong>Formulir yang sudah diambil pendaftar</strong>
        </p>
          <h2 class="status-jumlah-sga-h2">{{dataInformasi.formulirdiambil}}</h2></div>
        <div class="col-md-4 text-center my-auto"><p><strong>Berkas pendaftaran terisi lengkap yang sudah
          diterima Sekolah </strong></p>
          <h2 class="status-jumlah-sga-h2">{{dataInformasi.terisilengkap}}</h2></div>
        <div class="col-md-4 text-center my-auto"><p><strong>Kursi kelas yang masih tersedia</strong>
        </p>
          <h2 class="status-jumlah-sga-h2">{{dataInformasi.kursitersedia}}</h2></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArtikelInfoPendaftaran",
  props:{
    dataInformasi:undefined
  }
}
</script>

<style scoped>

</style>