<template>
  <div class="footer-area-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 text-white">&copy; Sistem Sekolah Gemala Ananda.<br>
          Dikembangkan oleh EvolutionTeams
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterWeb"
}
</script>

<style scoped>

</style>