<template>
  <PageRegister></PageRegister>
</template>

<script>
import PageRegister from "@/components/views/PageRegister";
export default {
  name: "routerRegister",
  components: {PageRegister}
}
</script>

<style scoped>

</style>