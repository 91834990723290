<template>
  <Accordion>
    <AccordionTab header="File yang sudah terunggah (uploaded)">
      <ConfirmDialog></ConfirmDialog>
      <div class="card">
        <ul>
          <li v-for="(item,idx) in products" :key="idx">
            <span @click="showmodal(item)" style="cursor: pointer">{{item.namafile}} </span> <i class="fa-solid fa-xmark" style="cursor: pointer" v-if="isAdmin" @click="confirmdelete(item,idx)"></i>
          </li>
        </ul>
      </div>
    </AccordionTab>
  </Accordion>
  <Dialog v-model:visible="visible" modal :header="currenUploadData.namafile" :style="{ width: '80%' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <img :src="currenUploadData.fileurl" v-if="currenUploadData.filetype!=='pdf'" class="img-fluid" alt=""/>
    <object v-else :data="currenUploadData.fileurl" type="application/pdf" width="100%" height="600px">
      <p>Unable to display PDF file. <a :href="currenUploadData.fileurl">Download</a> instead.</p>
    </object>
  </Dialog>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dialog from 'primevue/dialog';
import DashboardServices from "@/services/dashboard.services";
//import Carousel from 'primevue/carousel';
import ConfirmDialog from 'primevue/confirmdialog';
export default {
  name: "FileLister",
  props:{
    filedata:undefined,
    numVisible: Number,
    enabledelete: Boolean
  },
  components:{
    Accordion,
    AccordionTab,
    Dialog,
    ConfirmDialog,
    //Carousel
  },
  data:()=>({
    pdffile: require('@/assets/images/img_pdf.png'),
    products:[],
    visible:false,
    currenUploadData:'',
    isAdmin: false,
  }),
  methods:{
    confirmdelete(item,idx){
      this.$confirm.require({
        message: 'Anda yakin mau menghapus file?',
        header: 'Konfirmasi',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.deleteItem(item,idx)
          this.$toast.add({ severity: 'info', summary: 'Informasi', detail: 'File berhasil dihapus', life: 3000 });
        },
        reject: () => {
        }
      })
    },
    deleteItem(item,idx){
      let data = item;
      let path = '/dashboard/deleteimagedoc';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.products.splice(idx, 1);
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    showmodal(item){
      this.currenUploadData = item;
      this.visible = !this.visible;
    },
    prepareData(){
      //this.products = await this.filedata.datapribadi.list;
      //this.numVisible = await this.filedata.datapribadi.size;
      this.products =this.filedata;
    },
    checkuser(){
      let userData = localStorage.getItem('usergemala');
      let role = 0;
      let token = '';
      const objData = JSON.parse(userData);
      if(objData.token !== undefined){
        token = objData.token;
        if (token.length>0){
          role = objData.role;
          if(role===3){
            console.log("OKE");
            this.isAdmin = true;
          }
        }
      }
    }
  },
  beforeMount() {

  },
  mounted() {
    this.prepareData();
    this.checkuser();
  }
}
</script>

<style scoped>

</style>