<template>
  <FormTitle title="C. Informasi Keluarga"></FormTitle>
  <div class="row" >
    <div class="col-md-6">
      <PageFormDataOrangTua :required="true" :initdata="initdata.ayahkandung" :statushubugan="false" label="Nama lengkap Ayah Kandung" @change-data="updateAyahKandung"></PageFormDataOrangTua>
    </div>
    <div class="col-md-6">
      <PageFormDataOrangTua :required="true" :initdata="initdata.ibukandung" :statushubugan="false" label="Nama lengkap Ibu Kandung" @change-data="updateIbuKandung"></PageFormDataOrangTua>
    </div>
  </div>
  <hr class="hr-spacer">
  <div class="row">
    <div class="col-12">
      <normal-label><strong>Data Anak Dalam Keluarga. </strong></normal-label>
    </div>
    <div class="col-12 mb-1">
      <keterangan-form>Termasuk nama ananda yang akan di daftarkan</keterangan-form>
    </div>
    <div class="col-12 button-area-sga text-right mb-2" v-if="showupload">
      <a @click="remFormDataAnak" v-show="dataFormulir.anak.length>1" class="btn btn-md btn-danger mr-2 text-white">- <i class="fa-solid fa-child"></i> Anak</a>
      <a @click="addFormDataAnak" v-show="dataFormulir.anak.length<4" class="btn btn-md btn-warning">+ <i class="fa-solid fa-child"></i> Anak</a>
    </div>
  </div>
  <PageFormDataAnak v-for="(anak,idx) in dataFormulir.anak" :key="idx" :initdata="anak" :label=getLabel(anak,idx) @change-data="updateDataAnak($event,idx)"></PageFormDataAnak>
  <div class="row">
    <div class="col-md-3">
      <label>Anak tinggal bersama</label>
    </div>
    <div class="col-md-9">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="ya" v-model="dataFormulir.tinggalbersama.ayahkandung" >
        <span class="normal-label2 form-check-label" for="inlineCheckbox1">Ayah kandung</span>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="ya" v-model="dataFormulir.tinggalbersama.ibukandung" >
        <span class="normal-label2 form-check-label" for="inlineCheckbox2">Ibu kandung</span>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="ya" v-model="dataFormulir.tinggalbersama.ayahtiri" >
        <span class="normal-label2 form-check-label">Ayah tiri</span>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="ya" id="inlineCheckbox4" v-model="dataFormulir.tinggalbersama.ibutiri">
        <span class="normal-label2 form-check-label">Ibu tiri</span>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="ya" id="inlineCheckbox5" v-model="dataFormulir.tinggalbersama.ayahangkat">
        <span class="normal-label2 form-check-label">Ayah angkat</span>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="ya" id="inlineCheckbox6" v-model="dataFormulir.tinggalbersama.ibuangkat">
        <span class="normal-label2 form-check-label">Ibu angkat</span>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="ya" id="inlineCheckbox7" v-model="dataFormulir.tinggalbersama.wali">
        <span class="normal-label2 form-check-label">Wali</span>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/views/psb/FormTitle";
import PageFormDataOrangTua from "@/components/views/psb/part/PageFormDataOrangTua";
import PageFormDataAnak from "@/components/views/psb/part/PageFormDataAnak";

export default {
  name: "PageFormInformasiKeluarga",
  props:{
    data:[],
    initdata:undefined,
    showupload: Boolean
  },
  emits: ['change-data'],
  components: {PageFormDataAnak, PageFormDataOrangTua, FormTitle},
  data:()=>({
    loading: false,
    loader: true,
    message:'',
    success:false,
    dataFormulir:{
      ayahkandung:[],
      ibukandung:[],
      anak:[],
      tinggalbersama:{
        ayahkandung:'',
        ibukandung:'',
        ayahtiri:'',
        ibutiri:'',
        ayahangkat:'',
        ibuangkat:'',
        wali:''
      }
    }
  }),
  methods:{
    updateAyahKandung(value){
      this.dataFormulir.ayahkandung = this.initdata.ayahkandung;
      this.dataFormulir.ayahkandung = value;
    },
    updateIbuKandung(value){
      this.dataFormulir.ibukandung = this.initdata.ibukandung;
      this.dataFormulir.ibukandung = value;
    },
    updateDataAnak(value,index){
      this.dataFormulir.anak[index] = value;
    },
    addFormDataAnak(){
      let anak = {nama:'',jeniskelamin:'',ttl:'',sekolah:''};
      this.dataFormulir.anak.push(anak);
    },
    remFormDataAnak(){
      this.dataFormulir.anak.pop()
    },
    getLabel(anak,idx){
      idx++
      return "Anak ke-"+idx;
    },
  },
  mounted() {
    this.addFormDataAnak();
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.dataFormulir);
  },
}
</script>

<style scoped>

</style>