<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Keputusan > Lihat Data Keputusan > Ubah data"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Keputusan PSB diterima untuk {{data.nama_anak}} No Formulir {{data.no_formulir}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div class="notes-notification-sga">
      <p><strong>Catatan :</strong><br></p>
      <ul>
        <li>Mengirimkan SK sekaligus akan mengaktifkan tagihan pelunasan uang sekolah untuk tahun ajaran terkait bagi calon siswa yang tertera.</li>
      </ul>
      <p></p>
    </div>
    <div class="form-general">
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-12 spacer-field-sga"><normal-label>No formulir</normal-label> <br/><normal-label2>{{data.no_formulir}}</normal-label2> </div>
            <div class="col-12 spacer-field-sga"><normal-label>Nama Ananda </normal-label> <br/><normal-label2>{{data.nama_anak}}</normal-label2></div>
            <div class="col-12 spacer-field-sga"><normal-label>Nama Orang Tua </normal-label>
              <ul>
                <li>
                  <normal-label2>{{ data.nama_ayah }}</normal-label2>
                </li>
                <li>
                  <normal-label2>{{ data.nama_ibu }}</normal-label2>
                </li>
                <li v-if="data.nama_ayah_tiri">{{ data.nama_ayah_tiri }}</li>
                <li v-if="data.nama_ibu_tiri">{{ data.nama_ibu_tiri }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-striped ">
                  <thead>
                  <tr>
                    <th scope="col">Keterangan </th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th scope="row">SK Keputusan</th>
                    <td>{{data.datacatatan.idemail_keputusan_dikirim}}</td>
                    <td>
                      <LoadSpinner v-if="sedangkirimsuratkeputusan"></LoadSpinner>
                      <button v-else class="btn btn-sm btn-success" :disabled="disablekirimsk" @click="kirimsuratkeputusan">Kirim surat SK</button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Uang pelunasan</th>
                    <td>{{data.datacatatan.idemail_uang_pelunasan_dikirim}}</td>
                    <td>
                      <LoadSpinner v-if="sedangkirimsuratpelunasan"></LoadSpinner>
                      <button v-else class="btn btn-sm btn-success" :disabled="disablekirimpelunasan" @click="kirimsuratpelunasan">Kirim tagihan</button></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import LoadSpinner from "@/components/LoadSpinner";
export default {
  name: "PageViewPSBditerima",
  components: {LoadSpinner, BreadCrumb},
  props:{
    data: undefined
  },
  emits: ['change-view'],
  data:()=>({
    loading: false,
    sedangkirimsuratkeputusan: false,
    skterkirim: false,
    pelunasanterkirim: false,
    sedangkirimsuratpelunasan: false,
    response:'',
    message:'',
    success: false,
    dataKeputusan:[]
  }),
  computed:{
    disablekirimsk(){
      if(this.data.datacatatan.idemail_keputusan_dikirim==='Sudah dikirim'){
        return true;
      }else{
        return false;
      }
    },
    disablekirimpelunasan(){
      if(this.data.datacatatan.idemail_uang_pelunasan_dikirim==='Sudah dikirim'){
        return true;
      }else{
        return false;
      }
    }
  },
  methods:{
    toggleview() {
      this.$emit('change-view', true)
    },
    kirimsuratkeputusan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        'idanak': this.data.idanak,
        'invid': this.data.invid,
        'jenis': 'suratsk'
      };
      this.sedangkirimsuratkeputusan = true;
      let path = '/dashboard/kirimsuratkeputusan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataKeputusan = this.response.data;
              this.skterkirim = true;
            }else {
              this.success = false;
            }
            this.sedangkirimsuratkeputusan = false;
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    kirimsuratpelunasan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        'idanak': this.data.idanak,
        'invid': this.data.invid,
        'jenis': 'suratpelunasan'
      };
      this.sedangkirimsuratpelunasan = true;
      let path = '/dashboard/kirimsuratkeputusan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataKeputusan = this.response.data;
              this.pelunasanterkirim = true;
            }else {
              this.success = false;
            }
            this.sedangkirimsuratpelunasan = false;
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  }
}
</script>

<style scoped>

</style>