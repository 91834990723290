<template>
  <!--DATA PRIBADI-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>D. Informasi Orang Tua Tiri/Angkat atau Wali (bila ada)
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <PageFormDataOrangTua :required="false" :initdata="initdata.ayahtiri" :statushubugan="true" label="Nama lengkap Ayah" @change-data="updateAyahTiri"></PageFormDataOrangTua>
    </div>
    <div class="col-md-6">
      <PageFormDataOrangTua :required="false" :initdata="initdata.ibutiri" :statushubugan="true" label="Nama lengkap Ibu" @change-data="updateIbuTiri"></PageFormDataOrangTua>
    </div>
  </div>
</template>

<script>
import PageFormDataOrangTua from "@/components/views/psb/part/PageFormDataOrangTua";
export default {
  name: "PageFormInformasiOrangTuaTiri",
  props:{
    initdata:undefined
  },
  components: {PageFormDataOrangTua},
  emits: ['change-data'],
  data:()=>({
    loading: false,
    message:'',
    success:false,
    dataFormulir:{
      ayahtiri:[],
      ibutiri:[],
    }
  }),
  methods: {
    updateAyahTiri(value) {
      this.dataFormulir.ayahtiri = value;
    },
    updateIbuTiri(value) {
      this.dataFormulir.ibutiri = value;
    },
  },
  mounted() {
    this.$emit('change-data',this.dataFormulir);
    this.dataFormulir = this.initdata;
  }
}
</script>

<style scoped>

</style>