<template>
  <HeaderParent></HeaderParent>
  <ParentIndex></ParentIndex>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderParent from "@/components/HeaderParent";
import ParentIndex from "@/components/ParentIndex";
import FooterWeb from "@/components/FooterWeb";
export default {
  name: "routeHome",
  components: {FooterWeb, ParentIndex, HeaderParent}
}
</script>

<style scoped>

</style>