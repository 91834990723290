<template>
  <div class="wrapper-login-daftar-sga">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <div class="logo-login">
            <img :src="logo" class="img-fluid"></div>
          <h2>Setel ulang Akun</h2>
          <p>Harap masukkan data data dengan lengkap dan sesuai untuk akun Anda. Selanjutnya kami akan mengirimkan
            surel untuk melakukan validasi alamat surel Anda.</p>
          <div class="form-login-daftar">
            <form class="needs-validation" novalidate>
              <div class="row">
                <div class="col-md-6 text-left spacer-field-sga">Alamat surel</div>
                <div class="col-md-6 spacer-field-sga text-left"><input v-model="accountData.email" type="text"
                                                                        class="w-100 form-control" :disabled="!loading ? disabled : ''" required>
                  <span class="keterangan-form text-danger" v-if="v$.accountData.email.$error">Mohon isi email anda</span>
                </div>
                <div class="col-md-6 text-left spacer-field-sga">Ulangi alamat surel</div>
                <div class="col-md-6  spacer-field-sga text-left"><input v-model="accountData.email2" type="text"
                                                                         class="w-100 form-control" :disabled="!loading ? disabled : ''"
                                                                         required>
                  <span class="keterangan-form text-danger" v-if="v$.accountData.email2.$error">Mohon isi dan cek kembali email anda</span>
                </div>
                <div class="col-md-12 text-right spacer-field-sga">
                  <AlertSuccess v-if="success" :message="message"></AlertSuccess>
                  <AlertDanger v-else :message="message"></AlertDanger>
                  <ButtonLoading v-if="loading"></ButtonLoading>
                  <button v-else class="btn btn-md btn-success" type="button" @click="doSubmitResetPassword"><i
                      class="fa-solid fa-caret-right"></i> Setel ulang
                  </button>
                </div>
                <div class="col-md-12 text-left spacer-field-sga" v-if="!loading">
                  <span>Kembali ke <router-link to="/login">layar login</router-link></span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";
import ButtonLoading from "@/components/ButtonLoading";
import DashboardServices from "@/services/dashboard.services";
import useValidate from '@vuelidate/core'
import {sameAs, email, minLength, required} from '@vuelidate/validators'

export default {
  name: "PageResetPassword",
  components: {AlertSuccess, AlertDanger,ButtonLoading},
  methods:{
    doClearForm(){
      this.accountData.email = '';
      this.accountData.email2 = '';
    },
    doSubmitResetPassword(){
      this.v$.accountData.$validate();
      if(!this.v$.accountData.$error){
        this.loading = true;
        let data = this.accountData;
        let path = '/api/request-reset-password';
        DashboardServices.postData(data,path).then(
            (response)=>{
              this.response = response.data;
              this.message = response.data.message;
              if(response.data.status===200){
                this.success = true;
                this.doClearForm();
              }else {
                this.success = false;
              }
              this.loading = false;
            }
        );
      }else{
        this.loading = false;
        this.message = 'Mohon isi data dengan benar.';
      }
    },
  },
  data:()=>({
    v$: useValidate(),
    loading: false,
    logo: require('@/assets/images/logo-sga.png'),
    imgLoading: require('@/assets/images/loading.gif'),
    response:'',
    message: '',
    success: false,
    accountData:{
      email:'',
      email2:'',
    }
  }),
  validations() {
    return {
      accountData: {
        email:{required,email,minLength: minLength(3)},
        email2:{
          required,
          sameAsPassword: sameAs(this.accountData.email)
        },
      }
    }
  },
  beforeCreate: function() {
    document.body.style="";
    document.body.className = 'home';
  }
}
</script>

<style scoped>
body.home {
  padding-top: 0px!important;
}
</style>