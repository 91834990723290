<template>
  <Toast></Toast>
  <BreadCrumb page="Beranda > Pendaftaran > Isi Formulir"></BreadCrumb>
  <div class="container content-area" v-if="!dataSavedStatus">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Pengisian Formulir Penerimaan Siswa Baru (PSB) Sekolah Gemala Ananda
        </h3>
        <hr>
        <div class="row">
          <div class="col-12">
            <div class="notes-notification-sga2">
              <div class="row">
                <div class="col-md-3 my-auto">	No formulir:<br><strong>{{dataAnak.inv_child}}</strong></div>
                <div class="col-md-4 my-auto">	Nama Ananda:<br><strong>{{dataAnak.anak_nama}}</strong></div>
                <div class="col-md-3 my-auto">Jenis pendaftaran:<br><strong>{{dataAnak.anak_jenisdaftar}}</strong></div>
              </div>
            </div>
          </div>
        </div>
        <ProgressBar v-if="loaderformulir" mode="indeterminate" class="mb-3" style="height: 6px"></ProgressBar>
        <form v-else class="needs-validation" novalidate>
          <div class="form-general">
            <div class="row">
              <div class="col-12 text-left">
                <ul id="clothingnav1" class="nav nav-tabs justify-content-center" role="tablist">
                  <li class="nav-item"><a class="nav-link" href="#panel1" id="hometab1"
                                          role="tab" data-toggle="tab" aria-controls="home"
                                          aria-expanded="true" :class="getClass(1)" @click="setStep(1)"><span :class="formerror.satu?'text-danger':''">1</span></a></li>
                  <li class="nav-item"><a class="nav-link" href="#panel2" role="tab"
                                          data-toggle="tab" aria-controls="hats" :class="getClass(2)"
                                          @click="setStep(2)"><span :class="formerror.dua?'text-danger':''">2</span></a></li>
                  <li class="nav-item"><a class="nav-link" href="#panel3" role="tab"
                                          data-toggle="tab" aria-controls="hats" :class="getClass(3)"
                                          @click="setStep(3)"><span :class="formerror.tiga?'text-danger':''">3</span></a></li>
                  <li class="nav-item"><a class="nav-link" href="#panel4" role="tab"
                                          data-toggle="tab" aria-controls="hats" :class="getClass(4)"
                                          @click="setStep(4)"><span :class="formerror.empat?'text-danger':''">4</span></a></li>
                  <li class="nav-item"><a class="nav-link" href="#panel5" role="tab"
                                          data-toggle="tab" aria-controls="hats" :class="getClass(5)"
                                          @click="setStep(5)"><span :class="formerror.lima?'text-danger':''">5</span></a></li>
                  <li class="nav-item"><a class="nav-link" href="#panel6" role="tab"
                                          data-toggle="tab" aria-controls="hats" :class="getClass(6)"
                                          @click="setStep(6)"><span :class="formerror.enam?'text-danger':''">6</span></a></li>
                  <li class="nav-item"><a class="nav-link" href="#panel7" role="tab"
                                          data-toggle="tab" aria-controls="hats" :class="getClass(7)"
                                          @click="setStep(7)">7</a></li>
                  <li v-if="isOpenNoSeven" class="nav-item"><a class="nav-link" href="#panel8" role="tab"
                                                               data-toggle="tab" aria-controls="hats" :class="getClass(8)"
                                                               @click="setStep(8)">8</a></li>
                </ul>
                <!-- Content Panel -->
                <div id="clothingnavcontent1" class="tab-content tab-content-sga-form">
                  <div role="tabpanel" class="tab-pane fade show" :class="getClass(1)"
                       aria-labelledby="hometab1">
                    <div class="tabs-form-panel-title-sga"> Formulir Pendaftaran</div>
                      <!--Data Pribadi-->
                    <PageFormDataPribadi
                        :initdata="dataFormulir.dataPribadi"
                        :idanak="invid" @change-data="updateDataPribadi"
                        :jenis-kelas="jenisKelas"
                        :data-files="dataFiles"
                        :showupload="true"
                    ></PageFormDataPribadi>
                      <!--Latar Belakang Pendidikan-->
                    <PageFormLatarBelakangPendidikan :initdata="dataFormulir.dataLatarBelakangPendidikan" @change-data="updateDataLatarBelakang"></PageFormLatarBelakangPendidikan>
                      <!--Informasi Keluarga-->
                    <PageFormInformasiKeluarga :showupload="true" :initdata="dataFormulir.dataInformasiKeluarga" @change-data="updateDataInformasiKeluarga"></PageFormInformasiKeluarga>
                      <!--Informasi Orang Tua Tiri/Angkat atau Wali-->
                    <PageFormInformasiOrangTuaTiri :initdata="dataFormulir.dataInformasiOrangTuaTiri" @change-data="updateDataInformasiOrangTuaTiri"></PageFormInformasiOrangTuaTiri>
                      <!--Kontak darurat (bila orang tua tidak dapat dihubungi)-->
                    <PageFormKontakDarurat :initdata="dataFormulir.dataKontakDarurat" @change-data="updateDataKontakDarurat"></PageFormKontakDarurat>
                      <!--Kebutuhan/Kondisi Khusus-->
                    <PageFormKondisiKhusus
                        :initdata="dataFormulir.dataKondisiKhusus"
                        :datapribadi="dataFormulir.dataPribadi"
                        @change-data="updateDataKondisiKhusus"></PageFormKondisiKhusus>
                      <!--Referensi (bila ada)-->
                    <PageFormReferensi :initdata="dataFormulir.dataReferensi" @change-data="updateDataReferensi"></PageFormReferensi>
                      <!--Pernyataan Orang Tua-->
                    <PageFormPernyataanOrangTua :showupload="true" :idanak="invid" :initdata="dataFormulir.dataPernyataanOrangTua" @change-data="updateDataPernyataan"></PageFormPernyataanOrangTua>
                  </div>
                  <div role="tabpanel" class="tab-pane fade show" :class="getClass(2)" aria-labelledby="hatstab1">
                    <div class="tabs-form-panel-title-sga">Catatan Pribadi Anak</div>
                    <PageFormKarakteristikUmum :initdata="dataFormulir.dataCatatanPribadiAnak" @change-data="updateDataCatatanPribadi"></PageFormKarakteristikUmum>
                  </div>
                  <div role="tabpanel" class="tab-pane fade show" :class="getClass(3)" aria-labelledby="hatstab1">
                    <div class="tabs-form-panel-title-sga">Formulir Kesehatan</div>
                    <div class="row" style="margin-top:20px; margin-bottom:-35px;">
                      <div class="col-md-10" >
                        <strong>Langkah pengisian Form Kesehatan </strong>
                        <ol>
                          <li>Orang tua mengisi Formulir Kesehatan. </li>
                          <li>Cetak form kesehatan tersebut. </li>
                          <li>Unduh dan cetak surat pengantar untuk dokter, dan tuliskan nama dokter yang biasa menangani ananda. </li>
                          <li>Silakan konsultasi ke dokter tsb., dengan membawa form dan surat pengantar tersebut. Minta dokter untuk:
                            <ul>
                              <li> memverifikasi data kesehatan dan membubuhkan paraf pada setiap halaman, </li>
                              <li> melakukan pemeriksaan kesehatan ananda, </li>
                              <li>mengisi formulir bagian Verifikasi Dokter. </li>
                            </ul>
                          </li>
                          <li>Masuk kembali ke Sistem PSB SGA
                            <ul>
                              <li>Sesuaikan isi form dengan penilaian dan rekomendasi yang dituliskan dokter dalam form cetak. </li>
                              <li>Tuliskan nama lengkap dokter dan alamat praktiknya. </li>
                              <li>Pindai (scan) keseluruhan dokumen dan unggah ke sistem. </li>
                              <li>Jangan lupa klik “simpan”. </li>
                            </ul>
                          </li>
                        </ol>
                      </div>
                      <div class="col-md-2">
                        <button onclick="window.print()" class="btn btn-sm btn-success">Cetak form kesehatan</button>
                      </div>
                    </div>
                    <PageFormKesehatan :showupload="true" :idanak="invid" :initdata="dataFormulir.dataFormulirKesehatan" @change-data="updateDataKesehatan"></PageFormKesehatan>
                  </div>
                  <div role="tabpanel" class="tab-pane fade show" :class="getClass(4)" aria-labelledby="hatstab1">
                    <div class="tabs-form-panel-title-sga">Surat Pernyataan Orang Tua</div>
                    <PageSuratPernyataanOrangTua :showupload="true" :idanak="invid" :initdata="dataFormulir.dataSuratPernyataanOrtu" @change-data="updateDataSuratPernyataan"></PageSuratPernyataanOrangTua>
                  </div>
                  <div role="tabpanel" class="tab-pane fade show" :class="getClass(5)" aria-labelledby="hatstab1">
                    <div class="tabs-form-panel-title-sga">Persetujuan Ketentuan Umum</div>
                    <PageFormKetentuanUmum :showupload="true" :idanak="invid" :initdata="dataFormulir.dataPersetujuanKetentuanUmum" @change-data="updateDataKetentuanUmum"></PageFormKetentuanUmum>
                  </div>
                  <div role="tabpanel" class="tab-pane fade show" :class="getClass(6)" aria-labelledby="hatstab1">
                    <div class="tabs-form-panel-title-sga">Dokumen Pendukung</div>
                    <PageFormDokumenPendukung :showupload="true" :idanak="invid" :initdata="dataFormulir.dataDokumenPendukung" @change-data="updateDataDokumenPendukung"></PageFormDokumenPendukung>
                  </div>
                  <div role="tabpanel" class="tab-pane fade show" :class="getClass(7)" aria-labelledby="hatstab1">
                    <div class="tabs-form-panel-title-sga">Formulir Kendaraan</div>
                    <PageFormulirKendaraan :idanak="invid" :initdata="dataFormulir.dataInformasiKendaraan" @change-data="updateDataKendaraan"></PageFormulirKendaraan>
                  </div>
                  <div v-if="isOpenNoSeven" role="tabpanel" class="tab-pane fade show" :class="getClass(8)" aria-labelledby="hatstab1">
                    <div class="tabs-form-panel-title-sga">Khusus Penambahan Data</div>
                    <PageFormDokumenTambahan :showupload="true" :idanak="invid" :initdata="dataFormulir.dataKhusuPenambahan" @change-data="updateDataPenambahan"></PageFormDokumenTambahan>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row" v-if="!dataSavedStatus">
              <span v-if="errorValidasi.length>0">
                Mohon isi data berikut:
                <ul>
                  <li v-for="(item,idx) in errorValidasi" :key="idx">{{item.$propertyPath}}</li>
                </ul>
              </span>
              <div class="col-12" v-if="messagetrx">
                <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
                <AlertDanger v-else :message="messagetrx"></AlertDanger>
              </div>
              <div class="col-2 text-left">
                <button type="button" @click="doSimpanIsiFormulir" class="btn btn-md btn-warning"><i class="fa-solid fa-caret-right"></i>
                  Simpan
                </button>
              </div>
              <div class="col-8 text-center">
                <a href="#" class="btn btn-md btn-info"><i class="fa-solid fa-arrow-up"></i></a>
              </div>
              <div class="col-2 text-right">
                <ConfirmDialog></ConfirmDialog>
                <button class="btn btn-md btn-success" type="button" @click="konfirmasi"><i
                    class="fa-solid fa-caret-right"></i> Kirim
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import PageFormDataPribadi from "@/components/views/psb/PageFormDataPribadi";
import PageFormLatarBelakangPendidikan from "@/components/views/psb/PageFormLatarBelakangPendidikan";
import PageFormInformasiKeluarga from "@/components/views/psb/PageFormInformasiKeluarga";
import PageFormKontakDarurat from "@/components/views/psb/PageFormKontakDarurat";
import PageFormKondisiKhusus from "@/components/views/psb/PageFormKondisiKhusus";
import PageFormReferensi from "@/components/views/psb/PageFormReferensi";
import PageFormPernyataanOrangTua from "@/components/views/psb/PageFormPernyataanOrangTua";
import PageFormInformasiOrangTuaTiri from "@/components/views/psb/PageFormInformasiOrangTuaTiri";
import PageFormKarakteristikUmum from "@/components/views/psb/dua/PageFormKarakteristikUmum";
import PageFormKesehatan from "@/components/views/psb/tiga/PageFormKesehatan";
import PageFormKetentuanUmum from "@/components/views/psb/lima/PageFormKetentuanUmum";
import PageFormDokumenPendukung from "@/components/views/psb/enam/PageFormDokumenPendukung";
import PageFormDokumenTambahan from "@/components/views/psb/tujuh/PageFormDokumenTambahan";
import DashboardServices from "@/services/dashboard.services";
import PageSuratPernyataanOrangTua from "@/components/views/psb/empat/PageFormPernyataanOrangTua";
import useValidate from "@vuelidate/core";
import {required,minLength,email}  from "@vuelidate/validators";
import router from "@/router";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";
import ProgressBar from 'primevue/progressbar';
import Toast from "primevue/toast";
import ConfirmDialog from 'primevue/confirmdialog';
import PageFormulirKendaraan from "@/components/views/psb/delapan/PageFormulirKendaraan";
export default {
  name: "PageFormFormulirPsb",
  components: {
    PageFormulirKendaraan,
    Toast,
    PageFormInformasiOrangTuaTiri,
    AlertDanger,
    AlertSuccess,
    PageSuratPernyataanOrangTua,
    PageFormDokumenTambahan,
    PageFormDokumenPendukung,
    PageFormKetentuanUmum,
    PageFormKesehatan,
    PageFormKarakteristikUmum,
    PageFormPernyataanOrangTua,
    PageFormReferensi,
    PageFormKondisiKhusus,
    ProgressBar,
    ConfirmDialog,
    PageFormKontakDarurat,
    PageFormInformasiKeluarga, PageFormLatarBelakangPendidikan, PageFormDataPribadi, BreadCrumb},
  data: () => ({
    v$: useValidate(),
    errorValidasi:[],
    step: 1,
    invid: '',
    success: false,
    successtrx: false,
    message: '',
    messagetrx: '',
    loading: false,
    isOpenNoSeven: false,
    jenisKelas:[],
    loaderformulir: false,
    error: false,
    dataAnak:[],
    dataFiles:[],
    dataSavedFormulir:[],
    dataSavedStatus: false,
    dataIsiFormulir:[],
    formerror:{
      satu:false,
      dua:false,
      tiga:false,
      empat:false,
      lima:false,
      enam:false
    },
    tmpFormulir:[],
    dataFormulir:{
      dataPribadi:[],
      dataLatarBelakangPendidikan:[],
      dataInformasiKeluarga:[],
      dataInformasiOrangTuaTiri:[],
      dataKontakDarurat:[],
      dataKondisiKhusus:[],
      dataReferensi:[],
      dataPernyataanOrangTua:[],
      dataCatatanPribadiAnak:[],
      dataFormulirKesehatan:[],
      dataSuratPernyataanOrtu:[],
      dataPersetujuanKetentuanUmum:[],
      dataDokumenPendukung:[],
      dataKhusuPenambahan:[],
      dataInformasiKendaraan:[]
    }
  }),
  methods: {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    getClass(id) {
      return this.step === id ? 'active' : '';
    },
    setStep(step) {
      this.step = step;
    },
    updateDataPribadi(value){
      this.dataFormulir.dataPribadi = value;
    },
    updateDataLatarBelakang(value){
      this.dataFormulir.dataLatarBelakangPendidikan = value;
    },
    updateDataInformasiKeluarga(value){
      this.dataFormulir.dataInformasiKeluarga = value;
    },
    updateDataInformasiOrangTuaTiri(value){
      this.dataFormulir.dataInformasiOrangTuaTiri = value;
    },
    updateDataKontakDarurat(value){
      this.dataFormulir.dataKontakDarurat = value;
    },
    updateDataKondisiKhusus(value){
      this.dataFormulir.dataKondisiKhusus = value;
    },
    updateDataReferensi(value){
      this.dataFormulir.dataReferensi = value;
    },
    updateDataPernyataan(value){
      this.dataFormulir.dataPernyataanOrangTua = value;
    },
    updateDataCatatanPribadi(value){
      this.dataFormulir.dataCatatanPribadiAnak = value;
    },
    updateDataKesehatan(value){
      this.dataFormulir.dataFormulirKesehatan = value;
    },
    updateDataSuratPernyataan(value){
      this.dataFormulir.dataSuratPernyataanOrtu = value;
    },
    updateDataKetentuanUmum(value){
      this.dataFormulir.dataPersetujuanKetentuanUmum = value;
    },
    updateDataDokumenPendukung(value){
      this.dataFormulir.dataDokumenPendukung = value;
    },
    updateDataPenambahan(value){
      this.dataFormulir.dataKhusuPenambahan = value;
    },
    updateDataKendaraan(value){
      this.dataFormulir.dataInformasiKendaraan = value;
    },
    onFormError(data){
      switch (data.id){
        case 1:
          this.formerror.satu = data.value;
          break;
        case 2:
          this.formerror.dua = data.value;
          break;
        case 3:
          this.formerror.tiga = data.value;
          break;
        case 4:
          this.formerror.empat = data.value;
          break;
        case 5:
          this.formerror.lima = data.value;
          break;
        case 6:
          this.formerror.enam = data.value;
          break;
      }
    },
    timerSimpan(){
      //setInterval(this.simpanotomatis, 30000);
    },
    async simpanotomatis(){
      if(!this.loading){
        if(this.tmpFormulir != this.dataFormulir){
          this.doSimpanIsiFormulir();
          this.tmpFormulir = structuredClone(this.dataFormulir);
        }
      }
    },
    async konfirmasi(){
      const result = await this.v$.dataFormulir.$validate();
      this.errorValidasi = this.v$.dataFormulir.$errors;
      if(result){
        this.$confirm.require({
          message: 'Apakah data yang Anda masukkan sudah benar?',
          header: 'Pengisian Formulir',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Ya',
          rejectLabel: 'Cek kembali',
          defaultFocus:'reject',
          accept: () => {
            this.doSubmitIsiFormulir();
          },
          reject: () => {
          }
        });
      }else{
        this.successtrx = false;
        this.messagetrx = 'Silahkan isi formulir dengan benar';
      }
    },
    doSimpanIsiFormulir(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {invid:this.invid,method:'savedraft',formulir: this.dataFormulir};
      let path = '/dashboard/submitIsiFormulirPsb';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.messagetrx = response.data.message;
            if(response.data.status===200){
              this.successtrx = true;
              this.dataIsiFormulir = this.response.data;
              //router.push('/gemala/psb/terima-kasih/'+this.invid);
            }else {
              this.successtrx = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    async doSubmitIsiFormulir(){
      const result = await this.v$.dataFormulir.$validate();
      if(result){
        this.error = false;
        this.loading = true;
        this.$store.commit('loading',this.loading);
        let data = {invid:this.invid,method:'submit',formulir: this.dataFormulir};
        let path = '/dashboard/submitIsiFormulirPsb';
        DashboardServices.postData(data,path).then(
            (response)=>{
              this.response = response.data;
              this.message = response.data.message;
              this.messagetrx = response.data.message;
              if(response.data.status===200){
                this.success = true;
                this.error = false;
                this.dataIsiFormulir = this.response.data;
                router.push('/gemala/psb/terima-kasih/'+this.invid);
              }else {
                this.error = true;
                this.success = false;
              }
              this.loading = false;
              this.$store.commit('loading',this.loading);
            }
        );
      }else{
        this.error = true;
        this.loading = false;
        this.$store.commit('loading',this.loading);
        this.successtrx = false;
        this.messagetrx = 'Silahkan isi formulir dengan benar!';
      }
    },
    loadSavedDataFormulirPsb(){
      this.loaderformulir = true;
      let data = {paramname:this.invid};
      let path = '/dashboard/loadFormulir';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataSavedFormulir = this.response.data;
              this.dataSavedStatus = this.dataSavedFormulir.savestatus;
              if(this.dataSavedStatus){
                router.push('/gemala/psb/terima-kasih/'+this.invid);
              }
              this.isOpenNoSeven = this.dataSavedFormulir.isOpenNoSeven;
              this.dataFormulir.dataPribadi = this.dataSavedFormulir.formulir.dataPribadi;
              this.dataFormulir.dataLatarBelakangPendidikan = this.dataSavedFormulir.formulir.dataLatarBelakangPendidikan;
              this.dataFormulir.dataInformasiKeluarga = this.dataSavedFormulir.formulir.dataInformasiKeluarga;
              this.dataFormulir.dataInformasiOrangTuaTiri = this.dataSavedFormulir.formulir.dataInformasiOrangTuaTiri;
              this.dataFormulir.dataKontakDarurat = this.dataSavedFormulir.formulir.dataKontakDarurat;
              this.dataFormulir.dataKondisiKhusus = this.dataSavedFormulir.formulir.dataKondisiKhusus;
              this.dataFormulir.dataReferensi = this.dataSavedFormulir.formulir.dataReferensi;
              this.dataFormulir.dataPernyataanOrangTua = this.dataSavedFormulir.formulir.dataPernyataanOrangTua;
              this.dataFormulir.dataCatatanPribadiAnak = this.dataSavedFormulir.formulir.dataCatatanPribadiAnak;
              this.dataFormulir.dataFormulirKesehatan = this.dataSavedFormulir.formulir.dataFormulirKesehatan;
              this.dataFormulir.dataSuratPernyataanOrtu = this.dataSavedFormulir.formulir.dataSuratPernyataanOrtu;
              this.dataFormulir.dataPersetujuanKetentuanUmum = this.dataSavedFormulir.formulir.dataPersetujuanKetentuanUmum;
              this.dataFormulir.dataDokumenPendukung = this.dataSavedFormulir.formulir.dataDokumenPendukung;
              this.dataFormulir.dataKhusuPenambahan = this.dataSavedFormulir.formulir.dataKhusuPenambahan;
              this.dataFormulir.dataInformasiKendaraan = this.dataSavedFormulir.formulir.dataInformasiKendaraan;
            }else {
              this.success = false;
            }
            this.loaderformulir = false;
          }
      );
    },
    getJenisKelas(){
      this.loading = true;
      let data = {"paramname":"kelas"};
      let path = '/dashboard/getParameter';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.jenisKelas = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.invid};
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.dataFiles = this.response.data;
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getDataFormulirAnak(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.invid};
      let path = '/dashboard/getDataFormulirAnak';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataAnak = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  beforeMount() {
    this.getDataFormulirAnak();
    this.getJenisKelas();
  },
  async mounted() {
    this.loadSavedDataFormulirPsb();
    this.timerSimpan();
  },
  validations() {
    return {
      dataFormulir: {
        dataPribadi:{
          namalengkap:{required},
          namapanggilan:{required},
          tempatlahir:{required},
          tanggallahir:{required},
          kebangsaan:{required},
          agama:{required},
          nik:{required},
          masukkekelas:{required},
          semester:{required},
          usiatahun:{required},
          usiabulan:{required},
          berkebutuhankhusus:{required},
          photo:{required},
        },
        dataLatarBelakangPendidikan:{
          pgnamasekolah :{} ,
          pgtahunbelajar :{} ,
          tknamasekolah :{} ,
          tktahunbelajar :{} ,
          sdnamasekolah :{} ,
          sdtahunbelajar :{} ,
          les :{}
        },
        dataInformasiKeluarga:{
          ayahkandung:{
            nama :{required},
            kebangsaan:{required},
            statushubungan:{},
            agama:{required},
            hp1:{required},
            hp2:{},
            rumah:{
              alamat :{required},
              rtrw:{required},
              kotakabupaten:{required},
              kecamatan:{required},
              kelurahan:{required},
              kodepos:{required},
              telp:{}
            },
            kantor:{
              posisi:{required},
              bidang:{required},
              namaperusahaan:{required},
              alamat:{required},
              kodepos:{required},
              telp:{}
            },
            socmed:{
              facebook:{},
              instagram:{} ,
              lainlain:{}
            },
            email:{email}
          },
          ibukandung:{
            nama :{required},
            kebangsaan:{required},
            statushubungan:{},
            agama:{required},
            hp1:{required},
            hp2:{},
            rumah:{
              alamat :{required},
              rtrw:{required},
              kotakabupaten:{required},
              kecamatan:{required},
              kelurahan:{required},
              kodepos:{required},
              telp:{}
            },
            kantor:{
              posisi:{required},
              bidang:{required},
              namaperusahaan:{required},
              alamat:{required},
              kodepos:{required},
              telp:{}
            },
            socmed:{
              facebook:{},
              instagram:{},
              lainlain:{},
            },
            email:{email}
          },
          anak:{},
          tinggalbersama:{required}
        },
        dataInformasiOrangTuaTiri:{
          ayahtiri:{
            nama :{},
            kebangsaan:{},
            statushubungan:{},
            agama:{},
            hp1:{},
            hp2:{},
            rumah:{
              alamat :{} ,
              rtrw:{},
              kotakabupaten:{},
              kecamatan:{} ,
              kelurahan:{} ,
              kodepos:{} ,
              telp:{}
            },
            kantor:{
              posisi:{},
              namaperusahaan:{},
              alamat:{},
              kodepos:{} ,
              telp:{}
            },
            socmed:{
              facebook:{},
              instagram:{},
              lainlain:{}
            },
            email:{email}
          },
          ibutiri:{
            nama :{},
            kebangsaan:{},
            statushubungan:{},
            agama:{},
            hp1:{},
            hp2:{},
            rumah:{
              alamat :{},
              rtrw:{},
              kotakabupaten:{},
              kecamatan:{},
              kelurahan:{},
              kodepos:{},
              telp:{}
            },
            kantor:{
              posisi:{},
              namaperusahaan:{},
              alamat:{},
              kodepos:{},
              telp:{}
            },
            socmed:{
              facebook:{},
              instagram:{},
              lainlain:{}
            },
            email:{email}
          },
        },
        dataKontakDarurat:{
          nama:{required},
          hubungan:{required},
          hp:{required},
          telprumah:{},
          telpkantor:{}
        },
        dataKondisiKhusus:{
          kondisikhusus:{},
          namakondisi:{},
          dokter:{
            nama:{},
            alamat:{},
            telp:{},
          },
        },
        dataReferensi:{
          referensi:{},
          referensinama:{},
          nama:{},
          kelas:{},
          mengetahuidari:{},
          pernahhadir:{},
          tanggal:{}
        },
        dataPernyataanOrangTua:{
          bank:{
            bankcabang:{},
            banknama:{required},
            namarekening:{required},
            norekening:{required}
          },
          pernyataan:{required,minLength: minLength(3)},
          photo:{required}
        },
        dataCatatanPribadiAnak:{
          karakter:{required},
          deskripsi:{required},
          hubunganantarkeluarga:{required},
          bangunpagi:{required},
          jambangunpagi:{required},
          mandipagi:{required},
          jammandipagi:{required},
          makanpagi:{required},
          jammakanpagi:{required},
          makansiang:{required},
          jammakansiang:{required},
          makanmalam:{required},
          jammakanmalam:{required},
          tidurmalam:{required},
          jamtidurmalam:{required},
          tidursiang:{required},
          jamtidursiang:{required},
          gosokgigi:{required},
          memilihpakaian:{required},
          mengenakanpakaian:{required},
          mengenakansepatu:{required},
          kegiatandisukai:{required},
          photo:{}
        },
        dataFormulirKesehatan:{
          kelahiran:{required},
          prematur:{required},
          beratlahir:{required},
          panjanglahir:{required},
          pernahkejang:{required},
          speechdelay:{required},
          golongandarah:{required},
          beratbadan:{required},
          tinggibadan:{required},
          berkacamata:{required},
          jeniskacamata:{},
          kacamatakiri:{},
          kacamatakanan:{} ,
          imunisasi:{
            hepatitisB:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{},
              keterangan:{},
              Booster:{},
              Keterangan:{}
            },
            BCG:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{},
              keterangan:{}
            },
            DPT:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{},
              keterangan:{}
            },
            Polio:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{},
              keterangan:{}
            },
            HIB:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{} ,
              keterangan:{}
            },
            campak:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{},
              keterangan:{}
            },
            MMR:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{},
              keterangan:{}
            },
            Cacar:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{},
              keterangan:{}
            },
            Tifus:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{},
              keterangan:{}
            },
            HepatitisA:{
              tahap1:{},
              tahap2:{},
              tahap3:{},
              booster:{},
              keterangan:{}
            }
          },
          sikapimunsasi:{required},
          sikapimunsasipenjelasan:{required},
          alergi:{required},
          alergiketerangan:{},
          alergiketerangandokter:{},
          limitasifisik:{},
          limitasifisikketerangan:{},
          pernahoperasi:{},
          pernahoperasiketerangan:{},
          riwayatsakit:{
            batukrejan:{},
            asma:{},
            eksim:{},
            rheumaticfever:{},
            kejangkejang:{},
            sakitkepalapersisten:{},
            infeksiparu:{},
            infeksitelingapersisten:{},
            cacarair:{},
            campak:{},
            campakjerman:{},
            gandongan:{},
            rubella:{},
            epilepsi:{},
            tuberkilosis:{},
            mimisan:{},
            gangguanpenglihatan:{},
            gangguanpendengaran:{},
            masalahjantung:{},
            infeksiginjal:{},
            diabetes:{}
          },
          sedangpengobatanrutin:{required},
          sedangpengobatanrutinketerangan:{},
          kesehatankebutuhankhusus:{required},
          kesehatankebutuhankhususketerangan:{},
          dokter:{
            penilaian:{},
            nama:{required},
            alamat:{required},
            telepon:{},
            bersedia:{}
          },
          pernyataanortu:{
            menjamin:{required},
            bersedia:{},
            memberikanizin:{required}
          },
          filedata:{
            imunisasi:{},
            keterangandokter:{},
            keterangansehat:{},
          }
        },
        dataSuratPernyataanOrtu:{
          pernyataan:{
            ayah:{required},
            ibu:{required},
            namasiswa:{required}
          },
          menyatakan:{
            bersedia:{checked: value => value === true},
            memahami:{checked: value => value === true},
            bersediaaktif:{checked: value => value === true},
            memberiizin:{checked: value => value === true},
            memberitahukan:{checked: value => value === true}
          },
          dokumen:{required}
        },
        dataPersetujuanKetentuanUmum:{
          setujuketentuan:{checked: value => value === true},
          dokumen:{required}
        },
        dataDokumenPendukung:{
          tambahan:{},
          dokumen:{
            kelahiran:{required},
            kartukeluarga:{required},
            report:{required}
          },
        },
        dataKhusuPenambahan:{
          tambahan:{},
          dokumen:{
            kelahiran:{},
            kartukeluarga:{},
            report:{},
            tambahan:{}
          }
        },
        dataInformasiKendaraan:{
          datakendaraan:{},
          namaayah: {required},
          namaibu: {required},
          anak: {
            nama: {required},
            jeniskelamin: {required},
          },
          penjemput1: {
            nama: {},
            jeniskelamin: {},
            hubungan: {},
            ktp: {}
          },
          penjemput2: {
            nama: {},
            jeniskelamin: {},
            hubungan: {},
            ktp: {}
          },
          kendaraan1: {
            merk: {},
            nopol: {}
          },
          kendaraan2: {
            merk: {},
            nopol: {}
          },
          kendaraan3: {
            merk: {},
            nopol: {}
          },
          setuju: {checked: value => value === true}
        }
      }
    }
  },
  watch:{
    '$route.params.id':{
      immediate: true,
      deep: false,
      handler(){
        this.invid = this.$route.params.id;
      }
    }
  }
}
</script>

<style scoped>

</style>