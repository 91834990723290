<template>
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr>
      <h3>{{title}}</h3>
      <p>{{subtitle}}</p>
      <hr>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormTitle",
  props:{
    title: String,
    subtitle: String
  }
}
</script>

<style scoped>

</style>