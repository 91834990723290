<template>
  <div class="row">
    <div class="col-12">
      <div class="table-data-sga">
        <div class="table-responsive">
          <table class="table table-striped ">
            <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">No formulir</th>
              <th scope="col">Tanggal</th>
              <th scope="col">Jumlah</th>
              <th scope="col">Biaya</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(formulir,idx) in dataformulir" :key="idx">
                <td>{{++idx}}</td>
                <td>{{formulir.noinv}}</td>
                <td>{{formulir.tgl}}</td>
                <td>{{formulir.jml}}</td>
                <td>{{formulir.hargaformulir}}</td>
                <td>{{formulir.biayaformulir}}</td>
                <td v-if="formulir.flagBiayaFormulir ===0">
                  <ButtonLoading v-if="loading"></ButtonLoading>
                  <button v-else @click="doPaymentFormulir2(formulir)" class="btn btn-sm btn-success"><i class="fa-solid fa-caret-right"></i> {{buttontitle}}</button>
                </td>
                <td v-else>
                  <ButtonLoading v-if="loading"></ButtonLoading>
                  <button v-else @click="doRedirectToPath()" class="btn btn-sm btn-success"><i class="fa-solid fa-caret-right"></i> {{buttontitle2}}</button>
                </td>
              </tr>
            </tbody>
          </table>
          <AlertSuccess v-if="success" :message="messagetrx"></AlertSuccess>
          <AlertDanger v-else :message="messagetrx"></AlertDanger>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="showPaymentChannel" modal header="Pilih Channel Pembayaran" :style="{ width: '25rem' }">
    <div class="flex flex-col gap-4 w-[25rem]">
      <PaymentOption path="/dashboard/doPaymentFormulir" :is-konfirmasi=false :formulir="formulirData"/>
    </div>
  </Dialog>
</template>

<script>
import ButtonLoading from "@/components/ButtonLoading";
import DashboardServices from "@/services/dashboard.services";
import AlertDanger from "@/components/AlertDanger";
import AlertSuccess from "@/components/AlertSuccess";
import router from "@/router";
import PaymentOption from "@/components/PaymentOption";
import Dialog from 'primevue/dialog';
export default {
  name: "TableDataFormulir",
  components: {PaymentOption, AlertSuccess, AlertDanger, ButtonLoading,Dialog},
  props:{
    dataformulir:undefined,
    routepath: String,
    buttontitle: String,
    routeterbayar: String,
    buttontitle2: String
  },
  data:()=>({
    loading: false,
    showPaymentChannel: false,
    messagetrx: '',
    success: false,
    formulirData:[],
    dataPembayaran:[]
  }),
  methods:{
    doRedirectToPath(){
      router.push(this.routeterbayar);
    },
    doPaymentFormulir2(formulir){
      this.formulirData = formulir;
      this.showPaymentChannel = true;
    },
    doPaymentFormulir(formulir){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {data:formulir};
      let path = '/dashboard/doPaymentFormulir';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.messagetrx = response.data.message;
              this.dataPembayaran = this.response.data;
              if(this.dataPembayaran.Status==='SUCCESS'){
                let checkouturl = this.dataPembayaran.CheckoutURL;
                window.open(checkouturl, '_self');
              }else{
                this.messagetrx = 'Mohon coba kembali';
              }
            }else {
              this.messagetrx = response.data.message;
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    generateLink(){
      return this.routepath;
    }
  }
}
</script>

<style scoped>

</style>