<template>
  <PageResetPassword></PageResetPassword>
</template>

<script>
import PageResetPassword from "@/components/views/PageResetPassword";
export default {
  name: "routerResetPassword",
  components: {PageResetPassword}
}
</script>

<style scoped>

</style>