<template>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Jadwal Kelas Percobaan - {{data.anak_nama}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a></div>
      <div class="col-12"><hr></div>
    </div>
    <div class="notes-notification-sga">
      <p><strong>Catatan :</strong><br>Pilih Tim yang sesuai untuk sesi penilaian</p></div>
    <div class="row">
      <div class="col-12">
          <div class="form-general">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4 spacer-field-sga"><normal-label>No formulir </normal-label></div>
                  <div class="col-md-8 spacer-field-sga">{{data.inv_child}}</div>
                  <div class="col-md-4 spacer-field-sga"><normal-label>Nama Ananda </normal-label></div>
                  <div class="col-md-8 spacer-field-sga">{{data.anak_nama}}</div>
                  <div class="col-md-4 spacer-field-sga"><normal-label>Tanggal penilaian </normal-label></div>
                  <div class="col-md-8 spacer-field-sga">{{data.hari}}, {{data.tanggalid}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <LoadSpinner v-if="loading"></LoadSpinner>
                <div class="row" v-else>
                  <div class="col-12">
                    <normal-label>Tim Assessor : <select v-model="timid" class="sga-status-dropdown" >
                      <option v-for="(tim,idx) in dataAssessor" :key="idx" :value="tim.id_assessor">{{tim.nama_tim}}</option>
                    </select></normal-label>
                  </div>
                  <div class="col-12">
                    <normal-label>Tim Pewawancara : <select v-model="timpewawancara" class="sga-status-dropdown" >
                      <option v-for="(tim,idx) in dataPewawancara" :key="idx" :value="tim.id_ext_assessor">{{tim.nama_tim_ext}}</option>
                    </select></normal-label>
                  </div>
                  <button @click="submitAssessorSchedule" type="button" class="btn btn-sm btn-success ml-3">Simpan Tim</button>
                  <div class="col-12" v-if="messagetrx">
                    <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
                    <AlertDanger v-else :message="messagetrx"></AlertDanger>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import LoadSpinner from "@/components/LoadSpinner";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";

export default {
  name: "PagePilihTimPenilaian",
  components: {AlertDanger, AlertSuccess, LoadSpinner},
  props:{
    data:undefined
  },
  data:()=>({
    loading: false,
    message:'',
    response:'',
    success:'',
    messagetrx:'',
    successtrx:false,
    timid:'',
    timpewawancara:'',
    dataAssessor:[],
    dataPewawancara:[],
  }),
  emits: ['change-view'],
  methods:{
    toggleview(){
      this.$emit('change-view',true)
    },
    getDataKelasPercobaan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getListAssesor';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataAssessor = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getDataPewawancara(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getListExternalAssesor';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPewawancara = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    submitAssessorSchedule(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        idassesor: this.timid,
        idpewawancara: this.timpewawancara,
        jadwalid: this.data.jadwal_id,
        invid: this.data.inv_child
      };
      let path = '/dashboard/setTimAssessorToSchedule';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.messagetrx = response.data.message;
            if(response.data.status===200){
              this.successtrx = true;
            }else {
              this.successtrx = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataKelasPercobaan();
    this.getDataPewawancara();
    this.timid = this.data.id_assessor;
    this.timpewawancara = this.data.id_pewawancara;
  }
}
</script>

<style scoped>

</style>