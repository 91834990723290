<template>
  <HeaderParent></HeaderParent>
  <PagePanduan v-if="page==='panduan'"></PagePanduan>
  <PageFormFormulirPsb v-if="page==='isi'"></PageFormFormulirPsb>
  <PageBiayaKonfirmasi v-if="page==='biaya-konfirmasi'"></PageBiayaKonfirmasi>
  <PagePembayaranBiayaKonfirmasi v-if="page==='bayar-biaya-konfirmasi'"></PagePembayaranBiayaKonfirmasi>
  <PageBiayaPelunasan v-if="page==='biaya-pelunasan-uang-sekolah'"></PageBiayaPelunasan>
  <PageTerimaKasihIsiFormulir v-if="page==='terima-kasih'"></PageTerimaKasihIsiFormulir>
  <PageFormulirBerasa v-if="page==='formulir-berasa'"></PageFormulirBerasa>
  <PageIsiFormulirBerasa v-if="page==='isi-formulir-berasa'"></PageIsiFormulirBerasa>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderParent from "@/components/HeaderParent";
import FooterWeb from "@/components/FooterWeb";
import PagePanduan from "@/components/views/psb/PagePanduan";
import PageFormFormulirPsb from "@/components/views/psb/PageFormFormulirPsb";
import PageBiayaKonfirmasi from "@/components/views/biaya/PageBiayaKonfirmasi";
import PagePembayaranBiayaKonfirmasi from "@/components/views/biaya/PagePembayaranBiayaKonfirmasi";
import PageTerimaKasihIsiFormulir from "@/components/views/psb/TerimaKasihIsiFormulir";
import PageBiayaPelunasan from "@/components/views/biaya/PageBiayaPelunasan";
import PageFormulirBerasa from "@/components/views/berasa/PageFormulirBerasa";
import PageIsiFormulirBerasa from "@/components/views/berasa/PageIsiFormulirBerasa";
export default {
  name: "routerPsb",
  components: {
    PageIsiFormulirBerasa,
    PageFormulirBerasa,
    PageBiayaPelunasan,
    PageTerimaKasihIsiFormulir,
    PagePembayaranBiayaKonfirmasi,
    PageBiayaKonfirmasi, PageFormFormulirPsb, PagePanduan, FooterWeb, HeaderParent},
  data:()=>({
    page: '',
    pageAllow : ['isi','panduan','biaya-konfirmasi','bayar-biaya-konfirmasi','biaya-pelunasan-uang-sekolah','formulir-berasa','isi-formulir-berasa','terima-kasih']
  }),
  watch:{
    '$route.params.page':{
      immediate: true,
      deep: false,
      handler(){
        this.page = this.$route.params.page.toLowerCase();
        if(this.pageAllow.includes(this.page)){
          //this.$store.commit('tipepsb',this.page);
        }else{
          this.page = 'panduan';
        }
      }
    }
  }
}
</script>

<style scoped>

</style>