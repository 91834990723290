<template>
  <HeaderParent></HeaderParent>
  DKhsakjhdjkash
</template>

<script>
import HeaderParent from "@/components/HeaderParent";
export default {
  name: "NotFound404",
  components: {HeaderParent}
}
</script>

<style scoped>

</style>