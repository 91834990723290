<template>
  <div class="container-fluid content-area">
    <div class="row">
      <div class="col-md-9">
        <h3 class="judul-seksi-sga">Data Pengembalian Formulir PSB Tahun Ajaran {{this.$store.getters.getTahunAJaran}}</h3>
      </div>
    </div>
    <div class="" style="padding-top: 20px;">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <Toolbar class="mb-4">
              <template #end>
                <Button label="Export" icon="pi pi-upload" severity="help" @click="exportCSV($event)"  />
              </template>
            </Toolbar>
            <div class="card">
              <DataTable v-model:filters="filters"
                         :value="dataPendaftaran"
                         ref="dt"
                         tableStyle="min-width: 50rem"
                         :paginator="true"
                         :rows="25"
                         :rowsPerPageOptions="[5, 10, 20,25, 50,100]"
                         paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                         currentPageReportTemplate="Tampil {first} dari {last} of {totalRecords} data pengembalian"
                         :first="0"
                         :loading="loading"
                         class="p-datatable-sm"
                         rowGroupMode="subheader"
                         groupRowsBy="idortu"
                         sortMode="single"
                         stripedRows
              >
                <template #header>
                  <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                  </div>
                </template>
                <template #empty> Data tidak ada. </template>
                <template #loading> Mengambil data. Mohon tunggu. </template>
                <template #paginatorstart>
                  <Button type="button" icon="pi pi-refresh" text @click="getDataPendaftaran" />
                </template>
                <template #paginatorend>
                </template>
                <template #groupheader="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span><strong>Nama orang tua</strong> : {{ slotProps.data.namaortu }} | {{slotProps.data.emailortu}}</span>
                  </div>
                </template>
                <Column field="tgl" header="Tanggal" sortable style="min-width:12rem"></Column>
                <Column field="noinv" header="No pendaftaran" sortable style="min-width:16rem"></Column>
                <Column header="Status" style="min-width:16rem">
                  <template #body="slotProps">
                    <span v-if="slotProps.data.statusFormulir">
                      <router-link :to=generatelink(slotProps.data.invchild)>
                        <Button size="small" icon="pi pi-search" :label=slotProps.data.namaStatusFormulir severity="success" rounded/>
                      </router-link>
                    </span>
                    <Button v-else size="small" :label=slotProps.data.namaStatusFormulir severity="warning" rounded/>
                  </template>
                </Column>
                <Column field="posisiFormulir" header="Status" sortable style="min-width:16rem"></Column>
                <Column field="childinvid" header="No formulir" sortable style="min-width:16rem"></Column>
                <Column field="namaanak" header="Nama anak" sortable style="min-width:16rem"></Column>
                <Column field="namaortu" header="Nama orang tua" sortable style="min-width:16rem"></Column>
                <Column field="jenisdaftar" header="Jenis daftar" sortable style="min-width:16rem"></Column>
                <Column field="biayapendaftaran" header="Biaya daftar" sortable style="min-width:16rem"></Column>
                <Column field="biayakonfirmasi" header="Tanda konfirmasi" sortable style="min-width:16rem"></Column>
                <Column header="Lihat formulir" sortable style="min-width:16rem">
                  <template #body="slotProps">
                    <router-link :to=generatelink(slotProps.data.invchild)>
                      <Button label="Lihat" icon="pi pi-search" severity="success"></Button>
                    </router-link>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import DashboardServices from "@/services/dashboard.services";
import Button from 'primevue/button';
import {FilterMatchMode} from "@primevue/core/api";
import Toolbar from 'primevue/toolbar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from "primevue/inputtext";
export default {
  name: "ViewDataPengembalianFormulir",
  components:{Toolbar,DataTable,Column,Button,InputText},
  data:()=>({
    dataPendaftaran: [],
    loading: false,
    message: '',
    success: false,
    dataInformasi:[],
    response:'',
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'tgl': {value: '', matchMode: FilterMatchMode.CONTAINS},
      'noinv': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'jml': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'hargaformulir': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'biayaformulir': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
    },
  }),
  methods:{
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    generatelink(invchild){
      return "/admin/data/view-formulir-psb/"+invchild;
    },
    getDataPendaftaran(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.accountData;
      let path = '/dashboard/getDataPendaftaranAdmin';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    this.getDataPendaftaran();
  }
}
</script>

<style scoped>

</style>