<template>
<FormLogin></FormLogin>
</template>

<script>
import FormLogin from "@/components/views/FormLogin";
export default {
  name: "routerLogin",
  components: {FormLogin}
}
</script>

<style scoped>

</style>