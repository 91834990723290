<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Formulir > Lihat Data Pengembalian Formulir > Data Formulir"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-12">
        <h3 class="judul-seksi-sga">Data Formulir PSB - No formulir {{dataAnak.inv_child}}</h3>
        <normal-label>Status formulir : <select v-model="statusformulir" class="sga-status-dropdown mr-2">
          <option value="Menunggu validasi">Menunggu validasi</option>
          <option value="Lengkap">Lengkap</option>
          <option value="Tambahan">Butuh Dokumen Tambahan</option>
        </select></normal-label>
        <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
        <AlertDanger v-else :message="messagetrx"></AlertDanger>
        <LoadSpinner v-if="loading"></LoadSpinner>
        <button v-else @click="submitVerifikasiFormulir" class="btn btn-sm btn-success">Ubah</button>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="notes-notification-sga2">
              <div class="row">
                <div class="col-md-3 my-auto">	No formulir:<br><strong>{{dataAnak.inv_child}}</strong></div>
                <div class="col-md-4 my-auto">	Nama Ananda:<br><strong>{{dataAnak.anak_nama}}</strong></div>
                <div class="col-md-3 my-auto">Jenis pendaftaran:<br><strong>{{dataAnak.anak_jenisdaftar}}</strong></div>
              </div>
            </div>
          </div>
        </div>
        <fieldset>
          <ProgressBar v-if="loaderformulir" mode="indeterminate" class="mb-3" style="height: 6px"></ProgressBar>
          <form v-else class="needs-validation" novalidate>
            <div class="form-general">
              <div class="row">
                <div class="col-12 text-left">
                  <ul id="clothingnav1" class="nav nav-tabs justify-content-center" role="tablist">
                    <li class="nav-item"><a class="nav-link" href="#panel1" id="hometab1"
                                            role="tab" data-toggle="tab" aria-controls="home"
                                            aria-expanded="true" :class="getClass(1)" @click="setStep(1)"><span :class="formerror.satu?'text-danger':''">1</span></a></li>
                    <li class="nav-item"><a class="nav-link" href="#panel2" role="tab"
                                            data-toggle="tab" aria-controls="hats" :class="getClass(2)"
                                            @click="setStep(2)"><span :class="formerror.dua?'text-danger':''">2</span></a></li>
                    <li class="nav-item"><a class="nav-link" href="#panel3" role="tab"
                                            data-toggle="tab" aria-controls="hats" :class="getClass(3)"
                                            @click="setStep(3)"><span :class="formerror.tiga?'text-danger':''">3</span></a></li>
                    <li class="nav-item"><a class="nav-link" href="#panel4" role="tab"
                                            data-toggle="tab" aria-controls="hats" :class="getClass(4)"
                                            @click="setStep(4)"><span :class="formerror.empat?'text-danger':''">4</span></a></li>
                    <li class="nav-item"><a class="nav-link" href="#panel5" role="tab"
                                            data-toggle="tab" aria-controls="hats" :class="getClass(5)"
                                            @click="setStep(5)"><span :class="formerror.lima?'text-danger':''">5</span></a></li>
                    <li class="nav-item"><a class="nav-link" href="#panel6" role="tab"
                                            data-toggle="tab" aria-controls="hats" :class="getClass(6)"
                                            @click="setStep(6)"><span :class="formerror.enam?'text-danger':''">6</span></a></li>
                    <li class="nav-item"><a class="nav-link" href="#panel7" role="tab"
                                            data-toggle="tab" aria-controls="hats" :class="getClass(7)"
                                            @click="setStep(7)">7</a></li>
                    <li class="nav-item"><a class="nav-link" href="#panel8" role="tab"
                                            data-toggle="tab" aria-controls="hats" :class="getClass(8)"
                                            @click="setStep(8)">8</a></li>
                  </ul>
                  <!-- Content Panel -->
                  <div id="clothingnavcontent1" class="tab-content tab-content-sga-form">
                    <div role="tabpanel" class="tab-pane fade show" :class="getClass(1)"
                         aria-labelledby="hometab1">
                      <div class="tabs-form-panel-title-sga"> Formulir Pendaftaran</div>
                      <!--Data Pribadi-->
                      <PageFormDataPribadi
                          :initdata="dataFormulir.dataPribadi"
                          :idanak="invid"
                          :jenis-kelas="jenisKelas"
                          :data-files="dataFiles"
                          :showupload="false"
                      ></PageFormDataPribadi>
                      <!--Latar Belakang Pendidikan-->
                      <PageFormLatarBelakangPendidikan :initdata="dataFormulir.dataLatarBelakangPendidikan" ></PageFormLatarBelakangPendidikan>
                      <!--Informasi Keluarga-->
                      <PageFormInformasiKeluarga :showupload="false" :initdata="dataFormulir.dataInformasiKeluarga" ></PageFormInformasiKeluarga>
                      <!--Informasi Orang Tua Tiri/Angkat atau Wali-->
                      <PageFormInformasiOrangTuaTiri :initdata="dataFormulir.dataInformasiOrangTuaTiri" ></PageFormInformasiOrangTuaTiri>
                      <!--Kontak darurat (bila orang tua tidak dapat dihubungi)-->
                      <PageFormKontakDarurat :initdata="dataFormulir.dataKontakDarurat" ></PageFormKontakDarurat>
                      <!--Kebutuhan/Kondisi Khusus-->
                      <PageFormKondisiKhusus :initdata="dataFormulir.dataKondisiKhusus" ></PageFormKondisiKhusus>
                      <!--Referensi (bila ada)-->
                      <PageFormReferensi :initdata="dataFormulir.dataReferensi" ></PageFormReferensi>
                      <!--Pernyataan Orang Tua-->
                      <PageFormPernyataanOrangTua :showupload="false" :idanak="invid" :initdata="dataFormulir.dataPernyataanOrangTua" ></PageFormPernyataanOrangTua>
                    </div>
                    <div role="tabpanel" class="tab-pane fade show" :class="getClass(2)" aria-labelledby="hatstab1">
                      <div class="tabs-form-panel-title-sga">Catatan Pribadi Anak</div>
                      <PageFormKarakteristikUmum :showupload="false" :initdata="dataFormulir.dataCatatanPribadiAnak" ></PageFormKarakteristikUmum>
                    </div>
                    <div role="tabpanel" class="tab-pane fade show" :class="getClass(3)" aria-labelledby="hatstab1">
                      <div class="tabs-form-panel-title-sga">Formulir Kesehatan</div>
                      <div class="row" style="margin-top:20px; margin-bottom:-35px;">
                        <div class="col-md-10" >
                          <strong>Langkah pengisian Form Kesehatan </strong>
                          <ol>
                            <li>Orang tua mengisi Formulir Kesehatan. </li>
                            <li>Cetak form kesehatan tersebut. </li>
                            <li>Unduh dan cetak surat pengantar untuk dokter, dan tuliskan nama dokter yang biasa menangani ananda. </li>
                            <li>Silakan konsultasi ke dokter tsb., dengan membawa form dan surat pengantar tersebut. Minta dokter untuk:
                              <ul>
                                <li> memverifikasi data kesehatan dan membubuhkan paraf pada setiap halaman, </li>
                                <li> melakukan pemeriksaan kesehatan ananda, </li>
                                <li>mengisi formulir bagian Verifikasi Dokter. </li>
                              </ul>
                            </li>
                            <li>Masuk kembali ke Sistem PSB SGA
                              <ul>
                                <li>Sesuaikan isi form dengan penilaian dan rekomendasi yang dituliskan dokter dalam form cetak. </li>
                                <li>Tuliskan nama lengkap dokter dan alamat praktiknya. </li>
                                <li>Pindai (scan) keseluruhan dokumen dan unggah ke sistem. </li>
                                <li>Jangan lupa klik “simpan”. </li>
                              </ul>
                            </li>
                          </ol>
                        </div>
                        <div class="col-md-2">
                          <button onclick="window.print()" class="btn btn-sm btn-success">Cetak form kesehatan</button>
                        </div>
                      </div>
                      <PageFormKesehatan :showupload="false" :idanak="invid" :initdata="dataFormulir.dataFormulirKesehatan" ></PageFormKesehatan>
                    </div>
                    <div role="tabpanel" class="tab-pane fade show" :class="getClass(4)" aria-labelledby="hatstab1">
                      <div class="tabs-form-panel-title-sga">Surat Pernyataan Orang Tua</div>
                      <PageSuratPernyataanOrangTua :showupload="false" :idanak="invid" :initdata="dataFormulir.dataSuratPernyataanOrtu" ></PageSuratPernyataanOrangTua>
                    </div>
                    <div role="tabpanel" class="tab-pane fade show" :class="getClass(5)" aria-labelledby="hatstab1">
                      <div class="tabs-form-panel-title-sga">Persetujuan Ketentuan Umum</div>
                      <PageFormKetentuanUmum :showupload="false" :idanak="invid" :initdata="dataFormulir.dataPersetujuanKetentuanUmum" ></PageFormKetentuanUmum>
                    </div>
                    <div role="tabpanel" class="tab-pane fade show" :class="getClass(6)" aria-labelledby="hatstab1">
                      <div class="tabs-form-panel-title-sga">Dokumen Pendukung</div>
                      <PageFormDokumenPendukung :showupload="false" :idanak="invid" :initdata="dataFormulir.dataDokumenPendukung" ></PageFormDokumenPendukung>
                    </div>
                    <div role="tabpanel" class="tab-pane fade show" :class="getClass(7)" aria-labelledby="hatstab1">
                      <div class="tabs-form-panel-title-sga">Formulir Kendaraan</div>
                      <PageFormulirKendaraan :idanak="invid" :initdata="dataFormulir.dataInformasiKendaraan"></PageFormulirKendaraan>
                    </div>
                    <div role="tabpanel" class="tab-pane fade show" :class="getClass(8)" aria-labelledby="hatstab1">
                      <div class="tabs-form-panel-title-sga">Khusus Penambahan Data</div>
                      <PageFormDokumenTambahan :showupload="false" :idanak="invid" :initdata="dataFormulir.dataKhusuPenambahan" ></PageFormDokumenTambahan>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <ListUpdateData :dataupdate="dataStatusPerubahan"></ListUpdateData>
            </div>
          </form>
        </fieldset>
      </div>
    </div>

  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import PageFormDataPribadi from "@/components/views/psb/PageFormDataPribadi";
import PageFormLatarBelakangPendidikan from "@/components/views/psb/PageFormLatarBelakangPendidikan";
import PageFormInformasiKeluarga from "@/components/views/psb/PageFormInformasiKeluarga";
import PageFormKontakDarurat from "@/components/views/psb/PageFormKontakDarurat";
import PageFormKondisiKhusus from "@/components/views/psb/PageFormKondisiKhusus";
import PageFormReferensi from "@/components/views/psb/PageFormReferensi";
import PageFormPernyataanOrangTua from "@/components/views/psb/PageFormPernyataanOrangTua";
import PageFormInformasiOrangTuaTiri from "@/components/views/psb/PageFormInformasiOrangTuaTiri";
import PageFormKarakteristikUmum from "@/components/views/psb/dua/PageFormKarakteristikUmum";
import PageFormKesehatan from "@/components/views/psb/tiga/PageFormKesehatan";
import PageFormKetentuanUmum from "@/components/views/psb/lima/PageFormKetentuanUmum";
import PageFormDokumenPendukung from "@/components/views/psb/enam/PageFormDokumenPendukung";
import PageFormDokumenTambahan from "@/components/views/psb/tujuh/PageFormDokumenTambahan";
import DashboardServices from "@/services/dashboard.services";
import PageSuratPernyataanOrangTua from "@/components/views/psb/empat/PageFormPernyataanOrangTua";
import ProgressBar from 'primevue/progressbar';
import LoadSpinner from "@/components/LoadSpinner";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";
import ListUpdateData from "@/components/ListUpdateData";
import PageFormulirKendaraan from "@/components/views/psb/delapan/PageFormulirKendaraan";
export default {
  name: "PageViewFormulirPSB",
  components:{
    PageFormulirKendaraan,
    ListUpdateData,
    AlertDanger,
    AlertSuccess,
    LoadSpinner,
    PageFormDataPribadi,PageFormLatarBelakangPendidikan,PageFormInformasiKeluarga,PageFormKontakDarurat,
    PageFormKondisiKhusus,PageFormReferensi,PageFormPernyataanOrangTua,PageFormInformasiOrangTuaTiri,PageFormKarakteristikUmum,
    PageFormKesehatan,PageFormKetentuanUmum,PageFormDokumenPendukung,PageFormDokumenTambahan,PageSuratPernyataanOrangTua,
    ProgressBar,BreadCrumb
  },
  data: () => ({
    invid: '',
    dataAnak:[],
    message:'',
    messagetrx:'',
    successtrx: false,
    dataFiles:[],
    loaderformulir: false,
    step:1,
    statusformulir:'',
    loading: false,
    jenisKelas:[],
    formerror:{
      satu:false,
      dua:false,
      tiga:false,
      empat:false,
      lima:false,
      enam:false
    },
    dataStatusPerubahan:[],
    dataFormulir:{
      dataPribadi:[],
      dataLatarBelakangPendidikan:[],
      dataInformasiKeluarga:[],
      dataInformasiOrangTuaTiri:[],
      dataKontakDarurat:[],
      dataKondisiKhusus:[],
      dataReferensi:[],
      dataPernyataanOrangTua:[],
      dataCatatanPribadiAnak:[],
      dataFormulirKesehatan:[],
      dataSuratPernyataanOrtu:[],
      dataPersetujuanKetentuanUmum:[],
      dataDokumenPendukung:[],
      dataKhusuPenambahan:[],
      dataInformasiKendaraan:[]
    }
  }),
  methods:{
    getClass(id) {
      return this.step === id ? 'active' : '';
    },
    setStep(step) {
      this.step = step;
    },
    getJenisKelas(){
      this.loading = true;
      let data = {"paramname":"kelas"};
      let path = '/dashboard/getParameter';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.jenisKelas = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getDataFormulirAnak(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.invid};
      let path = '/dashboard/getDataFormulirAnak';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataAnak = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    submitVerifikasiFormulir(){
      this.loading = true;
      this.successtrx = false;
      let data = {paramname:this.invid, status: this.statusformulir};
      let path = '/dashboard/setStatusFormulirAdmin';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.messagetrx = response.data.message;
            if(response.data.status===200){
              this.successtrx = true;
              this.messagetrx = response.data.message;
            }
            this.loading = false;
          }
      );
    },
    loadSavedDataFormulirPsb(){
      this.loaderformulir = true;
      let data = {paramname:this.invid};
      let path = '/dashboard/loadFormulirAdmin';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataSavedFormulir = this.response.data;
              if(this.dataSavedFormulir != null){
                this.dataSavedStatus = this.dataSavedFormulir.savestatus;
                this.statusformulir =  this.dataSavedFormulir.statusFormulir;
                this.dataStatusPerubahan = this.dataSavedFormulir.statusUpdate;
                this.dataFormulir.dataPribadi = this.dataSavedFormulir.formulir.dataPribadi;
                this.dataFormulir.dataLatarBelakangPendidikan = this.dataSavedFormulir.formulir.dataLatarBelakangPendidikan;
                this.dataFormulir.dataInformasiKeluarga = this.dataSavedFormulir.formulir.dataInformasiKeluarga;
                this.dataFormulir.dataInformasiOrangTuaTiri = this.dataSavedFormulir.formulir.dataInformasiOrangTuaTiri;
                this.dataFormulir.dataKontakDarurat = this.dataSavedFormulir.formulir.dataKontakDarurat;
                this.dataFormulir.dataKondisiKhusus = this.dataSavedFormulir.formulir.dataKondisiKhusus;
                this.dataFormulir.dataReferensi = this.dataSavedFormulir.formulir.dataReferensi;
                this.dataFormulir.dataPernyataanOrangTua = this.dataSavedFormulir.formulir.dataPernyataanOrangTua;
                this.dataFormulir.dataCatatanPribadiAnak = this.dataSavedFormulir.formulir.dataCatatanPribadiAnak;
                this.dataFormulir.dataFormulirKesehatan = this.dataSavedFormulir.formulir.dataFormulirKesehatan;
                this.dataFormulir.dataSuratPernyataanOrtu = this.dataSavedFormulir.formulir.dataSuratPernyataanOrtu;
                this.dataFormulir.dataPersetujuanKetentuanUmum = this.dataSavedFormulir.formulir.dataPersetujuanKetentuanUmum;
                this.dataFormulir.dataDokumenPendukung = this.dataSavedFormulir.formulir.dataDokumenPendukung;
                this.dataFormulir.dataKhusuPenambahan = this.dataSavedFormulir.formulir.dataKhusuPenambahan;
                this.dataFormulir.dataInformasiKendaraan = this.dataSavedFormulir.formulir.dataInformasiKendaraan;
              }else{
                this.loaderformulir = false;
                this.message = 'Data tidak ditemukan';
              }
            }else {
              this.success = false;
            }
            this.loaderformulir = false;
          }
      );
    },
  },
  beforeMount() {
    this.getJenisKelas();
  },
  mounted() {
    this.getDataFormulirAnak();
    this.loadSavedDataFormulirPsb();
  },
  watch:{
    '$route.params.id':{
      immediate: true,
      deep: false,
      handler(){
        this.invid = this.$route.params.id;
      }
    }
  }
}
</script>

<style scoped>
input:disabled {
  background: #ffffff;
}
</style>