<template>
  <HeaderParent></HeaderParent>
  <InformasiPendaftaran></InformasiPendaftaran>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderParent from "@/components/HeaderParent";
import FooterWeb from "@/components/FooterWeb";
import InformasiPendaftaran from "@/components/views/InformasiPendaftaran";
export default {
  name: "RouteInformasiPendaftaran",
  components:{
    InformasiPendaftaran,
    FooterWeb,
    HeaderParent,
  }
}
</script>

<style scoped>

</style>