<template>
  <HeaderParent></HeaderParent>
  <PageHasilKeputusan></PageHasilKeputusan>
</template>

<script>
import PageHasilKeputusan from "@/components/views/hasilkeputusan/PageHasilKeputusan";
import HeaderParent from "@/components/HeaderParent";
export default {
  name: "routerHasilKeputusan",
  components: {HeaderParent, PageHasilKeputusan}
}
</script>

<style scoped>

</style>