<template>
  <div class="container breadcrumb-area">
    <div class="row">
      <div class="col-12 breadcrumb-text">Beranda > Akun</div>
    </div>
  </div>
  <!--content-area-sga-->
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Informasi Akun</h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <router-link to="/logout" class="btn btn-sm btn-warning"><i class="fa-solid fa-right-from-bracket"></i></router-link>
      </div>
    </div>
    <hr>
    <div class="form-general">
      <div class="row ">
        <div class="col-md-6 text-left">Nama pengguna</div>
        <div class="col-md-6 text-left"><input v-model="accountData.nama" type="text" class="w-100 form-control" required></div>
        <div class="col-md-6 text-left spacer-field-sga">Alamat surel</div>
        <div class="col-md-6 spacer-field-sga text-left"><input v-model="accountData.email" type="text" disabled="disabled" class="w-100 form-control" required></div>
        <div class="col-md-6 text-left spacer-field-sga">Ulangi alamat surel</div>
        <div class="col-md-6  spacer-field-sga text-left"><input v-model="accountData.email2" type="text" class="w-100 form-control" required></div>
        <div class="col-md-6 text-left spacer-field-sga">Kata sandi lama</div>
        <div class="col-md-6 text-left spacer-field-sga"><input v-model="accountData.oldpassword" type="password" class="w-100 form-control" required></div>
        <div class="col-md-6 text-left spacer-field-sga">Kata sandi baru</div>
        <div class="col-md-6 text-left spacer-field-sga"><input v-model="accountData.password" type="password" class="w-100 form-control" required></div>
        <div class="col-md-6 text-left spacer-field-sga">Ulangi kata sandi baru</div>
        <div class="col-md-6 text-left spacer-field-sga"><input v-model="accountData.password2" type="password" class="w-100 form-control" required></div>
        <div class="col-md-12 text-right spacer-field-sga">
          <AlertSuccess v-if="success" :message="message"></AlertSuccess>
          <AlertDanger v-else :message="message"></AlertDanger>
          <LoadSpinner v-if="loading"></LoadSpinner>
          <button v-else class="btn btn-md btn-success" @click="doChangeUserPassword" type="button"><i class="fa-solid fa-caret-right"></i> Ubah
            data
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import LoadSpinner from "@/components/LoadSpinner";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";

export default {
  name: "PageAccount",
  components: {AlertDanger, AlertSuccess, LoadSpinner},
  data:()=>({
    logo: require('@/assets/images/logo-sga.png'),
    logoAnimated: require('@/assets/images/logo-sga-animated2.gif'),
    accountData:{
      nama:'',
      email:'',
      email2:'',
      oldpassword:'',
      password:'',
      passwor2:'',
    },
    response:'',
    message:'',
    success: false,
    loading: false
  }),
  methods:{
    doClearForm(){
      this.accountData.oldpassword = '';
      this.accountData.email2 = '';
      this.accountData.password = '';
      this.accountData.password2 = '';
    },
    doChangeUserPassword(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.accountData;
      let path = '/dashboard/changePassword';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.doClearForm();
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getUserInformation(){
      let userdata = this.$store.getters.getUserInfo;
      this.accountData.nama = userdata.data.fullname;
      this.accountData.email = userdata.data.email;
    }
  },
  beforeMount() {
    this.getUserInformation();
  }
}
</script>

<style scoped>

</style>