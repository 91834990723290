<template>
  <Bread-crumb page="Beranda > Pendaftaran > Informasi Pendaftaran"></Bread-crumb>
  <!--content-area-sga-->
  <div class="container content-area">
    <LoadSpinner v-if="loading"></LoadSpinner>
    <ArtikelInfoPendaftaran v-for="(info,idx) in dataInformasi" :key="idx" :data-informasi="info"></ArtikelInfoPendaftaran>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import ArtikelInfoPendaftaran from "@/components/views/artikel/ArtikelInfoPendaftaran";
import LoadSpinner from "@/components/LoadSpinner";
export default {
  name: "InformasiPendaftaran",
  components:{LoadSpinner, ArtikelInfoPendaftaran, BreadCrumb},
  data:()=>({
    dataInformasi: [],
    loading: false,
    message: '',
    success: false
  }),
  methods:{
    getInformasi(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.accountData;
      let path = '/gemala/info/getinformasipsb';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataInformasi = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getInformasi();
  }
}
</script>
//getPeriode
<style scoped>

</style>