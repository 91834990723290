<template>
  <ParentIndex :fromlogout="true"></ParentIndex>
</template>

<script>
import ParentIndex from "@/components/ParentIndex";
export default {
  name: "PageLogout",
  components: {ParentIndex}
}
</script>

<style scoped>

</style>