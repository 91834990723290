<template>
  <HeaderParent></HeaderParent>
  {{ $route.params.pagename }}
  <PagePendaftaran></PagePendaftaran>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderParent from "@/components/HeaderParent";
import FooterWeb from "@/components/FooterWeb";
import PagePendaftaran from "@/components/views/PagePendaftaran";
export default {
  name: "RoutePendaftaran",
  computed:{
  },
  methods:{
  },
  components: {
    PagePendaftaran,
    FooterWeb,
    HeaderParent
  },
  beforeMount() {
  }
}
</script>

<style scoped>

</style>