<template>
  <HeaderParent></HeaderParent>
  <PageJadwalWawancaraPsikologi v-if="page==='wawancara-psikologi'"></PageJadwalWawancaraPsikologi>
  <PageJadwalTestKognitif v-if="page==='test-kognitif'"></PageJadwalTestKognitif>
  <PageJadwalKelasPercobaan v-if="page==='kelas-percobaan'"></PageJadwalKelasPercobaan>
  <PageJadwalWawancaraOrangTua v-if="page==='wawancara-orangtua'"></PageJadwalWawancaraOrangTua>

</template>

<script>
import HeaderParent from "@/components/HeaderParent";
import PageJadwalWawancaraPsikologi from "@/components/views/jadwal/PageJadwalWawancaraPsikologi";
import PageJadwalTestKognitif from "@/components/views/jadwal/PageJadwalTestKognitif";
import PageJadwalKelasPercobaan from "@/components/views/jadwal/PageJadwalKelasPercobaan";
import PageJadwalWawancaraOrangTua from "@/components/views/jadwal/PageJadwalWawancaraOrangTua";
export default {
  name: "routerJadwalPsb",
  components: {
    PageJadwalWawancaraOrangTua,
    PageJadwalKelasPercobaan, PageJadwalTestKognitif, PageJadwalWawancaraPsikologi, HeaderParent},
  data:()=>({
    page: '',
    pageAllow : ['wawancara-psikologi','test-kognitif','kelas-percobaan','wawancara-orangtua']
  }),
  watch:{
    '$route.params.page':{
      immediate: true,
      deep: false,
      handler(){
        this.page = this.$route.params.page.toLowerCase();
        if(this.pageAllow.includes(this.page)){
          //this.$store.commit('tipepsb',this.page);
        }else{
          this.page = 'wawancara-psikologi';
        }
      }
    }
  }
}
</script>

<style scoped>

</style>