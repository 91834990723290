import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import App from './App.vue'
import router from './router'
import store from './store/index'
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Lara from '@primevue/themes/lara';
const app = createApp(App);
    app.use(router)
        .use(store)
        .use(PrimeVue,{
            theme: {
                preset: Lara,
                options: {
                    prefix: 'p',
                    darkModeSelector: '.my-app-dark',
                    cssLayer: false
                }
            }
        })
        .use(ToastService)
        .use(ConfirmationService)
        .mount('#app')
