<template>
    <BreadCrumb v-if="showgrid" page="Beranda > Pendaftaran > Data Wawancara Psikolog > Data Jadwal Wawancara Psikolog"></BreadCrumb>
    <div class="container content-area" v-if="showgrid">
      <div class="row">
        <div class="col-md-12">
          <h3 class="judul-seksi-sga">Data Jadwal Wawancara Psikolog</h3></div>
      </div>
      <div class="" style="padding-top: 20px;">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <DataTable v-model:filters="filters" :value="dataWawancara" ref="dt" tableStyle="min-width: 50rem"
                         paginator :rows="10"
                         :rowsPerPageOptions="[5, 10, 20, 50]"
                         :first="0"
                         :loading="loading"
              >
                <template #empty> Data tidak ada. </template>
                <template #loading> Mengambil data. Mohon tunggu. </template>
                <Column field="no_formulir" header="Inv Id" sortable></Column>
                <Column field="biaya_konfirmasi" header="Tanda konfirmasi" sortable></Column>
                <Column field="nama_anak" header="Nama Anak" filterMatchMode="startsWith" sortable>
                </Column>
                <Column field="nama_ortu" header="Nama Ortu" sortable style="width: 25%"></Column>
                <Column field="jadwal_wawancara" header="Jadwal Wawancara" sortable></Column>
                <Column field="tanggal_wawancara" header="Tanggal Wawancara" sortable style="width: 25%"></Column>
                <Column>
                  <template #body="slotProps">
                    <a href="#" @click="toggleGrid(slotProps.data)" class="fa-solid fa-eye" v-if="slotProps.data.sudahbayarkonfirmasi"></a>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageUpdateJadwalWawancara :data="selectedFormulir" v-if="!showgrid"
                               @change-view="toogleviewshowgrid"
    ></PageUpdateJadwalWawancara>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from '@primevue/core/api';
import PageUpdateJadwalWawancara from "@/components/viewadmin/jadwalwawancarapsikolog/PageUpdateJadwalWawancara";
export default {
  name: "PageViewDataWawancara",
  components: {PageUpdateJadwalWawancara, BreadCrumb,DataTable,Column},
  data:()=>({
    loading: false,
    lazyParams: {},
    showgrid: true,
    response:'',
    message:'',
    success: false,
    dataWawancara:[],
    selectedFormulir:[],
    totalRecords: 4,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'no_formulir': {value: '', matchMode: FilterMatchMode.CONTAINS},
      'nama_anak': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'nama_ortu': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'jadwal_wawancara': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'tanggal_wawancara': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
    },
  }),
  methods:{
    toogleviewshowgrid(){
      this.showgrid = true;
    },
    toggleGrid(data){
      this.showgrid = !this.showgrid;
      this.selectedFormulir = data;
    },
    generatelink(invchild){
      return "/admin/data/view-formulir-psb/"+invchild;
    },
    getDataWawancaraPsikolog(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataWawancaraPsikolog';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataWawancara = this.response.data.kebutuhankhusus;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.lazyParams = {
      first: this.$refs.dt.first,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };
    this.getDataWawancaraPsikolog();
  }
}
</script>

<style scoped>

</style>