<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Tes Kognitif > Data Hasil Tes Kognitif > Ubah data"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Data Hasil Tes Kognitif - {{data.nama_anak}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12"><hr></div>
    </div>
    <div class="form-general">
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-12 spacer-field-sga"><normal-label>No formulir</normal-label><br/>
              <normal-label2>{{data.no_formulir}}</normal-label2>
            </div>
            <div class="col-12 spacer-field-sga"><normal-label>Nama Ananda </normal-label><br/>
              <normal-label2>{{data.nama_anak}}</normal-label2></div>
            <div class="col-12 spacer-field-sga"><normal-label>Nama Orang Tua </normal-label>
              <ul>
                <li><normal-label2>{{data.nama_ayah}}</normal-label2></li>
                <li><normal-label2>{{data.nama_ibu}}</normal-label2></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-12"><h5 style="color: darkorange;">Ringkasan Hasil Tes</h5>
            </div>
            <div class="col-md-8"><label>Comprehension Knowledge (Gc)</label></div>
            <div class="col-md-4"><input v-model="dataringkasan.gc" type="text"></div>
            <div class="col-md-8"><label>Fluid Reasoning (Gf)</label></div>
            <div class="col-md-4"><input v-model="dataringkasan.gf" type="text"></div>
            <div class="col-md-8"><label>Working Memory (Gwm)</label></div>
            <div class="col-md-4"><input v-model="dataringkasan.gwm" type="text"></div>
            <div class="col-md-8"><label>Retrieval Fluency (Gr)</label></div>
            <div class="col-md-4"><input v-model="dataringkasan.gr" type="text"></div>
            <div class="col-md-8"><label>Auditory Processing (Ga)</label></div>
            <div class="col-md-4"><input v-model="dataringkasan.ga" type="text"></div>
            <div class="col-md-8"><label>Visual Processiong (Gv)</label></div>
            <div class="col-md-4"><input v-model="dataringkasan.gv" type="text"></div>
            <div class="col-md-8"><label>Processiong Speed (Gs)</label></div>
            <div class="col-md-4"><input v-model="dataringkasan.gs" type="text"></div>
            <div class="col-md-8"><label><strong>General Cognitive Ability (GCA)</strong></label></div>
            <div class="col-md-4"><input v-model="dataringkasan.gca" type="text"></div>
          </div>
          <div class="row"><div class="col-12 spacer-field-sga  w-100"><label>Catatan hasil tes kognitif</label> </div>
            <div class="col-12  spacer-field-sg">
              <textarea v-model="catatan" class="form-control textarea-sga" id="exampleFormControlTextarea1" rows="4" required=""></textarea>
            </div>
          </div><hr>
          <div class="row">
            <div class="col-12">
              <label>Dokumen wawancara : </label>
              <div class="custom-file">
                <FileLister v-if="!loading" :filedata="filelist.list" :num-visible="filelist.size"></FileLister>
                <FileUpload v-if="showupload" :class="classupload1" :fileLimit="1" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel1" @before-send="beforeUploadSatu" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="5000000" @progress="onProgressSatu" @upload="onUploadSatu" @select="onSelectSatu"></FileUpload>
                <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg atau .pdf</keterangan-form>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12"><hr></div>
          </div>
          <div class="row">
            <div class="col-12">
              <normal-label>Status Rekomendasi : <select v-model="statuspelaksanaan" name="status-pelaksanaan-penilaian" class="sga-status-dropdown" >
                <option value="Belum ada rekomendasi">Belum ada rekomendasi </option>
                <option value="Tidak diterima">Tidak diterima</option>
                <option value="Lanjut kelas coba belajar">Lanjut Kelas Coba Belajar</option>
              </select>
              </normal-label>
              <div class="col-12 button-area-sga text-right">
                <button @click="updateHasilTesKognitif" class="btn btn-sm btn-success" type="button"> Simpan catatan</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileLister from "@/components/FileLister";
import BreadCrumb from "@/components/BreadCrumb";
import FileUpload from "primevue/fileupload";
import DashboardServices from "@/services/dashboard.services";

export default {
  name: "PageUpdateHasilTesKognitif",
  components: {FileLister, BreadCrumb,FileUpload},
  props:{
    data: undefined
  },
  data:()=>({
    loading: false,
    response:'',
    message:'',
    success: false,
    namastatus:'',
    tanggal:'',
    waktu:'',
    showupload: true,
    classupload1:'',
    urlupload:'',
    uploadLabel1:'',
    statuspelaksanaan:'',
    catatan:'',
    dataringkasan:{
      gc:'',
      gf:'',
      gwm:'',
      gr:'',
      ga:'',
      gv:'',
      gs:'',
      gca:''
    },
    filelist:[],
  }),
  emits: ['change-view'],
  methods:{
    toggleview(){
      this.$emit('change-view',true)
    },
    beforeUploadSatu(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','testkognitif');
      request.formData.append('invid',this.data.invcode);
      return request;
    },
    onProgressSatu(event){
      this.isupload1 = true;
      this.uploadprogress1 = event.progress;
    },
    onUploadSatu(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.classupload1='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload1='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload1 = false;
    },
    onSelectSatu(event){
      if(event.files.length >0){
        //this.uploadLabel1 = event.files[0].name;
      }
    },
    setup(){
      this.catatan = this.data.catatan_kegiatan;
      this.statuspelaksanaan = this.data.sts_kegiatan;
      this.dataringkasan = this.data.datalain;
      this.statuspelaksanaan = this.data.rekomendasi;
    },
    updateHasilTesKognitif(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        'idjadwal': this.data.jadwal_id,
        'status': this.statuspelaksanaan,
        'catatan': this.catatan,
        'ringkasan': this.dataringkasan
      };
      let path = '/dashboard/updateHasilKegiatan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paramname:this.data.invcode,
        filecat:'testkognitif'
      };
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              if (typeof this.response.data.testkognitif !== 'undefined') {
                this.filelist = this.response.data.testkognitif;
              }
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.getDataUploadedFile();
    this.setup();
  }
}
</script>

<style scoped>

</style>