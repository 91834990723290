<template>
  <BreadCrumb v-if="showgrid" page="Beranda > Pendaftaran > Catatan Diskusi > Data Catatan Diskusi"></BreadCrumb>
  <div class="container content-area" v-if="showgrid">
    <div class="row">
      <div class="col-md-12">
        <h3 class="judul-seksi-sga">Data Catatan Diskusi</h3>
      </div>
    </div>
    <div class="" style="padding-top: 20px;">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <Toolbar class="mb-4">
              <template #end>
                <Button label="Export" icon="pi pi-upload" severity="help" @click="exportCSV($event)"  />
              </template>
            </Toolbar>
            <DataTable v-model:filters="filters" :value="dataCatatanDiskusi" ref="dt" tableStyle="min-width: 50rem"
                       paginator :rows="10"
                       :rowsPerPageOptions="[5, 10, 20, 50]"
                       :first="0"
                       :loading="loading"
            >
              <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                </div>
              </template>
              <template #empty> Data tidak ada. </template>
              <template #loading> Mengambil data. Mohon tunggu. </template>
              <Column field="naked_invid" header="No formulir" sortable></Column>
              <Column field="anak_nama" header="Nama Anak" filterMatchMode="startsWith" sortable>
              </Column>
              <Column field="ortu_nama" header="Nama Ortu" sortable ></Column>
              <Column field="status_rekomendasi" header="Rekomendasi" sortable></Column>
              <Column>
                <template #body="slotProps">
                  <a href="#" @click="toggleGrid(slotProps.data)" class="fa-solid fa-eye"></a>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PageUpdateCatatanDiskusi
      :data="selectedFormulir"
      v-if="!showgrid"
      @change-view="toogleviewshowgrid"
  ></PageUpdateCatatanDiskusi>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import {FilterMatchMode} from "@primevue/core/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import DashboardServices from "@/services/dashboard.services";
import PageUpdateCatatanDiskusi from "@/components/viewadmin/catatandiskusi/PageUpdateCatatanDiskusi";
import Toolbar from 'primevue/toolbar';
import InputText from "primevue/inputtext";
import Button from 'primevue/button';
export default {
  name: "ViewListAnakCatatanDiskusi",
  components: {PageUpdateCatatanDiskusi, BreadCrumb,DataTable,Column,Toolbar,InputText,Button},
  data:()=>({
    loading: false,
    lazyParams: {},
    showgrid: true,
    response:'',
    message:'',
    success: false,
    dataCatatanDiskusi:[],
    selectedFormulir:[],
    totalRecords: 4,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'no_formulir': {value: '', matchMode: FilterMatchMode.CONTAINS},
      'nama_anak': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'nama_ortu': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'jadwal_wawancara': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'tanggal_wawancara': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
    },
  }),
  methods:{
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    toogleviewshowgrid(){
      this.showgrid = true;
    },
    toggleGrid(data){
      this.showgrid = !this.showgrid;
      this.selectedFormulir = data;
    },
    getDataCatatanDiskusi(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataCatatanDiskusi';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataCatatanDiskusi = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataCatatanDiskusi();
  }
}
</script>

<style scoped>

</style>