<template>
  <li class="dropdown-submenu">
    <a class="dropdown-item dropdown-toggle" href="#">{{ title }}</a>
    <ul :class="menuclass">
      <li>
        <a v-for="(mn,idx) in menus" :key="idx" class="dropdown-item" :href="mn.menulink">{{mn.title}}</a>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: "DropDownMenu",
  props:{
    show: Boolean,
    title: String,
    menus:Object
  },
  computed:{
    menuclass(){
      if(this.show){
        return 'dropdown-menu show';
      }else {
        return 'dropdown-menu';
      }
    },
  }
}
</script>

<style scoped>

</style>