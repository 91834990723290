<template>
  <BreadCrumb v-if="showgrid" page="Beranda > Pendaftaran > Data Diskon > Lihat Data Diskon"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-12">
        <h3 class="judul-seksi-sga">Access Role</h3></div>
    </div>
  </div>
  <div class="" style="padding-top: 20px;">
    <div class="row">
      <div class="col-12">
        <Select v-model="selectedGroup" @change="loadSavedGroup" :options="groups" optionLabel="nama_group" placeholder="Select user group" checkmark :highlightOnSelect="false" class="w-full md:w-56" />
        <div class="card">
          <TreeTable v-model:selectionKeys="selectedKey" :value="menus" tableStyle="min-width: 50rem" selectionMode="checkbox">
            <Column field="label" header="Name" expander style="width: 34%"></Column>
            <Column field="id" header="ID" style="width: 33%"></Column>
            <Column field="path" header="Path" style="width: 33%"></Column>
          </TreeTable>
        </div>
        <div class="col-12 button-area-sga text-right"><button type="button" @click="saveListGroup" class="btn btn-md btn-success"><i
            class="fa-solid fa-caret-right"></i> Simpan</button></div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';
import BreadCrumb from "@/components/BreadCrumb";
import Select from 'primevue/select';
export default {
  name: "PageAccessMenu",
  components:{TreeTable,Column,BreadCrumb,Select},
  data:()=>({
    success: false,
    successtrx: false,
    loading: false,
    message:'',
    messagetrx:'',
    error:'',
    menus: [],
    groups:[],
    selectedKey: [],
    selectedGroup:''
  }),
  methods:{
    loadSavedGroup(){
      this.loading = true;
      this.selectedKey= [],
      this.$store.commit('loading',this.loading);
      let data = {
        gid:this.selectedGroup,
      };
      let path = '/dashboard/loadsavedgroupsmenu';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.selectedKey = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    saveListGroup(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        gid:this.selectedGroup,
        list: this.selectedKey
      };
      let path = '/dashboard/saveusergroups';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.message = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getAdminMenu(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/getListAccessMenu';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.menus = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getListGroup(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/getlistusergroup';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.groups = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getAdminMenu();
    this.getListGroup();
  },
}
</script>

<style scoped>

</style>