<template>
  <Bread-crumb page="Beranda > Pendaftaran > Tanda Konfirmasi"></Bread-crumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Pembayaran Tanda Konfirmasi</h3>
        <hr>
        <p>Lakukan pembayaran tanda konfirmasi sebesar Rp. 3.000.000,- untuk Ananda berikut ini, sebagai syarat
          kelengkapan Pendaftaran Siswa Baru (PSB) Sekolah Gemala Ananda</p>
        <p>Anda akan dialihkan ke laman pembayaran uang sekolah melalui payment gateway E2Pay.</p>
        <div class="form-general">
          <div class="row">
            <div class="col-12 text-left">
              <div class="table-data-sga">
                <div class="table-responsive">
                  <TableDataAnak :ispembayaran="true" :datapendaftaran="dataPendaftaran" buttontitle="Bayar tanda konfirmasi" routepath="/gemala/psb/bayar-biaya-konfirmasi/" :withbutton=true></TableDataAnak>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableDataAnak from "@/components/views/psb/part/TableDataAnak";
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "PageBiayaKonfirmasi",
  components: {TableDataAnak},
  data: () => ({
    dataPendaftaran:[],
    loading: false
  }),
  methods:{
    getDataPendaftaran(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/getDataPendaftaran';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  beforeMount() {
    this.getDataPendaftaran();
  }
}
</script>

<style scoped>

</style>