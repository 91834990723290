<template>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Data Formulir Berasa - {{data.data.anak_nama}} no formulir PSB {{data.data.inv_child}}</h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-12"><h4>Keterangan Formulir Berasa</h4></div>
    </div>
    <div class="group-sga">
      <div class="row">
        <div class="col-md-4 spacer-field-sga">
          <normal-label>Nama lengkap Ananda</normal-label>
        </div>
        <div class="col-md-8 spacer-field-sga">{{data.data.anak_nama}}</div>
        <div class="col-md-4 spacer-field-sga">
          <normal-label>Nama Orang Tua (Anda)</normal-label>
        </div>
        <div class="col-md-8 spacer-field-sga">{{data.data.nama_ortu}}</div>

        <div class="col-md-4 spacer-field-sga">
          <normal-label>Status hubungan</normal-label>
        </div>
        <div class="col-md-8 spacer-field-sga">{{data.data.tipe_pengisi}}</div>
      </div>
    </div>
    <hr>
    <div class="group-sga">
      <div class="row">
        <div class="col-12"><h4>Topik 1</h4></div>
      </div>
      <div class="row">
        <div class="col-12">
          <normal-label-nilai>Nilai apakah yang ditanamkan orang tua Anda kepada Anda, yang sekarang Anda
            terapkan dalam kehidupan sehari-hari?
          </normal-label-nilai>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Nilai</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikSatu.jawaban1" class="w-100 form-control" required></div>
        <div class="col-md-4 spacer-field-sga"><label>Mengapa nilai tersebut Anda anggap penting untuk
          diterapkan dalam kehidupan Anda?</label></div>
        <div class="col-md-8 spacer-field-sga">
          <textarea :value="data.formulir.formulir.topikSatu.jawaban2" class="form-control textarea-sga" id="exampleFormControlTextarea1" rows="4" required></textarea>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Ceritakan satu peristiwa spesifik dalam kurun waktu satu
          tahun terakhir, di mana Anda menerapkan nilai tersebut dalam kehidupan sehari-hari.</label>
        </div>
        <div class="col-md-8 spacer-field-sga">
                    <textarea class="form-control textarea-sga" :value="data.formulir.formulir.topikSatu.jawaban3" rows="4"
                              required></textarea>
        </div>
      </div>
    </div>
    <hr>
    <div class="group-sga">
      <div class="row">
        <div class="col-12"><h4>Topik 2</h4></div>
      </div>

      <div class="row">
        <div class="col-12">
          <normal-label-nilai>Apakah nilai lain yang menurut Anda pribadi juga penting dalam mendidik anak?
          </normal-label-nilai>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Nilai</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikDua.jawaban1" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Mengapa nilai tersebut Anda anggap penting untuk
          diterapkan dalam kehidupan Anda?</label></div>
        <div class="col-md-8 spacer-field-sga">
                    <textarea class="form-control textarea-sga" :value="data.formulir.formulir.topikDua.jawaban2" rows="4"
                              required></textarea>


        </div>
        <div class="col-md-4 spacer-field-sga"><label>Ceritakan satu peristiwa spesifik dalam kurun waktu satu
          tahun terakhir, di mana Anda menerapkan nilai tersebut dalam kehidupan sehari-hari.</label>
        </div>
        <div class="col-md-8 spacer-field-sga">
                    <textarea class="form-control textarea-sga" :value="data.formulir.formulir.topikDua.jawaban3" rows="4"
                              required></textarea>
        </div>
      </div>

    </div>
    <hr>

    <div class="group-sga">
      <div class="row">
        <div class="col-12"><h4>Topik 3</h4></div>
      </div>
      <div class="row">
        <div class="col-12">
          <normal-label-nilai> Dalam kurun waktu satu tahun ini, adakah peristiwa spesifik di mana Anda
            mengalah kepada orang lain atau kesabaran Anda diuji? Apa peristiwanya, apa yang Anda lakukan,
            dan bagaimana hasilnya? Ceritakanlah dengan menggunakan format di bawah ini.
          </normal-label-nilai>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Judul peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikTiga.jawaban1" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Waktu dan durasi peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikTiga.jawaban2" class="w-100 form-control" required>
        </div>


        <div class="col-md-4 spacer-field-sga"><label>Latar belakang peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga">
                    <textarea class="form-control textarea-sga" :value="data.formulir.formulir.topikTiga.jawaban3" rows="4"
                              required></textarea>


        </div>
        <div class="col-md-4 spacer-field-sga"><label>Orang-orang yang terlibat</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikTiga.jawaban4" class="w-100 form-control" required>
        </div>

        <div class="col-md-4 spacer-field-sga"><label>Hasil akhir dari peristiwa ini</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikTiga.jawaban5" class="w-100 form-control" required>
        </div>
        <div class="col-12 spacer-field-sga">

        </div>

        <div class="col-md-4 spacer-field-sga">
          <label>Secara berurutan, langkah-langkah penting apa yang Anda lakukan untuk mencapainya</label>
          <p style="margin-top: 15px;"><strong>Catatan:</strong>
          <ul>
            <li>Pastikan Anda menggunakan kata “saya” ketika Anda sendirilah yang melakukan hal tersebut
            </li>
            <li> minimal 350 kata</li>
            <li> maksimal 500 kata</li>
          <li> Jumlah terisi {{data.topik3}}</li>
          </ul>
          </p>
        </div>
        <div class="col-md-8 spacer-field-sga">
                    <textarea class="form-control textarea-sga" :value="data.formulir.formulir.topikTiga.jawaban6" rows="9"
                              required></textarea>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Apa perasaan tentang hasilnya? Mengapa?</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikTiga.jawaban7" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Pelajaran/hikmah apa yang Anda petik dari peristiwa
          ini?</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikTiga.jawaban8" class="w-100 form-control" required>
        </div>
      </div>
    </div>
    <hr>
    <div class="group-sga">
      <div class="row">
        <div class="col-12"><h4>Topik 4</h4></div>
      </div>
      <div class="row">
        <div class="col-12">
          <normal-label-nilai>Dalam kurun waktu satu tahun terakhir ini, pernahkah Anda berada dalam situasi
            di mana anak Anda menunjukkan masalah/perilaku yang Anda anggap berat? Apa peristiwanya, apa
            yang Anda lakukan, dan bagaimana hasilnya? Ceritakanlah dengan menggunakan format di bawah ini.
          </normal-label-nilai>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Judul peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikEmpat.jawaban1" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Waktu dan durasi peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikEmpat.jawaban2" class="w-100 form-control" required>
        </div>


        <div class="col-md-4 spacer-field-sga"><label>Latar belakang peristiwa</label></div>
        <div class="col-md-8 spacer-field-sga">
                    <textarea class="form-control textarea-sga" :value="data.formulir.formulir.topikEmpat.jawaban3" rows="4"
                              required></textarea>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Orang-orang yang terlibat</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikEmpat.jawaban4" class="w-100 form-control" required>
        </div>

        <div class="col-md-4 spacer-field-sga"><label>Hasil akhir dari peristiwa ini</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikEmpat.jawaban5" class="w-100 form-control" required>
        </div>
        <div class="col-12 spacer-field-sga">

        </div>

        <div class="col-md-4 spacer-field-sga">
          <label>Secara berurutan, langkah-langkah penting apa yang Anda lakukan untuk mencapainya</label>

          <p style="margin-top: 15px;"><strong>Catatan:</strong>
          <ul>
            <li>Pastikan Anda menggunakan kata “saya” ketika Anda sendirilah yang melakukan hal tersebut
            </li>
            <li> minimal 350 kata</li>
            <li> maksimal 500 kata</li>
          <li> Jumlah terisi {{data.topik4}}</li>
          </ul>
          </p>
        </div>
        <div class="col-md-8 spacer-field-sga">
                    <textarea class="form-control textarea-sga" :value="data.formulir.formulir.topikEmpat.jawaban6" rows="9"
                              required></textarea>
        </div>

        <div class="col-md-4 spacer-field-sga"><label>Apa perasaan tentang hasilnya? Mengapa?</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikEmpat.jawaban7" class="w-100 form-control" required>
        </div>
        <div class="col-md-4 spacer-field-sga"><label>Pelajaran/hikmah apa yang Anda petik dari peristiwa
          ini?</label></div>
        <div class="col-md-8 spacer-field-sga"><input type="text" :value="data.formulir.formulir.topikEmpat.jawaban8" class="w-100 form-control" required>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "PageViewIsiBerasa",
  props: {
    data: undefined
  },
  components: {},
  methods:{
    toggleview(){
      this.$emit('change-view',true)
    },
  }
}
</script>

<style scoped>

</style>