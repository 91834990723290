<template>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Data Hasil Penilaian - {{data.anak_nama}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a></div>
      <div class="col-12"><hr></div>
    </div>
    <div class="notes-notification-sga">
      <p><strong>Catatan :</strong><br>Mengubah status pelaksanaan penilaian menjadi selesai, akan mengaktifkan email notifikasi ke orang tua untuk memulai pengisian formulir Berasa</p></div>
    <div class="row">
      <div class="col-12">
        <div class="form-general">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4 spacer-field-sga"><normal-label>No formulir </normal-label></div>
                <div class="col-md-8 spacer-field-sga">{{data.inv_child}}</div>
                <div class="col-md-4 spacer-field-sga"><normal-label>Nama Ananda </normal-label></div>
                <div class="col-md-8 spacer-field-sga">{{data.anak_nama}}</div>
                <div class="col-md-4 spacer-field-sga"><normal-label>Tanggal penilaian </normal-label></div>
                <div class="col-md-8 spacer-field-sga">{{data.hari}}, {{data.tanggalid}}</div>
              </div>
            </div>
            <div class="col-md-6">
              <LoadSpinner v-if="loading"></LoadSpinner>
              <div class="row" v-else>
                <div class="col-12">
                  <normal-label>Status Pelaksanaan : <select v-model="statuspelaksanaan" class="sga-status-dropdown" >
                    <option value="Terjadwal">Terjadwal</option>
                    <option value="JadwalUlang">Jadwal ulang</option>
                    <option value="Selesai">Selesai</option>
                  </select></normal-label>
                  <button type="button" @click="updateHasilKelasPercobaan" class="btn btn-sm btn-success ml-3">Simpan</button>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-12">
                  <normal-label>Catatan PIC: </normal-label>
                </div>
                <div class="col-12  spacer-field-sg">
                  <textarea v-model="catatan" class="form-control textarea-sga" id="exampleFormControlTextarea1" rows="9" required=""></textarea>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-12">
                  <label>Dokumen penilaian : </label>
                  <div class="custom-file">
                    <FileLister v-if="!loading" :filedata="filelist.list" :num-visible="filelist.size"></FileLister>
                    <FileUpload v-if="showupload" :class="classupload1" :fileLimit="2" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel1" @before-send="beforeUploadSatu" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="5000000" @progress="onProgressSatu" @upload="onUploadSatu" @select="onSelectSatu">
                      <template #empty>
                        <p>Drag and drop files to here to upload.</p>
                      </template>
                    </FileUpload>
                    <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg atau .pdf</keterangan-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import "primevue/resources/themes/lara-light-indigo/theme.css";
import FileUpload from 'primevue/fileupload';
import DashboardServices from "@/services/dashboard.services";
import LoadSpinner from "@/components/LoadSpinner";
import FileLister from "@/components/FileLister";
export default {
  name: "PageUpdateHasilPenilaian",
  components:{FileLister, LoadSpinner, FileUpload},
  emits: ['change-view'],
  props:{
    data:undefined
  },
  data:()=>({
    loading: false,
    showupload: true,
    classupload1:'',
    urlupload:'',
    uploadLabel1:'',
    catatan:'',
    message:'',
    response:'',
    success:'',
    messagetrx:'',
    filelist:[],
    successtrx:false,
    statuspelaksanaan:'',
    timid:'',
    dataAssessor:[],
  }),
  methods:{
    toggleview(){
      this.$emit('change-view',true)
    },
    beforeUploadSatu(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','kelaspercobaan');
      request.formData.append('invid',this.data.invcode);
      return request;
    },
    onProgressSatu(event){
      this.isupload1 = true;
      this.uploadprogress1 = event.progress;
    },
    onUploadSatu(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.classupload1='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload1='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload1 = false;
    },
    onSelectSatu(event){
      if(event.files.length >0){
        //this.uploadLabel1 = event.files[0].name;
      }
    },
    updateHasilKelasPercobaan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        'idjadwal': this.data.jadwal_id,
        'status': this.statuspelaksanaan,
        'catatan': this.catatan
      };
      let path = '/dashboard/updateHasilKegiatan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.filelist = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paramname:this.data.invcode,
        filecat:'kelaspercobaan'
      };
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              if (typeof this.response.data.kelaspercobaan !== 'undefined') {
                this.filelist = this.response.data.kelaspercobaan;
              }
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.catatan = this.data.catatan_kegiatan;
    this.statuspelaksanaan = this.data.sts_pelaksanaan;
    this.getDataUploadedFile();
  }
}
</script>

<style scoped>

</style>