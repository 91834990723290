<template>
  <div class="row">
    <div class="col-12">
      <div class="table-data-sga">
        <div class="table-responsive">
          <table class="table table-striped ">
            <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Formulir</th>
              <th scope="col">Tanggal</th>
              <th scope="col">Nama ananda</th>
              <th scope="col">Nama orang tua</th>
              <th scope="col">Jenis</th>
              <th scope="col" v-if="withharga">Biaya</th>
              <th scope="col">Biaya pendaftaran</th>
              <th scope="col">Tanda konfirmasi</th>
              <th scope="col" v-if="withbutton">Status</th>
            </tr>
            </thead>
            <tbody v-if="ispembayaran">
            <tr v-for="(anak,idx) in datapendaftaran" :key="idx">
              <td>{{++idx}}</td>
              <td>{{anak.childinvid}}</td>
              <td>{{anak.tgl}}</td>
              <td>{{anak.namaanak}}</td>
              <td>{{anak.namaortu}}</td>
              <td>{{anak.jenisdaftar}}</td>
              <td v-if="withharga">{{anak.hargaformulir}}</td>
              <td>{{anak.biayapendaftaran}}</td>
              <td>{{anak.biayakonfirmasi}}</td>
              <td v-if="withbutton">
                <router-link v-if="anak.flagBiayaKonfirmasi===0" :to=generateLink(anak.invchild) class="btn btn-sm btn-success"><i
                    class="fa-solid fa-caret-right"></i> {{buttontitle}}</router-link>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr v-for="(anak,idx) in datapendaftaran" :key="idx">
              <td>{{++idx}}</td>
              <td>{{anak.childinvid}}</td>
              <td>{{anak.tgl}}</td>
              <td>{{anak.namaanak}}</td>
              <td>{{anak.namaortu}}</td>
              <td>{{anak.jenisdaftar}}</td>
              <td v-if="withharga">{{anak.hargaformulir}}</td>
              <td>{{anak.biayapendaftaran}}</td>
              <td>{{anak.biayakonfirmasi}}</td>
              <td v-if="withbutton">
                <template v-if="anak.submitpsb">
                  <span v-if="!anak.needtambahan" class="text-uppercase">{{anak.statusFormulir}}</span>
                  <router-link v-else :to=generateLink(anak.invchild) class="btn btn-sm btn-success"><i
                      class="fa-solid fa-caret-right"></i> Lengkapi formulir</router-link>
                </template>
                <template v-else>
                <router-link v-if="anak.flagBiayaFormulir===1" :to=generateLink(anak.invchild) class="btn btn-sm btn-success"><i
                    class="fa-solid fa-caret-right"></i> {{buttontitle}}</router-link>
                <span v-else>Silakan Lakukan Pembayaran biaya formulir</span>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableDataAnak",
  props: {
    datapendaftaran:undefined,
    buttontitle: String,
    routepath: String,
    withbutton: Boolean,
    withharga: Boolean,
    ispembayaran: Boolean
  },
  computed:{
  },
  methods:{
    generateLink(id){
      return this.routepath+id;
    }
  }
}
</script>

<style scoped>

</style>