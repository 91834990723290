<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">
      <div class="logo-sga"><img :src="showLogo" class="img-fluid"></div>
    </a>
    <button @click="togglemenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div :class="menuclass" id="navbarNavDropdown">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link to="/home" class="nav-link">Beranda <span class="sr-only">(current)</span></router-link>
        </li>
        <li class="nav-item">
          <router-link v-if="$store.state.loggedIn" to="/gemala/pendaftaran" class="nav-link">Pendaftaran</router-link>
        </li>
      </ul>
      <div class="my-2 my-lg-0">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item ">
            <router-link v-if="loggedIn" to="/gemala/account" style="color: green;"><i class="fa-solid fa-user"></i> {{namapengguna}}</router-link>
            <router-link v-else to="/login" style="color: green;"><i class="fa-solid fa-right-to-bracket"></i> Login</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container-fluid custom-progress-bar-sga">
    <div class="row">
      <div class="col-12">
        <ProgressBar v-if="isLoading" mode="indeterminate" style="height: 6px"></ProgressBar>
        <!-- @detected-condition fires when the connectivity status of the device changes -->
        <v-offline @detected-condition="onNetworkChange">
          <template v-if="!online">
            <div class="alert alert-danger" role="alert">
              (Offline) Mohon cek koneksi internet anda!.
            </div>
          </template>
        </v-offline>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from 'primevue/progressbar';
import { VOffline } from 'v-offline';
import router from "@/router";
export default {
  name: "HeaderParent",
  components:{VOffline, ProgressBar},
  data:()=>({
    online:true,
    menuopen: false,
    logo: require('@/assets/images/logo-sga.png'),
    logoAnimated: require('@/assets/images/logo-sga-animated2.gif'),
    namapengguna:'',
    loggedIn: false,
  }),
  computed:{
    menuclass(){
      if(this.menuopen){
        return 'collapse navbar-collapse show';
      }else {
        return 'collapse navbar-collapse';
      }
    },
    isLoading(){
      return this.$store.state.loading;
    },
    showLogo(){
        return this.logo;
    },
  },
  methods:{
    onNetworkChange(status){
      this.online = status;
    },
    togglemenu(){
      this.menuopen = !this.menuopen
    },
    checkUserData(){
      const userData = localStorage.getItem('usergemala');
      if(userData){
        try{
          const objData = JSON.parse(userData);
          if(objData !== null){
            this.loggedIn = true;
            this.namapengguna = objData.data.fullname;
            return objData.data.email;
          }else{
            router.push('/login');
          }
        }catch (e) {
          router.push('/login');
        }
      }else{
        this.loggedIn = false;
        return '';
      }
    }
  },
  beforeCreate: function() {
    document.body.style = 'padding-top: 70px';
  },
  mounted() {
    //this.checkLogin();
    this.checkUserData();
  }
}
</script>

<style scoped>
.custom-progress-bar-sga {position: sticky;top:60px; z-index: 1000;text-align: center;}
</style>