<template>
  <div class="row">
    <div class="col-12">
      <div class="table-data-sga">
        <div class="table-responsive">
          <table class="table table-striped ">
            <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Formulir</th>
              <th scope="col">Tanggal</th>
              <th scope="col">Nama Ananda</th>
              <th scope="col">Nama Orang Tua (Anda)</th>
              <th scope="col">Hubungan</th>
              <th scope="col">Status</th>
            </tr>
            </thead>
            <tbody >
            <tr v-for="(anak,idx) in data" :key="idx">
              <td>{{++idx}}</td>
              <td>{{anak.childinvid}}</td>
              <td>{{anak.tgl}}</td>
              <td>{{anak.namaanak}}</td>
              <td>{{anak.berasa_ortu}}</td>
              <td>{{anak.berasa_ortustatus}}</td>
              <td>{{anak.berasa_status}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabelDataAnakBerasa",
  props: {
    label: String,
    data:[],
    initdata: undefined,
    routepath: String,
    buttontitle: String
  },
  methods:{
    generateLink(id){
      return this.routepath+id;
    }
  }
}
</script>

<style scoped>

</style>