<template>
  <div class="wrapper-login-daftar-sga">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <div class="logo-login"><img @click="goHome" :src="logo" class="img-fluid" style="cursor: pointer;"></div>
          <p>Selamat datang di Sistem Sekolah Gemala Ananda. <br>Gunakan login ID Anda atau silahkan <router-link to="/pendaftaran-akun">buat
            akun</router-link>.</p>
          <div class="form-login-daftar">
            <form>
              <div class="row ">
                <div class="col-md-6 text-left">Email</div>
                <div class="col-md-6 text-left">
                  <input v-model="accountData.email" type="text" class="w-100 form-control">
                  <span class="keterangan-form text-danger" v-if="v$.accountData.email.$error">Mohon isi nama pengguna (Email)</span>
                </div>
                <div class="col-md-6 text-left spacer-field-sga">Kata sandi</div>
                <div class="col-md-6 text-left spacer-field-sga">
                  <input v-model="accountData.password" type="password" class="w-100 form-control">
                  <span class="keterangan-form text-danger" v-if="v$.accountData.password.$error">Mohon isi password</span>
                </div>
                <div class="col-md-12 text-right spacer-field-sga">
                  <AlertSuccess v-if="success" :message="message"></AlertSuccess>
                  <AlertDanger v-else :message="message"></AlertDanger>
                  <ButtonLoading v-if="loading"></ButtonLoading>
                  <button v-else class="btn btn-md btn-success" type="button" @click="submitLogin"><i class="fa-solid fa-caret-right"></i> Masuk</button>
                </div>
              </div>
            </form>
          </div>
          <p>Lupa kata sandi? <router-link to="/reset-password">setel ulang kata sandi</router-link>.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import ButtonLoading from "@/components/ButtonLoading";
import {alphaNum, email, minLength, required} from "@vuelidate/validators";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";
import DashboardServices from "@/services/dashboard.services";
import router from "@/router";
export default {
  name: "FormLogin",
  components: {ButtonLoading,AlertSuccess,AlertDanger},
  methods:{
    doClearForm(){
      this.accountData.email = '';
      this.accountData.password = '';
    },
    goHome(){
      router.push('/home');
    },
    submitLogin(){
      this.v$.accountData.$validate();
      if(!this.v$.accountData.$error){
        this.loading = true;
        this.$store.commit('loading',this.loading);
        let data = this.accountData;
        let path = '/api/login';
        DashboardServices.postData(data,path).then(
            (res)=>{
              let admin = [3,4,5,6,7,8,9,10,11,12,13,14];
              this.response = res.data;
              this.message = res.data.message;
              if(res.data.status===200){
                this.success = true;
                this.$store.commit('setperiode',res.data.tahunajaran);
                this.$store.commit('savesession',this.response);
                let resp = res.data.data;
                let role = resp.userRole;
                if(role===4){
                  role = 3;
                }
                if(role===3){
                  router.push('/admin/');
                }else if(admin.includes(parseInt(role))){
                  router.push('/admin/');
                } else{
                  router.push('/gemala/pendaftaran');
                }
              }else {
                this.success = false;
              }
              this.loading = false;
              this.$store.commit('loading',this.loading);
            }
        );
      }else{
        this.loading = false;
        this.$store.commit('loading',this.loading);
        this.message = 'Mohon isi data dengan benar.';
      }
    }
  },
  data:()=>({
    v$: useValidate(),
    logo: require('@/assets/images/logo-sga.png'),
    imgLoading: require('@/assets/images/loading.gif'),
    loading: false,
    response:'',
    message: '',
    success: false,
    accountData:{
      email:'',
      password:''
    }
  }),
  validations() {
    return {
      accountData: {
        email:{required,email},
        password:{required,alphaNum,minLength:minLength(8)},
      }
    }
  },
  beforeCreate: function() {
    document.body.style="";
    document.body.className = 'home';
  }
}
</script>

<style scoped>
body.home {
  padding-top: 0px!important;
}
</style>