<template>
  <HeaderAdmin></HeaderAdmin>
  <ViewDataPendaftaran v-if="page==='pengambilan-formulir'"></ViewDataPendaftaran>
  <ViewDataPengembalianFormulir v-if="page==='pengembalian-formulir'"></ViewDataPengembalianFormulir>
  <PageParameterTahunAjaran v-if="page==='setup-tahunajaran'"></PageParameterTahunAjaran>
  <PageViewTahunAjaran v-if="page==='view-tahunajaran'"></PageViewTahunAjaran>
  <PageViewFormulirPSB v-if="page==='view-formulir-psb'"></PageViewFormulirPSB>
  <PageViewDataWawancara v-if="page==='jadwal-wawancara-psikolog'"></PageViewDataWawancara>
  <PageHasilWawancaraPsikolog v-if="page==='hasil-wawancara-psikolog'"></PageHasilWawancaraPsikolog>
  <SetupKelasPercobaan v-if="page==='setup-kelas-percobaan'"></SetupKelasPercobaan>
  <SetupAssesor v-if="page==='setup-assessor'"></SetupAssesor>
  <SetupAssessorExternal v-if="page==='setup-tim-pewawancara'"></SetupAssessorExternal>
  <JadwalKelasPercobaan v-if="page==='lihat-jadwal-kelas-percobaan'"></JadwalKelasPercobaan>
  <ViewDataTestKognitif v-if="page==='jadwal-test-kognitif'"></ViewDataTestKognitif>
  <PageHasilTesKognitif v-if="page==='lihat-hasil-test-kognitif'"></PageHasilTesKognitif>
  <ViewDataBerasa v-if="page==='lihat-data-berasa'"></ViewDataBerasa>
  <ViewListAnakCatatanDiskusi v-if="page==='catatan-diskusi'"></ViewListAnakCatatanDiskusi>
  <ViewListAnakWawancara v-if="page==='lihat-jadwal-wawncara'"></ViewListAnakWawancara>
  <ViewDataHasilWawancaraOrtu v-if="page==='lihat-hasil-wawancara'"></ViewDataHasilWawancaraOrtu>
  <ViewRekomendasiPSB v-if="page==='lihat-data-rekomendasi-psb'"></ViewRekomendasiPSB>
  <ViewKeputusanPSB v-if="page==='lihat-data-keputusan-psb'"></ViewKeputusanPSB>
  <ViewDataPSBditerima  v-if="page==='lihat-psb-diterima'"></ViewDataPSBditerima>
  <ViewDataPSBtidakDiterima  v-if="page==='lihat-psb-tidak-diterima'"></ViewDataPSBtidakDiterima>
  <PageDataDiskon v-if="page==='lihat-data-diskon'"></PageDataDiskon>
  <PageAccessMenu v-if="page==='set-access-menu'"></PageAccessMenu>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderAdmin from "@/components/HeaderAdmin";
import ViewDataPendaftaran from "@/components/viewadmin/formulir/ViewDataPendaftaran";
import FooterWeb from "@/components/FooterWeb";
import ViewDataPengembalianFormulir from "@/components/viewadmin/formulir/ViewDataPengembalianFormulir";
import router from "@/router";
import PageParameterTahunAjaran from "@/components/viewadmin/tahunajaran/PageParameterTahunAjaran";
import PageViewTahunAjaran from "@/components/viewadmin/tahunajaran/PageViewTahunAjaran";
import PageViewFormulirPSB from "@/components/viewadmin/formulir/ViewFormulirPSB";
import PageViewDataWawancara from "@/components/viewadmin/jadwalwawancarapsikolog/ViewDataWawancara";
import SetupKelasPercobaan from "@/components/viewadmin/kelaspercobaan/SetupKelasPercobaan";
import SetupAssesor from "@/components/viewadmin/kelaspercobaan/SetupAssesor";
import JadwalKelasPercobaan from "@/components/viewadmin/kelaspercobaan/JadwalKelasPercobaan";
import SetupAssessorExternal from "@/components/viewadmin/kelaspercobaan/SetupAssessorExternal";
import PageHasilWawancaraPsikolog from "@/components/viewadmin/jadwalwawancarapsikolog/PageHasilWawancaraPsikolog";
import ViewDataTestKognitif from "@/components/viewadmin/jadwaltestkognitif/ViewDataTestKognitif";
import PageHasilTesKognitif from "@/components/viewadmin/jadwaltestkognitif/PageHasilTesKognitif";
import ViewDataBerasa from "@/components/viewadmin/berasa/ViewDataBerasa";
import ViewListAnakCatatanDiskusi from "@/components/viewadmin/catatandiskusi/ViewListAnakCatatanDiskusi";
import ViewListAnakWawancara from "@/components/viewadmin/wawancara/ViewListAnakWawancara";
import ViewDataHasilWawancaraOrtu from "@/components/viewadmin/wawancara/ViewDataHasilWawancaraOrtu";
import ViewRekomendasiPSB from "@/components/viewadmin/rekomendasipsb/ViewRekomendasiPSB";
import ViewKeputusanPSB from "@/components/viewadmin/keputusanpsb/ViewKeputusanPSB";
import ViewDataPSBditerima from "@/components/viewadmin/penerimaan/ViewDataPSBditerima";
import ViewDataPSBtidakDiterima from "@/components/viewadmin/penerimaan/ViewDataPSBtidakDiterima";
import PageDataDiskon from "@/components/viewadmin/diskon/PageDataDiskon";
import PageAccessMenu from "@/components/viewadmin/accessmenu/PageAccessMenu";
export default {
  name: "routerViewAdmin",
  components: {
    PageAccessMenu,
    PageDataDiskon,
    ViewDataPSBtidakDiterima,
    ViewDataPSBditerima,
    ViewKeputusanPSB,
    ViewRekomendasiPSB,
    ViewDataHasilWawancaraOrtu,
    ViewListAnakWawancara,
    ViewListAnakCatatanDiskusi,
    ViewDataBerasa,
    PageHasilTesKognitif,
    ViewDataTestKognitif,
    PageHasilWawancaraPsikolog,
    SetupAssessorExternal,
    JadwalKelasPercobaan,
    SetupAssesor,
    SetupKelasPercobaan,
    PageViewDataWawancara,
    PageViewFormulirPSB,
    PageViewTahunAjaran,
    PageParameterTahunAjaran, ViewDataPengembalianFormulir, FooterWeb, ViewDataPendaftaran, HeaderAdmin},
  data:()=>({
    page: '',
    accountData:{
      email:'',
      password:'',
      role:''
    },
    pageAllow : ['isi','panduan','biaya-konfirmasi','bayar-biaya-konfirmasi','terima-kasih','setup-tahunajaran','view-formulir-psb','jadwal-wawancara-psikolog']
  }),
  methods:{
    async getUserInformation(){
      let userdata = await this.$store.getters.getUserInfo;
      this.accountData.nama = userdata.data.fullname;
      this.accountData.email = userdata.data.email;
      this.accountData.role = userdata.data.userRole;
      if(this.accountData.role!==3){
        router.push('/home');
      }
    }
  },
  watch:{
    '$route.params.page':{
      immediate: true,
      deep: false,
      handler(){
        this.page = this.$route.params.page.toLowerCase();
      }
    }
  },
  beforeMount() {
    //this.getUserInformation();
  }
}
</script>

<style scoped>

</style>