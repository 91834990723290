<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Wawancara Psikolog > Data Jadwal Wawancara Psikolog > Ubah data"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Data Jadwal Wawancara Psikolog - {{data.nama_anak}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </div>

    <div class="notes-notification-sga">
      <p><strong>Catatan :</strong><br>
      <ul>
        <li>Masukkan jadwal wawancara berdasarkan hasil komunikasi dengan orang tua dan tim pewawancara dibawah ini</li>
        <li>Sistem akan otomatis mengirimkan surel kepada Orang Tua mengenai jadwal yang telah disediakan</li>
      </ul>
      </p></div>
      <div class="form-general">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-12 spacer-field-sga">
                <normal-label>No formulir : </normal-label><br/>
                <normal-label2>{{data.no_formulir}}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Ananda : </normal-label><br/>
                <normal-label2>{{data.nama_anak}}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Orang Tua</normal-label>
                <ul>
                  <li>
                    <normal-label2>{{data.nama_ayah}}</normal-label2>
                  </li>
                  <li>
                    <normal-label2>{{data.nama_ibu}}</normal-label2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-12 spacer-field-sga  w-100"><label>Jadwal wawancara</label></div>
              <div class="col-md-6  spacer-field-sg">
                <div class="datepicker date input-group">
                  <input type="date" placeholder="" v-model="tanggal" class="date-picker-sga form-control" id="" required="">
                </div>
                <keterangan-form>Tanggal wawancara</keterangan-form>
              </div>
              <div class="col-md-6  spacer-field-sg">
                <div class="datepicker date input-group">
                  <input type="time" placeholder="H:i" v-model="waktu" class="date-picker-sga form-control" id="" required="">
                </div>
                <keterangan-form>Jam wawancara</keterangan-form>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12" v-if="message">
                <AlertSuccess v-if="success" :message="message"></AlertSuccess>
                <AlertDanger v-else :message="message"></AlertDanger>
              </div>
              <div class="col-12">
                <normal-label>Status pelaksanaan wawancara : <select v-model="namastatus"
                                                                     class="sga-status-dropdown">
                  <option value="Menunggu">Menunggu konfirmasi jadwal</option>
                  <option value="Terjadwal">Terjadwal</option>
                  <option value="Selesai">Selesai</option>
                </select></normal-label>
                <LoadSpinner v-if="loading"></LoadSpinner>
                <button v-else type="button" @click="saveDataWawancaraPsikolog" class="btn btn-sm btn-success ml-3">Simpan Jadwal / Ubah status</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";
import LoadSpinner from "@/components/LoadSpinner";

export default {
  name: "PageUpdateJadwalWawancara",
  components: {LoadSpinner, AlertDanger, AlertSuccess, BreadCrumb},
  props:{
    data: undefined
  },
  data:()=>({
    loading: false,
    response:'',
    message:'',
    success: false,
    namastatus:'',
    tanggal:'',
    waktu:'',
  }),
  emits: ['change-view'],
  methods:{
    toggleview(){
      this.$emit('change-view',true)
    },
    saveDataWawancaraPsikolog(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        idanak: this.data.no_formulir,
        tanggal: this.tanggal+' '+this.waktu,
        status: this.namastatus
      };
      let path = '/dashboard/simpanJadwalWawancaraPsikolog';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.tanggal = this.data.tgl_wawancara;
    this.waktu = this.data.jam_wawancara;
    this.namastatus = this.data.jadwal_wawancara;
  }
}
</script>

<style scoped>

</style>