<template>
  <PageIsiFormulirBerasa :token="token"></PageIsiFormulirBerasa>
</template>

<script>
import PageIsiFormulirBerasa from "@/components/views/berasa/PageIsiFormulirBerasa";
export default {
  name: "routeBerasa",
  components: {PageIsiFormulirBerasa},
  data:()=>({
    token:''
  }),
  watch:{
    '$route.params.token':{
      immediate: true,
      deep: false,
      handler(){
        this.token = this.$route.params.token.toLowerCase();
      }
    }
  },
}
</script>

<style scoped>

</style>