<template>
  <button type="button" class="btn btn-md btn-info"><img :src="logoAnimated" style="width: 20px; height: 20px"><i class="fa-solid fa-caret-right"></i> Loading...</button>
</template>

<script>
export default {
  name: "ButtonLoading",
  data:()=>({
    logoAnimated: require('@/assets/images/logo-sga-animated2.gif'),
  }),
}
</script>

<style scoped>

</style>