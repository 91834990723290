<template>
  <BreadCrumb v-if="showgrid" page="Beranda > Pendaftaran > Data Rekomendasi  PSB > Data Hasil Rekomendasi PSB"></BreadCrumb>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "ViewRekomendasiPSB",
  components: {BreadCrumb},
  data:()=>({
    loading: false,
    lazyParams: {},
    showgrid: true,
  })
}
</script>

<style scoped>

</style>