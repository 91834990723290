<template>
  <Bread-crumb page="Beranda > Pendaftaran > Hasil Keputusan PSB"></Bread-crumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Hasil Keputusan PSB</h3>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Dibawah ini adalah hasil keputusan Penerimaan Siswa Baru (PSB) untuk Ananda. Anda akan menerima surat elektronik dari pihak sekolah berisi Surat Keputusan (SK) resmi berikut instruksi pembayaran bila Ananda diterima menjadi siswa baru Sekolah Gemala Ananda.</p>
        <div class="table-data-sga">
          <div class="table-responsive">
            <table class="table table-striped ">
              <thead>
              <tr>
                <th scope="col">No formulir</th>
                <th scope="col">Nama Ananda</th>
                <th scope="col">Nama Orang Tua</th>
                <th scope="col">Jenis</th>
                <th scope="col">Status</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>%no%</td>
                <td>%NamaAnak%</td>
                <td>%Namaorangtua%</td>
                <td>%jenis%</td>
                <td>%status%</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Putra Ganteng Sak Jagad</td>
                <td>Biang Gateng Sak Jagad</td>
                <td>Pendaftaran siswa baru</td>
                <td>Diterima</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "PageHasilKeputusan",
  components:{BreadCrumb}
}
</script>

<style scoped>

</style>