<template>
  <FormTitle title="B. Latar Belakang Pendidikan" subtitle="Isi informasi mengenai latar belakang pendidikan nama akhir sekolah"></FormTitle>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <normal-label>Playgroup</normal-label>
        </div>
        <div class="col-md-6 spacer-field-sga">
          <input type="text" v-model="dataFormulir.pgnamasekolah" class="w-100 form-control">
          <keterangan-form>Nama sekolah</keterangan-form>
        </div>
        <div class="col-md-6 spacer-field-sga">
          <input type="text" v-model="dataFormulir.pgtahunbelajar" class="w-100 form-control">
          <keterangan-form>Tahun belajar</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <normal-label>TK</normal-label>
        </div>
        <div class="col-md-6 spacer-field-sga">
          <input type="text" v-model="dataFormulir.tknamasekolah" class="w-100 form-control">
          <keterangan-form>Nama sekolah</keterangan-form>
        </div>
        <div class="col-md-6 spacer-field-sga">
          <input type="text" v-model="dataFormulir.tktahunbelajar" class="w-100 form-control">
          <keterangan-form>Tahun belajar</keterangan-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <normal-label>SD</normal-label>
        </div>
        <div class="col-md-6 spacer-field-sga">
          <input type="text" v-model="dataFormulir.sdnamasekolah" class="w-100 form-control">
          <keterangan-form>Nama sekolah</keterangan-form>
        </div>
        <div class="col-md-6 spacer-field-sga">
          <input type="text" v-model="dataFormulir.sdtahunbelajar" class="w-100 form-control">
          <keterangan-form>Tahun belajar</keterangan-form>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <normal-label>Kegiatan Pengayaan/Les</normal-label>
          <textarea v-model="dataFormulir.les" class="form-control textarea-sga" id="exampleFormControlTextarea1" rows="9"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/views/psb/FormTitle";
export default {
  name: "PageFormLatarBelakangPendidikan",
  props:{
    data:[],
    initdata:undefined,
  },
  emits: ['change-data'],
  components: {FormTitle},
  data: () => ({
    loading: false,
    message:'',
    success:false,
    dataFormulir:{
      pgnamasekolah:'',
      pgtahunbelajar:'',
      tknamasekolah:'',
      tktahunbelajar:'',
      sdnamasekolah:'',
      sdtahunbelajar:'',
      les:''
    }
  }),
  mounted() {
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
  }
}
</script>

<style scoped>

</style>