<template>
  <div class="row">
    <div class="col-12 spacer-field-sga">
      <label v-if="required">{{label}}</label>
      <normal-label v-else>{{label}}</normal-label>
      <input type="text" v-model="dataFormulir.nama" class="w-100 form-control" required>
      <span class="keterangan-form">Tuliskan nama lengkap</span>
    </div>
    <div class="col-12 spacer-field-sga" v-if="statushubugan">
      <div class="row">
        <div class="col-12"><normal-label>Status hubungan</normal-label></div>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataFormulir.statushubungan" value="angkat">
            <span class="normal-label2 form-check-label" for="inlineCheckbox1">Angkat</span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataFormulir.statushubungan" value="tiri">
            <span class="normal-label2 form-check-label" for="inlineCheckbox2">Tiri</span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataFormulir.statushubungan" value="wali">
            <span class="normal-label2 form-check-label" for="inlineCheckbox3">Wali</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Kebangsaan</label>
      <normal-label v-else>Kebangsaan</normal-label>
      <input type="text" v-model="dataFormulir.kebangsaan" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Agama</label>
      <normal-label v-else>Agama</normal-label>
      <select name="Kelas" id="Agama" v-model="dataFormulir.agama" class="w-100 form-control" required>
        <option value="" disabled selected>Pilih</option>
        <option value="islam">Islam</option>
        <option value="katolik">Katolik</option>
        <option value="kristen">Kristen</option>
        <option value="budha">Budha</option>
        <option value="hindu">Hindu</option>
        <option value="konghucu">Konghucu</option>
        <option value="kepercayaan">Kepercayaan Kepada Tuhan Yang Maha Esa</option>
      </select>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Alamat rumah</label>
      <normal-label v-else>Alamat rumah</normal-label>
      <textarea type="text" v-model="dataFormulir.rumah.alamat" class="w-100 form-control textarea-sga" required></textarea>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">RT/RW</label>
      <normal-label v-else>RT/RW</normal-label>
      <input type="text" v-model="dataFormulir.rumah.rtrw" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Kelurahan</label>
      <normal-label v-else>Kelurahan</normal-label>
      <input type="text" v-model="dataFormulir.rumah.kelurahan" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Kecamatan</label>
      <normal-label v-else>Kecamatan</normal-label>
      <input type="text" v-model="dataFormulir.rumah.kecamatan" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Kota/Kabupaten</label>
      <normal-label v-else>Kota/Kabupaten</normal-label>
      <input type="text" v-model="dataFormulir.rumah.kotakabupaten" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Kode Pos</label>
      <normal-label v-else>Kode Pos</normal-label>
      <input type="text" v-model="dataFormulir.rumah.kodepos" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <span class="normal-label">No. Telepon Rumah</span>
      <input type="text" v-model="dataFormulir.rumah.telp" class="w-100 form-control">
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Posisi dalam pekerjaan</label>
      <normal-label v-else>Posisi dalam pekerjaan</normal-label>
      <input type="text" v-model="dataFormulir.kantor.posisi" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Bidang pekerjaan</label>
      <normal-label v-else>Bidang pekerjaan</normal-label>
      <input type="text" v-model="dataFormulir.kantor.bidang" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Nama perusahaan</label>
      <normal-label v-else>Nama perusahaan</normal-label>
      <input type="text" v-model="dataFormulir.kantor.namaperusahaan" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Alamat kantor</label>
      <normal-label v-else>Alamat kantor</normal-label>
      <textarea type="text" v-model="dataFormulir.kantor.alamat" class="w-100 form-control textarea-sga" required></textarea>
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Kode pos</label>
      <normal-label v-else>Kode pos</normal-label>
      <input type="text" v-model="dataFormulir.kantor.kodepos" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <normal-label>Telepon kantor</normal-label>
      <input type="text" v-model="dataFormulir.kantor.telp" class="w-100 form-control">
    </div>
    <div class="col-12 spacer-field-sga">
      <label v-if="required">Telepon genggam 1</label>
      <normal-label v-else>Telepon genggam 1</normal-label>
      <input type="text" v-model="dataFormulir.hp1" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <span class="normal-label">Telepon genggam 2</span>
      <input type="text" v-model="dataFormulir.hp2" class="w-100 form-control">
    </div>
    <div class="col-12 spacer-field-sga">
      <span class="normal-label">Alamat surel</span>
      <input type="email" v-model="dataFormulir.email" class="w-100 form-control" required>
    </div>
    <div class="col-12 spacer-field-sga">
      <span class="normal-label">Akun Facebook</span>
      <input type="text" v-model="dataFormulir.socmed.facebook" class="w-100 form-control">
    </div>
    <div class="col-12 spacer-field-sga">
      <span class="normal-label">Akun Instagram</span>
      <input type="text" v-model="dataFormulir.socmed.instagram" class="w-100 form-control">
    </div>
    <div class="col-12 spacer-field-sga">
      <span class="normal-label">Akun lain lain</span>
      <input type="text" v-model="dataFormulir.socmed.lainlain" class="w-100 form-control">
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFormDataOrangTua",
  props:{
    label: String,
    statushubugan: Boolean,
    data:[],
    initdata: undefined,
    required:Boolean
  },
  emits: ['change-data'],
  data:()=>({
    dataFormulir:{
      nama:'',
      kebangsaan:'',
      statushubungan:'',
      agama: '',
      hp1:'',
      hp2:'',
      rumah:{
        alamat:'',
        rtrw:'',
        kotakabupaten:'',
        kecamatan:'',
        kelurahan:'',
        kodepos:'',
        telp:'',
      },
      kantor:{
        posisi:'',
        bidang:'',
        namaperusahaan:'',
        alamat:'',
        kodepos:'',
        telp:'',
      },
      socmed:{
        facebook:'',
        instagram:'',
        lainlain:''
      }
    }
  }),
  computed:{
    isRequired(){
      return this.required;
    }
  },
  mounted() {
    this.$emit('change-data',this.initdata);
    this.dataFormulir = this.initdata;
  },
}
</script>

<style scoped>

</style>