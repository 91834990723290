<template>
  <div class="col-md-4 spacer-field-sga"><label>Nama lengkap ananda</label></div>
  <div class="col-md-8 spacer-field-sga"><input type="text" :value="anak.nama"
                                                @keyup="$emit('change-nama',$event.target.value)"
                                                class="w-100 form-control" required>
    <span class="keterangan-form" :class="v$.anak.nama.$error?'text-danger':''">Tuliskan nama lengkap</span>
  </div>
  <div class="col-md-4 spacer-field-sga"><label>Jenis pendaftaran</label></div>
  <div class="col-md-8 spacer-field-sga">
    <LoadSpinner v-if="loading"></LoadSpinner>
    <select v-else :value="anak.jenisdaftar" name="Daftar" class="w-100 form-control"
            @change="$emit('change-jenisdaftar',$event.target.value)" required>
      <option v-for="(jenis,idx) in jenisPendaftaran" :key="idx" :value="jenis.code">{{jenis.value}}</option>
    </select>
    <span class="keterangan-form" :class="v$.anak.jenisdaftar.$error?'text-danger':''">Siswa baru atau siswa pindahan</span>
  </div>
  <div class="col-md-4 spacer-field-sga" v-if="pindahan"><label>Akan masuk ke kelas</label></div>
  <div class="col-md-8 spacer-field-sga" v-if="pindahan">
    <LoadSpinner v-if="loading"></LoadSpinner>
    <select v-else name="Kelas" class="w-100 form-control" :value="anak.kelas"
            @change="$emit('change-kelas',$event.target.value)" required>
      <option v-for="(kelas,idx) in jenisKelas" :key="idx" :value="kelas.code">{{kelas.value}}</option>
    </select>
    <span class="keterangan-form" :class="v$.anak.kelas.$error?'text-danger':''">Khusus siswa pindahan</span>
  </div>
  <div class="col-md-4 spacer-field-sga" v-if="pindahan"><label>Tahun ajaran</label></div>
  <div class="col-md-8 spacer-field-sga" v-if="pindahan">
    <LoadSpinner v-if="loading"></LoadSpinner>
    <select v-else class="w-100 form-control" :value="anak.tahunajaran"
            @change="$emit('change-ajaran',$event.target.value)" required>
      <option v-for="(ajaran,idx) in jenisAjaran" :key="idx" :value="ajaran.code">{{ajaran.value}}</option>
    </select>
    <span class="keterangan-form" :class="v$.anak.tahunajaran.$error?'text-danger':''">Khusus siswa pindahan</span>
  </div>
  <div class="col-md-4 spacer-field-sga"><label>NIK ananda</label></div>
  <div class="col-md-8 spacer-field-sga"><input type="text" :value="anak.nikanak"
                                                @keyup="$emit('change-nik',$event.target.value)"
                                                @blur="checknik"
                                                @keypress="onInput($event,16)" class="w-100 form-control" required>
    <span v-if="isnikduplikat" class="keterangan-form text-danger mr-1">{{msgnikduplikat}}</span>
    <span v-else class="keterangan-form" :class="v$.anak.nikanak.$error?'text-danger':''">NIK 16 digit sesuai Kartu Keluarga
    ({{ getInfoLength(anak.nikanak, 16) }})</span>
  </div>
  <div class="col-md-4 spacer-field-sga"><label>Tanggal lahir ananda</label></div>
  <div class="col-md-8 spacer-field-sga">
    <div class="datepicker date input-group" v-if="!loadingumur">
      <DatePicker v-model="ttl" @date-select="calculateAge(ttl)" class="date-picker-sga form-control" id="" dateFormat="dd/mm/yy" required/>
    </div>
    <LoadSpinner v-if="loadingumur"></LoadSpinner>
    <span class="keterangan-form" v-if="!pindahan" :class="!umurvalid?'text-danger':''">dd/mm/yyyy dan usia maksimal 8 tahun dan minimal 6
    tahun terhitung
    dari 31 Mei tahun ajaran. </span><span :class="!umurvalid?'text-danger':''" class="keterangan-form">(Usia saat ini: {{umur}} )</span>
  </div>
  <div class="col-md-4 spacer-field-sga"><label>Sekolah asal</label></div>
  <div class="col-md-8 spacer-field-sga"><input type="text" :value="anak.sekolahasal"
                                                @keyup="$emit('change-asal',$event.target.value)"
                                                class="w-100 form-control" required>
    <span class="keterangan-form" :class="v$.anak.sekolahasal.$error?'text-danger':''">TK untuk kelas 1, dan SD asal untuk pindahan,
    bila tidak bersekolah
    tulis 'tidak bersekolah'
  </span>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import {required, minLength, maxLength, requiredIf} from '@vuelidate/validators'
import tools from "@/js/js.function.tools";
import LoadSpinner from "@/components/LoadSpinner";
import DashboardServices from "@/services/dashboard.services";
import DatePicker from 'primevue/datepicker';
export default {
  name: "FormDataAnak",
  components: {LoadSpinner,DatePicker},
  emits: ['change-jenisdaftar','change-nama', 'change-nik', 'change-ttl', 'change-kelas', 'change-ajaran', 'change-asal', 'validate-form'],
  props: {
    anak: {},
    index: Number,
    jenisPendaftaran:undefined,
    jenisKelas:undefined,
    jenisAjaran:undefined,
    nikduplikat: undefined,
  },
  data: () => ({
    v$: useValidate(),
    loading:false,
    umur:'-',
    ttl:"",
    umurvalid: false,
    loadingumur: false,
    isnikduplikat: false,
    msgnikduplikat:'',
    imgLoading: require('@/assets/images/loading.gif'),
    pindahan: false,
    issibling:true
  }),
  computed:{
  },
  validations() {
    return {
      ttl: {required, minLength: minLength(2), maxLength: maxLength(50)},
      anak: {
        nama: {required, minLength: minLength(2), maxLength: maxLength(100)},
        jenisdaftar: {required},
        kelas: {required: requiredIf(this.pindahan), minLength: minLength(1), maxLength: maxLength(10)},
        tahunajaran: {required:requiredIf(this.pindahan)},
        nikanak: {required, minLength: minLength(16), maxLength: maxLength(16)},
        sekolahasal: {required, minLength: minLength(2), maxLength: maxLength(100)},
      },
    }
  },
  methods: {
    updateTanggal(){
      //$emit('change-ttl',$event.target.value)

      //console.log(this.ttl)
    },
    calculateAge(date){
      this.$emit('change-ttl',date)
      //if(date.length ===10){
        this.hitungumur(date);
      //}
    },
    validate() {
      this.v$.anak.$validate();
    },
    onInput(evt, max) {
      tools.inputNumericOnly(evt, max);
    },
    getInfoLength(data, max) {
      return tools.getInfoLength(data, max);
    },
    getAge2(data) {
      let birthDate = new Date(data);
      let otherDate = new Date();

      var years = (otherDate.getFullYear() - birthDate.getFullYear());

      if (otherDate.getMonth() < birthDate.getMonth() ||
          otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
        years--;
      }
      return years;
    },
    getAgeEnglish(date) {
      let todate = new Date();
      if (todate) todate = new Date(todate);
      else todate = new Date();
      var age = [], fromdate = new Date(date),
          y = [todate.getFullYear(), fromdate.getFullYear()],
          ydiff = y[0] - y[1],
          m = [todate.getMonth(), fromdate.getMonth()],
          mdiff = m[0] - m[1],
          d = [todate.getDate(), fromdate.getDate()],
          ddiff = d[0] - d[1];

      if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) --ydiff;
      if (mdiff < 0) mdiff += 12;
      if (ddiff < 0) {
        fromdate.setMonth(m[1] + 1, 0);
        ddiff = fromdate.getDate() - d[1] + d[0];
        --mdiff;
      }
      if (ydiff > 0) age.push(ydiff + ' year' + (ydiff > 1 ? 's ' : ' '));
      if (mdiff > 0) age.push(mdiff + ' month' + (mdiff > 1 ? 's' : ''));
      if (ddiff > 0) age.push(ddiff + ' day' + (ddiff > 1 ? 's' : ''));
      if (age.length > 1) age.splice(age.length - 1, 0, ' and ');
      return age.join('');
    },
    getAge(date) {
      let todate = new Date();
      if (todate) todate = new Date(todate);
      else todate = new Date();
      var age = [], fromdate = new Date(date),
          y = [todate.getFullYear(), fromdate.getFullYear()],
          ydiff = y[0] - y[1],
          m = [todate.getMonth(), fromdate.getMonth()],
          mdiff = m[0] - m[1],
          d = [todate.getDate(), fromdate.getDate()],
          ddiff = d[0] - d[1];

      if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) --ydiff;
      if (mdiff < 0) mdiff += 12;
      if (ddiff < 0) {
        fromdate.setMonth(m[1] + 1, 0);
        ddiff = fromdate.getDate() - d[1] + d[0];
        --mdiff;
      }
      if (ydiff > 0) age.push(ydiff + ' tahun ');
      if (mdiff > 0) age.push(mdiff + ' bulan');
      if (ddiff > 0) age.push(ddiff + ' hari');
      if (age.length > 1) age.splice(age.length - 1, 0, ' dan ');
      return age.join('');
    },
    checknik(){
      if(this.anak.nikanak.length===16){
        if(this.nikduplikat){
          this.isnikduplikat = true;
          this.msgnikduplikat = 'Mohon cek NIK anak, pastikan sesuai';
        }else{
          this.isnikduplikat = false;
          this.msgnikduplikat = '';
          this.checkNikAnak();
        }
      }else{
        this.isnikduplikat = false;
      }
    },
    hitungumur(date){
      this.loadingumur = true;
      let data = {"paramname":date};
      let path = '/function/calculateage';
      this.msgnikduplikat = '';
      DashboardServices.postData(data,path).then(
          (response)=>{
            let resp = response.data;
            this.loadingumur = false;
            if(resp.status===200){
              this.umurvalid = resp.data.valid;
              this.umur = resp.data.umur;
            }else{
              this.umur = '-';
            }
          }
      );
    },
    checkNikAnak(){
      this.loading = true;
      let data = {"paramname":this.anak.nikanak};
      let path = '/dashboard/ceknik';
      this.msgnikduplikat = '';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status!=200){
              this.isnikduplikat = true;
              this.msgnikduplikat = response.data.message;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    this.validate();
  },
  watch: {
    anak: {
      immediate: true,
      deep: true,
      handler() {
        if(this.anak.jenisdaftar==='2'){
          this.pindahan = true;
        }else{
          this.pindahan = false;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>