<template>
  <Accordion>
    <AccordionTab header="Perubahan Status">
      <ul class="list-group">
        <li class="list-group-item" v-for="(data,idx) in dataupdate" :key="idx">{{data.info}}</li>
      </ul>
    </AccordionTab>
  </Accordion>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
export default {
  name: "ListUpdateData",
  props:{
    dataupdate: undefined
  },
  components:{
    Accordion,
    AccordionTab,
  },
}
</script>

<style scoped>

</style>