<template>
  <BreadCrumb page="Beranda > Pendaftaran > Penilaian > Pengaturan Tim Pewawancara"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Pengaturan Tim Assessor PSB Tahun Ajaran {{this.$store.getters.getTahunAJaran}}</h3>
        <normal-label>Status tim : <select name="status-jadwal" class="sga-status-dropdown" >
          <option value="ditutup">dalam persiapan</option>
          <option value="dibuka">tim lengkap</option>
        </select></normal-label>
        <LoadSpinner v-if="loading"></LoadSpinner>
        <button v-else type="button" href="#" class="btn btn-sm btn-success">Ubah</button>
        <hr>
        <div class="notes-notification-sga">
          <p><strong>Catatan : </strong><ul>
          <li>Masukkan nama tim, bisa berupa huruf dan angka</li>
          <li>Masukkan anggota tim</li>
        </ul> </p></div>
        <form>
          <div class="form-general">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-striped ">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Tim</th>
                      <th scope="col">Anggota</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(assesor,idx) in dataAssesor" :key="idx">
                      <td scope="row">{{assesor.no}}</td>
                      <td>{{assesor.nama_tim}}</td>
                      <td>{{assesor.nama_anggota}}</td>
                      <td><a @click="deleteAssessor(assesor.id_assessor)" href="#"><i class="fa-solid fa-trash"></i></a></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div></div>
            <hr>
            <h4>Tambah Tim</h4>
            <div class="row">
              <div class="col-md-11" v-if="messagetrx">
                <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
                <AlertDanger v-else :message="messagetrx"></AlertDanger>
              </div>
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-3 spacer-field-sga">
                    <div class="datepicker date input-group">
                      <input type="text" placeholder="" v-model="timname" class="w-100 form-control" required="">
                    </div>
                    <keterangan-form :class="v$.timname.$error?'text-danger':''">Masukkan nama tim</keterangan-form></div>
                  <div class="col-md-6 spacer-field-sga">
                    <input type="text" placeholder="" v-model="anggota" class="w-100 form-control" required="" >
                    <keterangan-form :class="v$.anggota.$error?'text-danger':''">Masukkan nama nama anggota</keterangan-form></div>
                  <div class="col-md-3 button-area-sga text-left">
                    <LoadSpinner v-if="loading"></LoadSpinner>
                    <button v-else type="button" @click="saveDataAssessor" class="btn btn-md btn-success"><i class="fa-solid fa-caret-right"></i>  Tambah tim</button></div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import {required,minLength,} from '@vuelidate/validators';
import AlertDanger from "@/components/AlertDanger";
import AlertSuccess from "@/components/AlertSuccess";
import LoadSpinner from "@/components/LoadSpinner";
export default {
  name: "SetupAssesor",
  components: {LoadSpinner, AlertSuccess, BreadCrumb,AlertDanger},
  data:()=>({
    v$: useValidate(),
    loading: false,
    response: '',
    message: '',
    success: false,
    messagetrx: '',
    successtrx: false,
    tanggalkelas:'',
    anggota:'',
    timname:'',
    dataAssesor:[]
  }),
  methods:{
    getDataAssessor(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataAssessor';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataAssesor = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    async saveDataAssessor(){
      const result = await this.v$.$validate();
      if(result){
        this.loading = true;
        this.$store.commit('loading',this.loading);
        let data = {
          timname: this.timname,
          anggota: this.anggota
        };
        let path = '/dashboard/simpanAssessor';
        DashboardServices.postData(data,path).then(
            (response)=>{
              this.response = response.data;
              this.messagetrx = response.data.message;
              if(response.data.status===200){
                this.successtrx = true;
                this.getDataAssessor();
              }else {
                this.successtrx = false;
              }
              this.loading = false;
              this.$store.commit('loading',this.loading);
            }
        );
      }else{
        this.message = 'Silahkan isi data dengan benar';
      }
    },
    deleteAssessor(idAssesor){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paramname: idAssesor,
        status: 'ACTIVE'
      };
      let path = '/dashboard/deleteAssessor';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.messagetrx = response.data.message;
            if(response.data.status===200){
              this.successtrx = true;
              this.getDataAssessor();
            }else {
              this.successtrx = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  validations(){
    return {
      anggota:{required,minlength:minLength(5)},
      timname:{required,minlength:minLength(2)},
    }
  },
  mounted() {
    this.getDataAssessor();
  }
}
</script>

<style scoped>

</style>