<template>
  <BreadCrumb page="Beranda > Pendaftaran > Isi Formulir"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Pengisian Formulir Penerimaan Siswa Baru (PSB) Sekolah Gemala Ananda
        </h3>
        <hr>
        <LoadSpinner v-if="loading"></LoadSpinner>
        <div v-else class="form-general">
          <div class="row">
            <div class="col-12 text-left">
              <p>Terima kasih</p>
              <p>
                Anda telah menyelesaikan pengisian formulir PSB SD Gemala Ananda untuk
                {{dataAnak.anak_nama}}. Kelengkapan formulir akan diperiksa oleh tim PSB.</p>
              <p>Bila Anda belum melakukan pembayaran tanda konfirmasi, harap lakukan <strong>pembayaran
                tanda konfirmasi</strong>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import LoadSpinner from "@/components/LoadSpinner";

export default {
  name: "PageTerimaKasihIsiFormulir",
  components: {LoadSpinner},
  data: () => ({
    invid:'',
    response:'',
    message:'',
    loading:false,
    success: false,
    dataAnak:[],
  }),
  methods:{
    getDataFormulirAnak(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.invid};
      let path = '/dashboard/getDataFormulirAnak';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataAnak = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  watch:{
    '$route.params.id':{
      immediate: true,
      deep: false,
      handler(){
        this.invid = this.$route.params.id;
      }
    }
  },
  mounted() {
    this.getDataFormulirAnak();
  }
}
</script>

<style scoped>

</style>