<template>
  <!--Dokumen Penambahan   -->
  <div class="row">
    <div class="col-12 spacer-field-sga">
      <p style="font-style:italic; color:red;">Hanya digunakan bila diperlukan untuk menambah data yang kurang, sesuai arahan pihak Sekolah</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12"></div>
    <div class="col-12 spacer-field-sga">
      <normal-label><i class="fa-solid fa-image"></i> Dokumen pendukung tambahan bila ada</normal-label>
      <div class="custom-file">
        <FileLister v-if="files.dokumentambahan.size!=0" :filedata="files.dokumentambahan.list" :num-visible="files.dokumentambahan.size"></FileLister>
        <ProgressBar v-if="isupload" class="mt-2 mb-2" :value="uploadprogress" style="height: 10px;font-size: 14px"></ProgressBar>
        <FileUpload v-if="showupload" :class="classupload" :fileLimit="10" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel" @before-send="beforeUpload" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="5000000" @progress="onProgress" @upload="onUpload" @select="onSelect">
          <template #empty>
            <p>Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
        <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg,.pdf | Maksimum 10 berkas </keterangan-form>
      </div>
    </div>
  </div>
</template>

<script>
//import "primevue/resources/themes/lara-light-indigo/theme.css";
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import DashboardServices from "@/services/dashboard.services";
import FileLister from "@/components/FileLister";
export default {
  name: "PageFormDokumenTambahan",
  props:{
    data:[],
    idanak:String,
    initdata: undefined,
    showupload: Boolean
  },
  components:{FileLister, FileUpload,ProgressBar},
  emits: ['change-data'],
  data:()=>({
    urlupload: '',
    uploadprogress:0,
    classupload:'',
    uploadLabel: 'Pilih',
    isupload: false,
    files:{
      dokumentambahan:{
        size:0,
        list:[]
      },
    },
    dataFormulir:{
      tambahan:'',
      dokumen:{
        kelahiran:'',
        kartukeluarga:'',
        report:'',
        tambahan:''
      }
    }
  }),
  methods:{
    beforeUpload(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','dokumentambahan');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgress(event){
      this.isupload = true;
      this.uploadprogress = event.progress;
    },
    onUpload(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.dokumen.tambahan = obj.data.fileid;
        this.classupload='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload = false;
    },
    onSelect(event){
      if(event.files.length >0){
        //this.uploadLabel = event.files[0].name;
      }
      this.dataFormulir.dokumen.tambahan = '';
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.idanak};
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              if (typeof this.response.data.dokumentambahan !== "undefined") {
                this.files.dokumentambahan.list = this.response.data.dokumentambahan.list;
                this.files.dokumentambahan.size = this.response.data.dokumentambahan.size;
                if (typeof this.files.dokumentambahan.size !== "undefined") {
                  if(this.files.dokumentambahan.size>0){
                    if (typeof this.dataFormulir.dokumen !== "undefined") {
                      if (typeof this.dataFormulir.dokumen.tambahan !== "undefined") {
                        this.dataFormulir.dokumen.tambahan = 'uploaded';
                      }
                    }
                  }
                }
              }
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
    //this.$emit('change-data',this.dataFormulir);
    this.getDataUploadedFile();
  },
}
</script>

<style scoped>

</style>