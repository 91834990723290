<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Keputusan > Lihat Data Keputusan > Ubah data"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Keputusan PSB untuk {{data.nama_anak}} No Formulir {{data.no_formulir}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div class="notes-notification-sga">
      <p><strong>Catatan :</strong><br></p>
      <ul>
        <li>Menggunggah SK akan mengirimkan notifikasi ke orang tua.</li>
      </ul>
      <p></p>
    </div>
    <div class="form-general">
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-12 spacer-field-sga"><normal-label>No formulir</normal-label> <br/><normal-label2>{{data.no_formulir}}</normal-label2> </div>
            <div class="col-12 spacer-field-sga"><normal-label>Nama Ananda </normal-label> <br/><normal-label2>{{data.nama_anak}}</normal-label2></div>
            <div class="col-12 spacer-field-sga"><normal-label>Nama Orang Tua </normal-label>
              <ul>
                <li>
                  <normal-label2>{{ data.nama_ayah }}</normal-label2>
                </li>
                <li>
                  <normal-label2>{{ data.nama_ibu }}</normal-label2>
                </li>
                <li v-if="data.nama_ayah_tiri">{{ data.nama_ayah_tiri }}</li>
                <li v-if="data.nama_ibu_tiri">{{ data.nama_ibu_tiri }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4"><normal-label>Nama penerima</normal-label></div>
            <div class="col-md-8">{{data.ortu}}</div>
            <div class="col-md-4"><normal-label>Email orang tua</normal-label></div>
            <div class="col-md-8">{{data.ortuemail}}</div>
            <div class="col-md-4"><label>Subjek surel</label></div>
            <div class="col-md-8"><input type="" value="Ananda tidak diterima di Sekolah Gemala Ananda" class="w-100 form-control" required=""><keterangan-form>tulis untuk mengubah default</keterangan-form></div>
            <div class="col-md-4"><label>Isi surel</label></div>
            <div class="col-md-8"><textarea class="form-control textarea-sga" id="exampleFormControlTextarea1" rows="9">Orang tua yang baik, terimakasih telah mendaftar di Sekolah Gemala Ananda. Dengan ini kami menginformasikan Ananda tidak diterima.</textarea><keterangan-form>tulis untuk mengubah default</keterangan-form></div>
          </div>
          <div class="row">
            <div class="col-md-4"><label>Unggak berkas SK</label></div>
            <ProgressBar v-if="isupload" class="mt-2 mb-2" :value="uploadprogress" style="height: 10px;font-size: 14px"></ProgressBar>
            <div class="col-md-8">
              <FileLister v-if="!loading" :filedata="filelist.list" :num-visible="filelist.size"></FileLister>
              <FileUpload v-if="showupload" :class="classupload" :fileLimit="2" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel" @before-send="beforeUpload" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg" :maxFileSize="5000000" @progress="onProgress" @upload="onUpload" @select="onSelect">
                <template #empty>
                  <p>Drag and drop files to here to upload.</p>
                </template>
              </FileUpload>
              <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg | Maksimum 2 berkas | Minimum 1 berkas</keterangan-form>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button href="12c-update-data-pembayaran-uang-sekolah.php" class="btn btn-sm btn-success">Kirim surat SK </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import "primevue/resources/themes/lara-light-indigo/theme.css";
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import FileLister from "@/components/FileLister";
export default {
  name: "PageViewPSBditolak",
  components: {BreadCrumb,FileUpload, ProgressBar,FileLister},
  props:{
    data: undefined
  },
  data:()=>({
    loading: false,
    isupload: true,
    showupload: true,
    classupload:'',
    uploadLabel:'',
    uploadprogress: 10,
    urlupload:'',
    filelist:[],
  }),
  emits: ['change-view'],
  methods:{
    toggleview() {
      this.$emit('change-view', true)
    },
    beforeUpload(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','sk-tdkditerima');
      request.formData.append('invid',this.data.invid);
      return request;
    },
    onProgress(event){
      this.isupload = true;
      this.uploadprogress = event.progress;
    },
    onUpload(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.classupload='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload = false;
    },
    onSelect(event){
      if(event.files.length >0){
        //this.uploadLabel3 = event.files[0].name;
      }
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paramname:this.data.invid,
        filecat:'sk-tdkditerima'
      };
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              if (typeof this.response.data["sk-tdkditerima"] !== 'undefined') {
                this.filelist = this.response.data["sk-tdkditerima"];
              }
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.getDataUploadedFile();
  }
}
</script>

<style scoped>

</style>