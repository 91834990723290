const tools = {
    isEmailAddress:function(str) {
        let pattern =/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(str);  // returns a boolean
    },
    isNotEmpty:function (str) {
        let pattern =/\S+/;
        return pattern.test(str);  // returns a boolean
    },
    isNumber:function(str) {
        let pattern = /^\d+\.?\d*$/;
        return pattern.test(str);  // returns a boolean
    },
    isSame:function(str1,str2){
        return str1 === str2;
    },
    inputNumericOnly(evt,maxLength){
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (
            charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            charCode !== 46
        ) {
            evt.preventDefault();
        }
        if(evt.target.value.length>=maxLength){
            evt.preventDefault();
        }
    },
    getInfoLength(data,max){
        return data.length+'/'+max;
    }
}

export default tools;