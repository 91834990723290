<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Diskon > Ubah Data Diskon"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Diskon PSB - {{data.nama_anak}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12"><hr></div>
    </div>
    <div class="form-general">
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-12 spacer-field-sga"><normal-label>No formulir</normal-label><br/>
              <normal-label2>{{data.no_formulir}}</normal-label2>
            </div>
            <div class="col-12 spacer-field-sga"><normal-label>Nama Ananda </normal-label><br/>
              <normal-label2>{{data.nama_anak}}</normal-label2></div>
            <div class="col-12 spacer-field-sga"><normal-label>Nama Orang Tua </normal-label>
              <ul>
                <li><normal-label2>{{data.nama_ayah}}</normal-label2></li>
                <li><normal-label2>{{data.nama_ibu}}</normal-label2></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <normal-label>Status Rekomendasi : <select v-model="statusdiskon" name="status-pelaksanaan-penilaian" class="sga-status-dropdown" >
              <option value="Belum ditentukan">Belum ditentukan</option>
              <option value="Normal">Normal</option>
              <option value="Sibling">Sibling</option>
              <option value="Karyawan tetap">Karyawan tetap</option>
              <option value="Karyawan paruh waktu">Karyawan paruh waktu</option>
            </select>
            </normal-label> <normal-label>Nilai Diskon : {{diskon}}</normal-label>
            <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
            <AlertDanger v-else :message="messagetrx"></AlertDanger>
            <div class="col-12 button-area-sga text-right">
              <button @click="updateDataDiskon" class="btn btn-sm btn-success" type="button"> Simpan catatan</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import AlertDanger from "@/components/AlertDanger";
import AlertSuccess from "@/components/AlertSuccess";
export default {
  name: "PageViewDataDiskon",
  components: {AlertSuccess, AlertDanger, BreadCrumb},
  props:{
    data: undefined
  },
  data:()=>({
    diskon:0,
    statusdiskon:'Belum ditentukan',
    successtrx: false,
    messagetrx:''
  }),
  emits: ['change-view'],
  methods: {
    toggleview() {
      this.$emit('change-view', true)
    },
    setup(){
      this.statusdiskon = this.data.tipediskon;
      this.diskon = this.data.diskon;
    },
    updateDataDiskon(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        'inv': this.data.no_formulir,
        'status': this.statusdiskon,
      };
      let path = '/dashboard/updateDataDiskon';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.diskon = response.data.data.diskon;
              this.successtrx = true;
              this.messagetrx = 'Data berhasil disimpan';
            }else {
              this.success = false;
              this.successtrx = false;
              this.messagetrx = 'Data gagal disimpan';
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.setup();
  }
}
</script>

<style scoped>

</style>