<template>
  <HeaderParent v-if="this.accountData.role!=3"></HeaderParent>
  <HeaderAdmin v-else></HeaderAdmin>
  <PageAccount></PageAccount>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderParent from "@/components/HeaderParent";
import FooterWeb from "@/components/FooterWeb";
import PageAccount from "@/components/views/PageAccount";
import HeaderAdmin from "@/components/HeaderAdmin";
export default {
  name: "routerAccount",
  components:{
    HeaderAdmin,
    FooterWeb,
    HeaderParent,
    PageAccount
  },
  data:()=>({
    accountData:{
      nama:'',
      email:'',
      role:'',
    }
  }),
  methods:{
    getUserInformation(){
      let userdata = this.$store.getters.getUserInfo;
      this.accountData.nama = userdata.data.fullname;
      this.accountData.email = userdata.data.email;
      this.accountData.role = userdata.data.userRole;
    }
  },
  mounted() {
    this.getUserInformation();
  }
}
</script>

<style scoped>

</style>