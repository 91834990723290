<template>
  <HeaderParent></HeaderParent>
  <PageLogout></PageLogout>
  <FooterWeb></FooterWeb>
</template>

<script>
import HeaderParent from "@/components/HeaderParent";
import FooterWeb from "@/components/FooterWeb";
import PageLogout from "@/components/views/PageLogout";
export default {
  name: "routerLogout",
  components: {
    FooterWeb,
    HeaderParent,
    PageLogout
  },
  beforeMount() {
    this.$store.commit('setLoggedIn',false);
    this.$store.commit('deletesession');
  }
}
</script>

<style scoped>

</style>