<template>
  <Bread-crumb page="Beranda > Pendaftaran > Jadwal Kelas Coba Belajar"></Bread-crumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Jadwal Kelas Coba Belajar</h3>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Dibawah ini adalah jadwal kelas coba belajar untuk Ananda. Silahkan pilih jadwal bila Ananda belum mendapatkan jadwal kelas coba belajar. </p>
        <div class="table-data-sga">
          <div class="table-responsive">
            <table class="table table-striped ">
              <thead>
              <tr>
                <th scope="col">No formulir</th>
                <th scope="col">Nama Ananda</th>
                <th scope="col">Nama Orang Tua</th>
                <th scope="col">Jenis</th>
                <th scope="col">Status</th>
                <th scope="col"> Jadwal</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(anak,idx) in dataAnak" :key="idx">
                <td>{{anak.no_formulir}}</td>
                <td>{{anak.nama_anak}}</td>
                <td>{{anak.nama_ortu}}</td>
                <td>{{anak.jenis_daftar}}</td>
                <td>{{anak.status}}</td>
                <td v-if="anak.status==='Terjadwal'">
                  {{anak.hari}}, {{anak.tanggal_kelas}} ({{anak.idslot}})
                </td>
                <td v-else>
                  <button type="button" class="btn btn-success btn-sm" @click="doShowPilihanJadwal(anak.no_formulir)">
                    <i class="fa-solid fa-caret-right"></i> Pilih jadwal
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PageModalPilihJadwal v-if="showmodal" :idanak="idanak" @change-modal="updateModal"></PageModalPilihJadwal>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import PageModalPilihJadwal from "@/components/views/jadwal/PageModalPilihJadwal";
export default {
  name: "PageJadwalKelasPercobaan",
  components:{PageModalPilihJadwal, BreadCrumb},
  data:()=>({
    loading: false,
    response: '',
    message: '',
    success: false,
    tanggalkelas:'',
    anggota:'',
    timname:'',
    showmodal: false,
    dataAnak:[],
    idanak:'',
  }),
  methods:{
    updateModal(){
      this.showmodal = false;
      this.getDataAnak();
    },
    doShowPilihanJadwal(idanak){
      this.idanak = idanak;
      this.showmodal = !this.showmodal;
    },
    getDataAnak(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataAnakKelasPercobaan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataAnak = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataAnak();
  }
}
</script>

<style scoped>

</style>