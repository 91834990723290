<template>
  <BreadCrumb v-if="showgrid" page="Beranda > Pendaftaran > Data Penerimaan > Lihat Data Diterima "></BreadCrumb>
  <div class="container content-area" v-if="showgrid">
    <div class="row">
      <div class="col-md-12">
        <h3 class="judul-seksi-sga">Data Diterima - SK dan Status Pembayaran Uang Sekolah Tahun Ajaran {{this.$store.getters.getTahunAJaran}}</h3>
      </div>
    </div>
    <div class="" style="padding-top: 20px;">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <DataTable class="table table-border-bottom-0"
                       v-model:filters="filters"
                       :value="dataKeputusanDiterima"
                       ref="dt"
                       tableStyle="min-width: 50rem"
                       paginator :rows="10"
                       :rowsPerPageOptions="[5, 10, 20, 50]"
                       :first="0"
                       :loading="loading"
            >
              <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                </div>
              </template>
              <template #empty> Data tidak ada. </template>
              <template #loading> Mengambil data. Mohon tunggu. </template>
              <Column field="no_formulir" header="No formulir" sortable></Column>
              <Column field="nama_anak" header="Nama Anak" filterMatchMode="startsWith" sortable>
              </Column>
              <Column field="ortu" header="Nama Orang Tua" sortable style="width: 25%"></Column>
              <Column field="jadwal_wawancara" header="Status SK" sortable></Column>
              <Column field="tanggal_wawancara" header="Status Tagihan" sortable style="width: 25%"></Column>
              <Column field="diskontipe" header="Status Diskon" sortable></Column>
              <Column field="jadwal_wawancara" header="Status Pelunasan" sortable></Column>
              <Column>
                <template #body="slotProps">
                  <a href="#" @click="toggleGrid(slotProps.data)" class="fa-solid fa-eye"></a>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PageViewPSBditerima :data="selectedFormulir" v-if="!showgrid"
                       @change-view="toogleviewshowgrid"></PageViewPSBditerima>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import {FilterMatchMode} from "@primevue/core/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import DashboardServices from "@/services/dashboard.services";
import PageViewPSBditerima from "@/components/viewadmin/penerimaan/PageViewPSBditerima";
export default {
  name: "ViewDataPSBditerima",
  components: {PageViewPSBditerima, BreadCrumb,DataTable,Column,InputText},
  data:()=>({
    loading: false,
    lazyParams: {},
    showgrid: true,
    response:'',
    message:'',
    success: false,
    dataKeputusanDiterima:[],
    selectedFormulir:[],
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'no_formulir': {value: '', matchMode: FilterMatchMode.CONTAINS},
      'nama_anak': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'nama_ortu': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'jadwal_wawancara': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'tanggal_wawancara': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
    },
  }),
  methods:{
    toogleviewshowgrid(){
      this.showgrid = true;
    },
    toggleGrid(data){
      this.showgrid = !this.showgrid;
      this.selectedFormulir = data;
    },
    getDataDiterima(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.lazyParams;
      let path = '/dashboard/getDataPSBditerima';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataKeputusanDiterima = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataDiterima();
  }
}
</script>

<style scoped>

</style>