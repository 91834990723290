<template>
  <Bread-crumb page="Beranda > Pendaftaran"></Bread-crumb>
  <!--content-area-sga-->
  <div class="container content-area">
    <div class="row">
      <LoadSpinner v-if="loading"></LoadSpinner>
      <div v-else class="col-12">
        <h3 class="judul-seksi-sga">Sistem Pendaftaran Penerimaan Siswa Baru (PSB) SD Gemala Ananda {{this.$store.getters.getTahunAJaran}}</h3>
        <hr>
        <div class="form-general">
          <div class="row">
            <div class="col-md-4">
              <router-link to="/informasipendaftaran" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran "><i class="fa-solid fa-caret-right"></i> Informasi Statistik Pendaftaran</router-link>
            </div>
            <div class="col-12 w-100" style="margin-top: -15px;">
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <RouterLink to="/gemala/formulir/psb" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran"><i class="fa-solid fa-caret-right"></i> Pengambilan Formulir PSB</RouterLink>
            </div>
            <div class="col-md-4">
              <RouterLink to="/gemala/psb/isi-formulir-psb" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran" :class="btn.btnIsiFormulirPsbActive?'':'disabled'"><i class="fa-solid fa-caret-right"></i> Isi Formulir PSB</RouterLink>
            </div>
            <div class="col-md-4">
              <RouterLink to="/gemala/psb/biaya-konfirmasi" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran" :class="btn.btnBiayaKonfirmasiACtive?'':'disabled'"><i class="fa-solid fa-caret-right"></i> Tanda Konfirmasi</RouterLink>
            </div>
            <div class="col-md-4" v-if="isMenuOpen('1')">
              <RouterLink to="/gemala/jadwal/psb/wawancara-psikologi" class="btn btn-md btn-outline-danger btn-block btn-pendaftaran" :class="btn.btnJadwalWawancaraPsikologActive?'':'disabled'"><i class="fa-solid fa-caret-right"></i> Jadwal Wawancara Psikolog</RouterLink>
            </div>
            <div class="col-md-4" v-if="isMenuOpen('2')">
              <RouterLink to="/gemala/jadwal/psb/test-kognitif" class="btn btn-md btn-outline-danger btn-block btn-pendaftaran" :class="btn.btnJadwalTestKognitifActive?'':'disabled'"><i class="fa-solid fa-caret-right"></i> Jadwal Test Kognitif</RouterLink>
            </div>
            <div class="col-md-4" v-if="isMenuOpen('3')">
              <RouterLink to="/gemala/jadwal/psb/kelas-percobaan" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran" :class="btn.btnIsiFormulirBerasaActive?'':'disabled'"><i class="fa-solid fa-caret-right"></i> Jadwal Kelas Coba Belajar</RouterLink>
            </div>
            <div class="col-md-4" v-if="isMenuOpen('4')">
              <RouterLink to="/gemala/psb/formulir-berasa" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran" :class="btn.btnIsiFormulirBerasaActive?'':'disabled'"><i class="fa-solid fa-caret-right"></i> Isi Formulir Berasa</RouterLink>
            </div>
            <div class="col-md-4" v-if="isMenuOpen('5')">
              <RouterLink to="/gemala/jadwal/psb/wawancara-orangtua" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran" :class="btn.btnJadwalWawancaraActive?'':'disabled'"><i class="fa-solid fa-caret-right"></i> Jadwal Wawancara</RouterLink>
            </div>
            <div class="col-md-4" v-if="isMenuOpen('6')">
              <RouterLink to="/gemala/hasil/psb/keputusan" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran" :class="btn.btnKeputusanPsbActive?'':'disabled'"><i class="fa-solid fa-caret-right"></i> Keputusan PSB</RouterLink>
            </div>
            <div class="col-md-4" v-if="isMenuOpen('7')">
              <RouterLink to="/gemala/psb/biaya-pelunasan-uang-sekolah" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran" :class="btn.btnPelunasanUangSekolahActive?'':'disabled'"><i class="fa-solid fa-caret-right"></i> Pelunasan Uang Sekolah</RouterLink>
            </div>
            <!--div class="col-md-4"><a href="3-status-pendaftaran-ananda.php" class="btn btn-md btn-outline-primary btn-block btn-pendaftaran"><i class="fa-solid fa-caret-right"></i> Status Pendaftaran Ananda</a></div-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadSpinner from "@/components/LoadSpinner";
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "PagePendaftaran",
  components:{BreadCrumb,LoadSpinner},
  data:()=>({
    loading:false,
    tahunajaran: '',
    bukapada:'',
    statusdaftar:[],
    accountData:{
      nama:'',
      email:'',
      role:''
    },
    tutuppada:'',
    message:'',
    success: false,
    dataInformasi:[],
    dataPendaftaran:[],
    isHaveNoFormulir:'',
    btn:{
      btnIsiFormulirPsbActive: true,
      btnBiayaKonfirmasiACtive: true,
      btnJawdalPenilaianActive: true,
      btnJadwalWawancaraPsikologActive: true,
      btnJadwalTestKognitifActive: true,
      btnIsiFormulirBerasaActive : true,
      btnJadwalWawancaraActive : true,
      btnKeputusanPsbActive: true,
      btnPelunasanUangSekolahActive: true
    }
  }),
  computed:{

  },
  methods:{
    isMenuOpen(menuid){
      if (this.statusdaftar.includes(menuid)){
        return true;
      }
    },
    getDataFormulirPendaftaran(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/getDataFormulirPendaftaranWithStatus';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data.data;
              this.isHaveNoFormulir = this.dataPendaftaran.length > 0;
              this.statusdaftar = response.data.data.stsdaftar;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getInformasi(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.accountData;
      let path = '/gemala/info/getinformasipsb';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataInformasi = this.response.data;
              this.tahunajaran = this.dataInformasi[0].tahunajaran;
              this.bukapada = this.dataInformasi[0].bukapada;
              this.tutuppada = this.dataInformasi[0].tutuppada;
              this.loading = false;
              this.$store.commit('setperiode',this.tahunajaran);
              this.$store.commit('loading',this.loading);
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getUserInformation(){
      let userdata = this.$store.getters.getUserInfo;
      this.accountData.nama = userdata.data.fullname;
      this.accountData.email = userdata.data.email;
      this.accountData.role = userdata.data.userRole;
    }
  },
  mounted() {
    this.getInformasi();
    localStorage.removeItem('tipepsb');
    this.getDataFormulirPendaftaran();
  },
  beforeMount() {
    this.getUserInformation();
  }
}
</script>
<style scoped></style>