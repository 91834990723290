<template>
  <BreadCrumb page="Beranda > Pendaftaran > Pembelian formulir"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">{{this.$store.getters.getJudul}}</h3>
        <hr>
        <form>
          <div class="form-general">
            <div class="row">
              <div class="col-12">
                <div class="notes-notification-sga">
                  <p><strong>Catatan</strong>
                  </p>
                  <ul>
                    <li>Harap perhatikan dengan saksama Tata Cara Pendaftaran Penerimaan
                      Siswa Baru berikut ini.
                    </li>
                    <li>Pada bagian paling bawah, Anda perlu <strong>menyetujui</strong> Tata Cara
                      Penerimaan Siswa Baru sebelum melanjutkan pembelian formulir pendaftaran.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <img :src="imageTataCara" class="img-fluid">
                <div class="form-group form-check sga-check-tatacara">
                  <input type="checkbox" v-model="setuju" @click="setuju=!setuju" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">Kami memahami informasi dan tata
                    laksana proses penerimaan siswa baru Sekolah Gemala Ananda. Kami menyatakan
                    bersedia mengikuti seluruh rangkaian proses pendaftaran dan seleksi sesuai
                    dengan sistem dan peraturan yang berlaku.

                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 button-area-sga text-center">
                <router-link to="/gemala/formulir/pengisian" class="btn btn-md btn-success" :class="setuju?'':'disabled'" ><i class="fa-solid fa-caret-right"></i> Daftar Sekarang</router-link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import router from "@/router";
export default {
  name: "PageTataCaraDaftar",
  components: {BreadCrumb},
  props:{
    page:String,
  },
  data:()=>({
    imageTataCara: require('@/assets/images/gambartatacara.jpg'),
    setuju: false
  }),
  mounted() {
    let tipepsb = localStorage.getItem('tipepsb');
    this.$store.commit('tipepsb',tipepsb);
    if(this.$store.state.titlepsb==='-'){
      router.push('/pendaftaran');
    }
    this.$store.commit('setFormulirAgreement', false);
    localStorage.removeItem('user-agreement');
  },
  watch:{
    setuju:{
      immediate: false,
      deep: false,
      handler(){
        this.$store.commit('setFormulirAgreement', this.setuju);
        localStorage.setItem('user-agreement', this.setuju);
        let tipepsb = localStorage.getItem('tipepsb');
        this.$store.commit('tipepsb',tipepsb);
      }
    }
  }
}
</script>

<style scoped>

</style>