<template>
  <BreadCrumb page="Beranda > Pendaftaran > Pembelian formulir"></BreadCrumb>
  <!--content-area-sga-->
  <div class="container content-area">
    <LoadSpinner v-if="loading"></LoadSpinner>
    <div v-else class="row">
      <div class="col-12" id="regtop">
        <h3 class="judul-seksi-sga">{{this.$store.getters.getJudul}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div class="form-general">
      <LoadSpinner v-if="loading"></LoadSpinner>
      <div v-else class="row">
        <div class="col-12 text-left" v-if="statusperiode">
          <p>Pendaftaran pengambilan formulir PSB SD Gemala Ananda dibuka pada tanggal
            <strong>{{tglbuka}}</strong> jam <strong>{{jambuka}}</strong>.</p>
          <vue-countdown v-if="isFuture" :time="time" :interval="100" v-slot="{ days,hours, minutes, seconds, milliseconds }" @end="setIsOpen">
            <p><strong style="color: red;"> {{ days }} : {{ hours }} : {{ minutes }} : {{ seconds }}.{{ Math.floor(milliseconds / 100) }}</strong></p>
          </vue-countdown>
          <RouterLink to="/gemala/pendaftaran/" class="btn btn-md btn-danger mr-2"><i class="fa-solid fa-caret-left"></i> Kembali</RouterLink>
          <RouterLink v-if="isOpen" :to=getLinkTataCara() class="btn btn-md btn-success"><i class="fa-solid fa-caret-right"></i> Mulai pendaftaran</RouterLink>
        </div>
        <div class="col-12 text-left" v-else>
          <p>Periode pendaftaran belum dibuka.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import LoadSpinner from "@/components/LoadSpinner";
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
  name: "PagePengambilanFormulir",
  components: {LoadSpinner, BreadCrumb,VueCountdown},
  data:()=>({
    now:'',
    newYear:'',
    time:0,
    page:'',
    tatacaraLink:'',
    response:'',
    message:'',
    loading: false,
    success: false,
    dataInformasi:[],
    tahunajaran:'',
    bukapada:'',
    isOpen: false,
    isFuture: false,
    tutuppada:'',
    tglbuka:'',
    jambuka:'',
    jumlahkursi:0,
    statusperiode:false
  }),
  computed:{
  },
  mounted() {
    this.page = this.$route.params.page.toLowerCase();
    this.getInformasi();
  },
  methods:{
    getLinkTataCara(){
      return '/gemala/formulir/tatacara/';
    },
    setIsOpen(){
      this.isFuture = false;
      this.isOpen = true;
    },
    getInformasi(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.accountData;
      let path = '/gemala/info/getinformasipsb';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataInformasi = this.response.data;
              this.tahunajaran = this.dataInformasi[0].tahunajaran;
              this.jumlahkursi = this.dataInformasi[0].jumlahkursi;
              this.statusperiode = this.dataInformasi[0].statusperiode===1?true:false;
              this.bukapada = this.dataInformasi[0].bukapada;
              this.tutuppada = this.dataInformasi[0].tutuppada;
              this.tglbuka = this.dataInformasi[0].tglbuka;
              this.jambuka = this.dataInformasi[0].jambuka;
              this.isOpen = this.dataInformasi[0].isopen;
              this.isFuture = this.dataInformasi[0].isfuture;
              this.now = new Date((this.dataInformasi[0].currenttime)*1000);
              this.newYear = new Date((this.dataInformasi[0].opendate)*1000);
              this.time = this.newYear - this.now;
              this.loading = false;
              this.$store.commit('setperiode',this.tahunajaran);
              this.$store.commit('loading',this.loading);
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  watch:{
    '$route.params.page':{
      immediate: false,
      deep: false,
      handler(){
        this.page = this.$route.params.page.toLowerCase();
      }
    }
  }
}
</script>
<style scoped></style>